import classNames from 'classnames';
import { FC } from 'react';

interface FooterProps {
  isRules?: boolean;
}
const Footer: FC<FooterProps> = ({ isRules = false }) => {
  return (
    <div className="flex flex-row justify-between p-6">
      <div>
        <p
          className={classNames('text-sm leading-4 font-normal m-0', {
            'text-white-50': !isRules,
            'text-black-40': isRules,
          })}
        >
          © Rising Tides 2023
        </p>
      </div>
      <div>
        <p
          className={classNames('text-sm leading-4 font-normal m-0', {
            'text-white-50': !isRules,
            'text-black-40': isRules,
          })}
        >
          Privacy Policy
        </p>
      </div>
    </div>
  );
};

export default Footer;
