import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from './context';
import { AuthProviderProps } from './context.types';
import Api from '../axios/api';
import { AuthService, User } from '../services/auth.serivice';
import { Player } from '../services/lobby.serivice';
import { ConstructionI } from '../services/game.serivice';

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [players, setPlayers] = useState<Player[]>();
  const [builds, setBuilds] = useState<ConstructionI[]>();

  const getUser = useCallback(async (token: string) => {
    try {
      setLoading(true);
      const user = await AuthService.getMe();
      setUser(user.data);
      Api.defaults.headers['Authorization'] = `Bearer ${token}`;
    } catch (e) {
      logout();
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const existToken = localStorage.getItem('access_token');
    if (existToken) {
      getUser(existToken);
    } else {
      setLoading(false);
    }
  }, [getUser]);

  const logout = useCallback(() => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    navigate('/');
  }, [navigate]);

  const value = useMemo(
    () => ({
      loading,
      setLoading,
      user,
      setUser,
      players,
      setPlayers,
      logout,
      builds,
      setBuilds,
    }),
    [loading, user, players, logout, builds],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const GlobalProvider = ({ children }: any) => {
  return <AuthProvider>{children}</AuthProvider>;
};

export default GlobalProvider;
