import React from 'react';
import Modal from 'react-modal';
import Close from '../../assets/Close';
import { StructureInterface } from '../Roles';
import Next from '../../assets/Next';
import classNames from 'classnames';

interface ActionCardProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  actionCard: StructureInterface;
  disablePlay: boolean;
}

const ActionCard = ({ isOpen, onClose, onSubmit, actionCard, disablePlay }: ActionCardProps) => {
  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onClose}
      className="absolute rounded-2xl max-w-sm min-w-[384px]"
      overlayClassName="modal_overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col rounded-2xl bg-hint_of_green border-mantis border-solid border-4 shadow-product-modal">
        <div className="px-4 py-3 flex flex-col bg-forest-green rounded-t-xl">
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-white text-2xl font-medium">{actionCard.name}</p>
            <div className="flex flex-row items-center">
              <button
                onClick={onClose}
                className="bg-transparent outline-none border-0 flex flex-row justify-center items-center h-8 w-8 p-0 cursor-pointer"
              >
                <Close fill={'rgba(255, 255, 255, 0.80)'} />
              </button>
            </div>
          </div>
        </div>
        {actionCard.action_description ? (
          <div className="flex flex-col p-4">
            <p className="text-xs font-normal text-black-80 m-0">{actionCard.action_description}</p>
          </div>
        ) : null}
        <div className="flex flex-row gap-2 justify-center p-4">
          <button
            onClick={onClose}
            className="outline-none flex flex-row justify-center items-center px-4 py-3 w-32 rounded-sm border-0 bg-venetian_red cursor-pointer"
          >
            <p className="m-0 text-base font-medium text-white mr-2 py-1">Cancel</p>
            <Close fill={'#ffffff'} />
          </button>
          <button
            className={classNames(
              `flex flex-row justify-center items-center rounded-sm bg-forest-green outline-none border-0 px-4 py-3 w-32 cursor-pointer`,
              {
                'opacity-50': disablePlay,
              },
            )}
            onClick={onSubmit}
            disabled={disablePlay}
          >
            <p className="m-0 text-base font-medium text-white mr-2 py-1">Play</p>
            <Next />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ActionCard;
