import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from '../../components/Footer';
import ChevronRight from '../../assets/ChevronRight';

const Rules: FC = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col justify-between h-full relative z-10 bg-derby">
        <div className="flex flex-col justify-center w-full">
          <div className="flex flex-row p-6">
            <button
              className="flex flex-row bg-transparent outline-none border-0 cursor-pointer"
              onClick={back}
            >
              <ChevronRight />
            </button>
            <p className="m-0 text-3xl font-medium text-mine_shaft ml-2 uppercase">Rules</p>
          </div>
          <div className="mt-6 flex flex-row justify-center overflow-y-auto bg-derby">
            <div className="flex flex-col">
              <div className="flex flex-col max-w-3xl">
                <p className="m-0 text-xl text-cod-gray font-medium">Concept and Overview</p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  Rising Tides is a strategic and collaborative game that puts players in charge of
                  playing as a team of five different institutions with the goal of bringing CO2
                  emissions down to zero and saving the world from environmental catastrophe.
                  Players must work together to build green infrastructure and reduce their carbon
                  footprint while competing against other institutions to have the most structures.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  To win the game, players must balance their efforts between achieving the common
                  goal of reducing carbon emissions and competing to have the most structures. They
                  must allocate their resources strategically and prioritize their actions based on
                  the needs of the team and the objectives of the game.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  Players must also consider the potential consequences of their priorities, as
                  certain actions may have unintended negative environmental impacts and lead to
                  disasters.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  Throughout the game, players will face challenges and opportunities that will test
                  their teamwork and strategic planning skills. With its unique blend of cooperation
                  and competition, Rising Tides offers an engaging and thought-provoking gameplay
                  experience that challenges players to think critically about the impact of their
                  actions and work together to save the planet.
                </p>
              </div>

              <div className="mt-6 flex flex-col max-w-3xl">
                <p className="m-0 text-xl text-cod-gray font-medium">Game Mechanics</p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  <span className="font-medium">Role Cards:</span> Shuffle the role cards and
                  randomly assign each player a role.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  <span className="font-medium">Disaster Deck:</span> Shuffle the disaster deck and
                  place it next to the emissions bar. When you reach a threshold on the emissions
                  bar, draw a card from the disaster deck.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  <span className="font-medium">Resource Deck:</span> At the beginning of the game,
                  have each player shuffle their resource deck and draw 5 resource cards. At the end
                  of each turn, for every two structures draw one card. Separate item cards from
                  structural cards, drawing one item card is equivalent to 2 structural cards.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  <span className="font-medium">Structures:</span> Players can place these
                  structures in any city they wish. Each player takes 3 structures.
                </p>

                <p className="m-0 text-sm font-light text-tundora mt-2">
                  <span className="font-medium">Emissions Bar:</span> Place the emissions marker at
                  100. A major threshold is reached every 150 points, starting at 250 on the
                  emissions bar. A major threshold means you have to draw from the major disaster
                  deck. A minor threshold is reached every 50 points starting at 150 on the
                  emissions bar. A minor threshold means you have to draw from the minor disaster
                  deck. The major and minor thresholds are independent of each other, and are
                  tracked separately while both start at 100.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  If after the end of the round the emission bar is increased by 150 you draw a
                  disaster card. If after the end of the round the emission bar is increased by 50
                  you draw a disaster card.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  <span className="font-medium">Modifier Bar:</span> The modifier is added to
                  emissions at the end of a round, and is calculated by multiplying the number of
                  non-green structures by 5. The modifier is decreased by multiplying green
                  structures by 5. Item card’s modifier impact are also accounted for in the
                  modifier bar.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  <span className="font-medium">Number of Actions:</span> Types of moves: Building,
                  Item Card, Ability. You have two moves if you’re using Building and Item Cards.
                  The ability action should be the only one during the turn.
                </p>
              </div>
              <div className="mt-6 flex flex-col max-w-3xl">
                <p className="m-0 text-xl text-cod-gray font-medium">Cards</p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  <span className="font-medium">Infrastructure/Resource Deck</span>
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  <span className="font-normal">Structure Resource Card(24 per player):</span>
                  for three structure resource cards, players can build one structure/token. Each
                  structure built adds 5 points to the modifier.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  <span className="font-normal">Green Resource Cards(20 per player):</span> for two
                  green resource cards, they can flip the token over, to have the structure produce
                  net zero emissions.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  Infrastructure can only be built in cities.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  <span className="font-medium">Disaster Deck</span>
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  Containing both minor disasters such as political unrest to major ones, a complete
                  apocalypse, climate change entails devastation to those who inhabit earth. When
                  players reach a red mark on the carbon meter, draw a card from the disaster deck.
                </p>
                <p className="m-0 text-sm font-light text-tundora mt-2">
                  Disaster Deck should be balanced as it’s having too big of an impact at the
                  moment.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer isRules={true} />
      </div>
    </div>
  );
};

export default Rules;
