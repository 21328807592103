import { ActionDanger } from '../enums/action-danger';

export const MINOR_DANGERS = [
  {
    name: 'Political Tremors',
    description:
      'Turbulence ripples through the political landscape of {country}. Remove one government building to reflect the unrest.',
    action: ActionDanger.POLITICAL_TREMORS,
  },
  {
    name: 'Science Skeptics',
    description:
      'Doubtful factions in {country} show mistrust towards scientific endeavors. One science facility in this city will be removed due to diminished support.',
    action: ActionDanger.SCIENCE_SKEPTICS,
  },
  {
    name: `Nature's Defiance`,
    description:
      'Residents of {country} resist the growth of conservation areas, leading to a forest being torched. Eliminate one conservation area in response.',
    action: ActionDanger.NATURES_DEFIANCE,
  },
  {
    name: 'Economic Ebb',
    description:
      'A recession shadows {country}, curtailing consumer spending. Close down one shopping center as a consequence.',
    action: ActionDanger.ECONOMIC_EBB,
  },
  {
    name: 'Industry Standstill',
    description:
      'Factory workers in {country} stage a massive strike, halting production. Temporarily shutter one factory to reflect the stalemate.',
    action: ActionDanger.INDUSTRY_STANDSTILL,
  },
];

export const MAJOR_DANGERS = [
  {
    name: 'Earthquake',
    description:
      'Seismic activities caused by fossil fuel mining shatter the ground. {city} and {city_1} are affected. Half of all the structures are removed.',
    action: ActionDanger.EARTHQUAKE,
    icon: '/game/earthquake.png',
  },
  {
    name: 'Eruption',
    description:
      'Volcanic instability due to global warming results in a violent eruption. {city} is affected. All the structures are removed',
    action: ActionDanger.ERUPTION,
    icon: '/game/eruption.png',
  },
  {
    name: 'Emission of Radiation',
    description:
      'High energy demand causes a nuclear meltdown. {city} affected. Half of all the structures are removed.',
    action: ActionDanger.EMISSION_OF_RADIATION,
    icon: '/game/emission.png',
  },
  {
    name: 'Melting Glaciers',
    description:
      'Rapid glacier melting raises sea levels. {city} affected. All the structures are removed.',
    action: ActionDanger.MELTING_GLACIERS,
    icon: '/game/melting_glaciers.png',
  },
  {
    name: 'Drought',
    description:
      'Increased heat and dry conditions deplete water reserves. {city} affected. ⅓  of all the structures are removed.',
    action: ActionDanger.DROUGHT,
    icon: '/game/drought.png',
  },
  {
    name: 'Wildfires',
    description:
      'Hotter, drier weather sparks rampant wildfires. {city} is affected. All the structures are removed.',
    action: ActionDanger.WILDFIRES,
    icon: '/game/wildfires.png',
  },
  {
    name: 'Flood',
    description:
      'Melting ice and heavy rainfall flood the city. {city} affected, ⅓ of the structures are removed.',
    action: ActionDanger.FLOOD,
    icon: '/game/flood.png',
  },
  {
    name: 'Air Pollution',
    description:
      'Excessive CO2 emissions degrade air quality. {city} affected. Half of the structures are removed.',
    action: ActionDanger.AIR_POLLUTION,
    icon: '/game/air_pollution.png',
  },
];
