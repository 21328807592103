import {
  FC,
  KeyboardEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';

import { SocketService } from '../../services/socket.services';
import { ChatService, MessageI } from '../../services/chat.serivice';
import Send from '../../assets/Send';
import { AuthContext } from '../../context/context';
import classNames from 'classnames';
import DoubleArrowUp from '../../assets/DoubleArrowUp';
import { ROLES } from '../../constants/roles';
import { RoleInterface } from '../Roles';
interface ChatProps {
  chatId: string;
  isGame: boolean;
}

const Chat: FC<ChatProps> = ({ chatId, isGame }) => {
  const [messages, setMessages] = useState<MessageI[]>([]);
  const [opened, setOpened] = useState<boolean>(!isGame);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const { user, players } = useContext(AuthContext);
  const scrollRef = useRef(null) as any;

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollRef.current?.scrollTo({
        top: document.body.scrollHeight * 200,
        left: 0,
        behavior: 'smooth',
      });
    }, 500);
  };

  const addMessage = useCallback(
    async (text: string) => {
      try {
        const message = text?.trim();
        const { data } = await ChatService.addMessage(chatId, { message });
        const newMessages = [...messages, data];
        setMessages(newMessages);
        // scrollToBottom();
      } catch (e: any) {
        const message = e?.response?.statusText || 'Error';
        toast(message, { type: 'error' });
      }
    },
    [chatId, messages],
  );

  const fetchData = useCallback(
    async (page: any) => {
      try {
        if (chatId && hasMore) {
          const { data } = await ChatService.getMessages(chatId, page * 20);
          const haha = data.items;
          haha.reverse();
          const newMessages = [...haha, ...messages];
          setMessages(newMessages);
          const newHasMore = !(data?.count >= newMessages?.length);
          setHasMore(newHasMore);
        }
      } catch (e: any) {
        const message = e?.response?.statusText || 'Error';
        toast(message, { type: 'error' });
      }
    },
    [chatId, hasMore, messages],
  );

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: (values: { message: string }) => {
      addMessage(values.message);
      formik.resetForm();
    },
    validationSchema: Yup.object({
      message: Yup.string().required('Message is required'),
    }),
    enableReinitialize: true,
  });

  const getMessages = useCallback(async () => {
    try {
      if (chatId) {
        const { data } = await ChatService.getMessages(chatId);
        const newMessages = data.items;
        newMessages.reverse();
        setMessages(newMessages);
        const newHasMore = !(data?.count >= newMessages?.length);
        setHasMore(newHasMore);
        if (newMessages.length > 5) {
          scrollToBottom();
        }
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  }, [chatId]);

  useEffect(() => {
    if (chatId) {
      getMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  const connectSocket = useCallback(() => {
    try {
      if (chatId) {
        const socket = SocketService.getInstanceChat(chatId);
        socket.emit('identity', `chat-room:${chatId}`);
        socket.on('chat_stream', (data: MessageI) => {
          const newMessages = [...messages, data];
          setMessages(newMessages);
          if (newMessages.length > 5) {
            scrollToBottom();
          }
        });
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  }, [chatId, messages]);

  useEffect(() => {
    connectSocket();
  }, [chatId, connectSocket]);

  useEffect(() => {
    return () => {
      SocketService.disconnectChat(chatId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      formik.submitForm();
    }
  };

  return (
    <div className="flex flex-col h-full bg-derby-95 max-w-xs min-h-max max-h-full">
      <div
        className={classNames('flex flex-row items-center justify-between p-4 bg-muddy-waters-90', {
          'cursor-pointer': isGame,
        })}
        onClick={() => setOpened(!opened)}
      >
        <div className="flex flex-row justify-start items-center">
          <img src="/images/chat.png" alt="chat" className="w-5 h-5" />
          <p className="text-white text-lg leading-4 font-medium m-0 ml-2">Chat Room</p>
        </div>
        {isGame ? (
          <div
            className={classNames('transition-all ease-linear', {
              'rotate-180': opened,
            })}
          >
            <DoubleArrowUp />
          </div>
        ) : null}
      </div>
      <div
        className={classNames('flex flex-col p-4 bg-papaya_whip justify-between', {
          'h-[calc(100%-52px)]': !isGame,
          'h-[calc(100vh-500px)]': isGame && opened,
          'h-0 hidden': isGame && !opened,
        })}
      >
        <div
          className="flex flex-col overflow-y-auto min-w-[250px] bg-chat h-[calc(100%-156px)]"
          ref={scrollRef}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchData}
            hasMore={hasMore}
            isReverse={true}
            useWindow={false}
            threshold={400}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {messages.map((message, index) => {
              const isMe = message.author.id === user?.id;
              const author = players?.find((r) => r.id === message.author?.id);
              const playerData = ROLES?.find((p) => p.roleDb === author?.role) as RoleInterface;

              return isMe ? (
                <div key={`${message.id}_${index}`}>
                  <div className="flex flex-row justify-between items-center">
                    <p className="m-0 text-gray text-xs font-normal">
                      {dayjs(message.createdAt).format('hh:mm')}
                    </p>
                    <div className="flex flex-row justify-start items-center">
                      <p className="m-0 text-black text-sm leading-4 font-bold mr-1">
                        {message.author.name}(You)
                      </p>
                      <div
                        className={classNames(
                          'border border-solid rounded-md p-1 h-7 w-7 flex flex-row justify-center items-center',
                          {
                            'bg-potters-clay-70 border-potters-clay':
                              message.author?.id === user?.id,
                            'bg-white-30 border-gape-palliser': message.author?.id !== user?.id,
                          },
                        )}
                      >
                        {!!playerData && (
                          <playerData.gameIcon
                            fill={message.author?.id === user?.id ? '#ffffff' : '#6F4518'}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-1 bg-whiskey p-3 mb-3 rounded-b-lg rounded-tr-lg">
                    <p className="m-0 px-5 text-black text-xs font-normal">{message.message}</p>
                  </div>
                </div>
              ) : (
                <div key={`${message.id}_${index}`}>
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row justify-start items-center">
                      <div
                        className={classNames(
                          'border border-solid rounded-md p-1 h-7 w-7 flex flex-row justify-center items-center',
                          {
                            'bg-potters-clay-70 border-potters-clay':
                              message.author?.id === user?.id,
                            'bg-white-30 border-gape-palliser': message.author?.id !== user?.id,
                          },
                        )}
                      >
                        {!!playerData && (
                          <playerData.gameIcon
                            fill={message.author?.id === user?.id ? '#ffffff' : '#6F4518'}
                          />
                        )}
                      </div>
                      <p className="m-0 text-saddle-brown text-sm leading-4 font-medium ml-1">
                        {message.author.name}
                      </p>
                    </div>
                    <p className="m-0 text-gray text-xs font-normal">
                      {dayjs(message.createdAt).format('hh:mm')}
                    </p>
                  </div>
                  <div className="mt-1 bg-wheat p-3 mb-3 rounded-b-lg rounded-tr-lg">
                    <p className="m-0 px-5 text-mine_shaft text-xs font-normal">
                      {message.message}
                    </p>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
        <div className="flex flex-col pt-4 pb-1">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-row p-4 bg-gape-palliser rounded-lg"
          >
            <textarea
              className="bg-transparent border-0 outline-none text-concrete text-sm leading-4 font-normal"
              name="message"
              value={formik?.values.message}
              onChange={formik?.handleChange}
              onKeyDown={handleKeyPress}
            ></textarea>
            <button
              className="ml-6 flex flex-row justify-center items-center bg-transparent outline-none border-0 cursor-pointer"
              type="submit"
            >
              <Send />
            </button>
          </form>
          <div>
            <p className="m-0 text-xs leading-4 font-normal text-dusty_gray">
              {formik?.values?.message?.length}/250 characters
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
