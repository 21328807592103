import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Next from '../../assets/Next';
import { MAJOR_DANGERS, MINOR_DANGERS } from '../../constants/dangers';
import {
  CityI,
  ConstructionI,
  CountryI,
  DangerCardI,
  GameService,
  StateGameI,
} from '../../services/game.serivice';
import { COUNTRIES } from '../../constants/countries';
import { ActionDanger } from '../../enums/action-danger';
import { ConstructionTypes } from '../../enums/construction-type';
import { CITIES } from '../../constants/cities';
import { ConstructionTypesBuild } from '../../enums/construction-type-build';

interface DangerCardProps {
  isOpen: boolean;
  constructions: ConstructionI[];
  isMajorDanger: boolean;
  state: StateGameI;
  gameId: string;
  round: string;
  danger: string;
  onSubmit: () => void;
}

const DangerCard = ({
  isOpen,
  isMajorDanger,
  state,
  gameId,
  round,
  constructions,
  danger,
  onSubmit,
}: DangerCardProps) => {
  const [dangerCard, setDangerCard] = useState<DangerCardI | undefined>();
  const [selectedCity, setCity] = useState<CityI | undefined>();
  const [selectedOtherCity, setOtherCity] = useState<CityI | undefined>();
  const [selectedCountry, setCountry] = useState<CountryI | undefined>();
  const [protectCity, setProtectCity] = useState<string | undefined>();

  useEffect(() => {
    if (state.usedPowers?.length) {
      const governmentPower = `_government_government_research_${round}`;
      const disabledCityPower = state.usedPowers.find(
        (r) => r.includes('_consumer_popular_demand') || r.includes(governmentPower),
      );
      if (disabledCityPower) {
        let city = disabledCityPower?.replace('_consumer_popular_demand', '');
        if (!city) {
          city = disabledCityPower.replace(governmentPower, '');
        }
        setProtectCity(city);
      }
    }
  }, [round, state.usedPowers]);

  useEffect(() => {
    if (isMajorDanger) {
      const cities = CITIES;
      const randomCityIndex = Math.floor(Math.random() * cities.length);
      const card = MAJOR_DANGERS?.find((r) => r.action === danger)!;

      let otherCity: CityI = { name: '', id: 0 };
      if (card?.action === ActionDanger.EARTHQUAKE) {
        let newRandomCityIndex = Math.floor(Math.random() * cities.length);
        newRandomCityIndex =
          newRandomCityIndex === randomCityIndex ? newRandomCityIndex + 1 : newRandomCityIndex;
        otherCity = cities[newRandomCityIndex];
        setOtherCity(otherCity);
      }
      const city = cities[randomCityIndex];
      setCity(city);
      setDangerCard({
        ...card,
        description: card?.description
          ?.replace('{city}', city?.name!)
          ?.replace('{city_1}', otherCity?.name),
      });
    } else {
      const randomCountryIndex = Math.floor(Math.random() * COUNTRIES.length);
      const country = COUNTRIES[randomCountryIndex];
      const card = MINOR_DANGERS?.find((r) => r.action === danger)!;
      setCountry(country);
      setDangerCard({
        ...card,
        description: card?.description?.replace('{country}', country?.name!),
      });
    }
  }, [danger, isMajorDanger]);

  const changeGame = async (removeConstructions: ConstructionI[]) => {
    try {
      const log = isMajorDanger
        ? `${selectedCity?.name} was affected by a major disaster ${dangerCard?.name}.`
        : `${selectedCountry?.name} was affected by a minor disaster.`;
      const cityElement = removeConstructions?.length ? removeConstructions?.at(0)?.country : null;

      if (removeConstructions.length && protectCity !== cityElement) {
        const lastConstructions: ConstructionI[] = constructions;
        for (const element of removeConstructions) {
          const index = lastConstructions.findIndex(
            (y) =>
              y.typeBuild === element.typeBuild &&
              y.userId === element.userId &&
              y.country === element.country,
          );
          if (index !== -1) {
            lastConstructions.splice(index, 1);
          }
        }
        let worldState = {
          ...state,
          constructions: lastConstructions,
          lastModifier: 0,
          minorDanger: !isMajorDanger ? '' : state.minorDanger,
          danger: isMajorDanger ? '' : state.danger,
          dangerSubmited: true,
          usedCards: [...state.usedCards, dangerCard?.action!],
        };
        for (let i = 0; i < removeConstructions.length; i++) {
          const removeConstruction = removeConstructions[i];
          await GameService.addActions(gameId, {
            state: {
              procentX: removeConstruction.procentX,
              procentY: removeConstruction.procentY,
              type: removeConstruction.type,
              typeBuild: removeConstruction.typeBuild,
              country: removeConstruction.country,
              card_value: removeConstruction?.card_value,
              label: 'remove_build',
            },
            type: 'world_state',
            worldState: i === removeConstructions.length - 1 ? worldState : undefined,
          });
        }
        await GameService.addActions(gameId, {
          state: {
            procentX: 0,
            procentY: 0,
            type: '',
            card: { ...dangerCard, card_value: dangerCard?.action!, log },
            label: ConstructionTypesBuild.DANGER,
            typeBuild: ConstructionTypesBuild.DANGER,
            card_value: dangerCard?.action!,
            country: cityElement || '1',
          },
          type: 'visible_log',
          worldState: undefined,
        });
      } else {
        let usedPowers = state?.usedPowers?.length ? state?.usedPowers : [];
        if (protectCity && protectCity !== cityElement) {
          usedPowers = usedPowers?.filter((r) => !r.includes('_consumer_popular_demand'));
        }
        await GameService.addActions(gameId, {
          state: {
            procentX: 0,
            procentY: 0,
            type: '',
            card: { ...dangerCard, card_value: dangerCard?.action!, log },
            label: ConstructionTypesBuild.DANGER,
            typeBuild: ConstructionTypesBuild.DANGER,
            card_value: dangerCard?.action!,
            country: cityElement || '1',
          },
          type: 'visible_log',
          worldState: undefined,
        });
        await GameService.addActions(gameId, {
          state: undefined,
          type: 'world_state',
          worldState: {
            ...state,
            usedPowers,
            lastModifier: 0,
            minorDanger: !isMajorDanger ? '' : state.minorDanger,
            danger: isMajorDanger ? '' : state.danger,
            dangerSubmited: true,
            usedCards: [...state.usedCards, dangerCard?.action!],
          },
        });
      }
    } finally {
      setCity(undefined);
      setOtherCity(undefined);
      setCountry(undefined);
      setDangerCard(undefined);
      onSubmit();
    }
  };

  const earthquake = () => {
    let removedNewConstructions: ConstructionI[] = [];
    if (selectedCity && selectedCity.id) {
      const firstConSecond = constructions.filter(
        (r) => r.country === selectedCity?.id?.toString(),
      );
      const conLength = Math.ceil(firstConSecond.length / 2);
      const lastConFirst = firstConSecond.slice(0, conLength);
      removedNewConstructions = [...lastConFirst];
    }

    if (selectedOtherCity && selectedOtherCity.id) {
      const conSecond = constructions.filter(
        (r) => r.country === selectedOtherCity?.id?.toString(),
      );
      const greenSecondLength = Math.ceil(conSecond.length / 2);
      const lastConSecond = conSecond.slice(0, greenSecondLength);
      removedNewConstructions = [...removedNewConstructions, ...lastConSecond];
    }
    changeGame(removedNewConstructions);
  };

  const eruption = () => {
    let removedEruption: ConstructionI[] = [];
    if (selectedCity && selectedCity.id) {
      removedEruption = constructions.filter((r) => r.country === selectedCity?.id?.toString());
    }
    changeGame(removedEruption);
  };

  const emission = () => {
    let removedNewConstructions: ConstructionI[] = [];
    if (selectedCity && selectedCity.id) {
      const conFirst = constructions.filter((r) => r.country === selectedCity?.id?.toString());
      const firstLength = Math.ceil(conFirst.length / 2);
      removedNewConstructions = conFirst.slice(0, firstLength);
    }
    changeGame(removedNewConstructions);
  };

  const drought = () => {
    let removedNewConstructions: ConstructionI[] = [];
    if (selectedCity && selectedCity.id) {
      const cons = constructions.filter((r) => r.country === selectedCity?.id?.toString());
      const conLength = Math.ceil(cons.length / 3);
      removedNewConstructions = cons.slice(0, conLength);
    }
    changeGame(removedNewConstructions);
  };

  const minorDanger = (type: string) => {
    let removedNewConstructions: ConstructionI[] = [];
    if (selectedCountry && selectedCountry.id) {
      const cities = COUNTRIES.find((r) => r.id === selectedCountry?.id)?.cities?.map((c) =>
        c.id.toString(),
      );
      const cons = constructions.filter((r) => cities?.includes(r.country) && r.type === type);
      removedNewConstructions = cons?.length ? [cons[0]] : [];
    }
    changeGame(removedNewConstructions);
  };

  const dangerSubmit = () => {
    if ((selectedCountry || selectedCity) && dangerCard) {
      switch (dangerCard.action) {
        case ActionDanger.POLITICAL_TREMORS:
          minorDanger(ConstructionTypes.BANK);
          break;
        case ActionDanger.SCIENCE_SKEPTICS:
          minorDanger(ConstructionTypes.CHEMISTRY);
          break;
        case ActionDanger.NATURES_DEFIANCE:
          minorDanger(ConstructionTypes.USER);
          break;
        case ActionDanger.ECONOMIC_EBB:
          minorDanger(ConstructionTypes.STORE);
          break;
        case ActionDanger.INDUSTRY_STANDSTILL:
          minorDanger(ConstructionTypes.INDUSTRY);
          break;
        case ActionDanger.EARTHQUAKE:
          earthquake();
          break;
        case ActionDanger.ERUPTION:
          eruption();
          break;
        case ActionDanger.EMISSION_OF_RADIATION:
          emission();
          break;
        case ActionDanger.MELTING_GLACIERS:
          eruption();
          break;
        case ActionDanger.DROUGHT:
          drought();
          break;
        case ActionDanger.WILDFIRES:
          eruption();
          break;
        case ActionDanger.FLOOD:
          drought();
          break;
        case ActionDanger.AIR_POLLUTION:
          earthquake();
          break;
        default:
      }
      onSubmit();
    } else {
      onSubmit();
      return;
    }
  };

  return (
    <Modal
      isOpen={!!isOpen}
      className="absolute rounded-2xl max-w-sm min-w-[384px] outline-none"
      overlayClassName="modal_overlay_danger"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col border-0">
        <div className="flex flex-col items-center">
          <div className="w-80 h-80 bg-tangerine rounded-[32px] flex flex-row justify-center items-center">
            <div className="bg-black w-72 h-72 rounded-3xl flex flex-col items-center p-2">
              <p className="m-0 text-tangerine uppercase text-5xl font-medium leading-tight">
                Danger
              </p>
              <div className="bg-tangerine w-full h-[calc(100%-60px)] rounded-b-[20px] flex flex-col items-center justify-end px-2 py-2">
                <div className="w-full h-[calc(100%-40px)] max-h-full flex flex-row justify-center items-center">
                  {isMajorDanger ? (
                    <img
                      src={dangerCard?.icon}
                      alt={dangerCard?.name}
                      className="max-w-full max-h-full"
                    />
                  ) : null}
                </div>
                <p className="m-0 text-cod-gray text-2xl font-normal mt-2">{dangerCard?.name}</p>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <p className="m-0 text-center text-2xl text-white font-normal">
              {dangerCard?.description}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center mt-8">
          <button
            className="flex flex-row w-max justify-center items-center rounded-sm bg-mercury outline-none border-0 px-5 py-3 cursor-pointer"
            onClick={dangerSubmit}
          >
            <p className="m-0 text-sm leading-4 font-medium text-black mr-2 py-1">Continue</p>
            <Next fill={'#000000'} />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DangerCard;
