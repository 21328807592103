import React from 'react';
import Close from '../../assets/Close';

interface ChangeCityProps {
  isOpen: boolean;
  title: string;
  description?: string;
  onClose: () => void;
}

const ChangeCity = ({ isOpen, onClose, title, description }: ChangeCityProps) => {
  return isOpen ? (
    <div className="fixed top-44 right-2">
      <div className="flex flex-col rounded-lg bg-white p-4 max-w-xs">
        <div>
          <p className="m-0 text-black text-xl font-medium">Attention!</p>
        </div>
        <div className="mt-1">
          <p className="m-0 text-cod-gray text-base font-normal">{title}</p>
          {description ? <p className="mt-2 text-venetian_red">{description}</p> : null}
        </div>
        <div className="flex flex-row gap-x-4 justify-center mt-6">
          <button
            onClick={onClose}
            className="bg-venetian_red outline-none flex flex-row justify-center items-center px-4 py-3 w-36 rounded cursor-pointer border-0"
          >
            <p className="m-0 text-white text-sm leading-4 font-medium mr-2">Cancel</p>
            <Close fill={'#ffffff'} />
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default ChangeCity;
