const Logout = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="#4D4D4D"
        d="M19.337 9.787A10.431 10.431 0 0010 3.125 10.431 10.431 0 00.662 9.787a.625.625 0 000 .425A10.43 10.43 0 0010 16.876a10.43 10.43 0 009.337-6.662.626.626 0 000-.425zM10 15.625c-3.313 0-6.813-2.456-8.081-5.625C3.187 6.831 6.687 4.375 10 4.375c3.313 0 6.813 2.456 8.081 5.625-1.268 3.169-4.768 5.625-8.081 5.625z"
      ></path>
      <path
        fill="#4D4D4D"
        d="M10 6.25a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm0 6.25a2.5 2.5 0 110-5 2.5 2.5 0 010 5z"
      ></path>
    </svg>
  );
};

export default Logout;
