import Api from '../axios/api';

export interface Player {
  id: string;
  name: string;
  role: string;
}

export interface LobbyI {
  id: string;
  name: string;
  chatId: string;
  isOpen: boolean;
  author: Player;
  players: Player[];
}

interface PostLobbyResponse {
  data: LobbyI;
}

export class LobbyService {
  static create(name?: string): Promise<PostLobbyResponse> {
    return Api.post(`/v1/lobby`, { name });
  }

  static getOne(id: string): Promise<PostLobbyResponse> {
    return Api.get(`/v1/lobby/${id}`);
  }

  static join(id: string): Promise<PostLobbyResponse> {
    return Api.post(`/v1/lobby/${id}/join`);
  }

  static leave(id: string): Promise<PostLobbyResponse> {
    return Api.post(`/v1/lobby/${id}/leave`);
  }

  static shuffle(id: string): Promise<PostLobbyResponse> {
    return Api.post(`/v1/lobby/${id}/shuffle`);
  }

  static start(id: string): Promise<PostLobbyResponse> {
    return Api.post(`/v1/lobby/${id}/start`);
  }
}
