import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

export interface PlayerInterface {
  name: string;
  active: boolean;
}

export interface PlayersProps {
  players: PlayerInterface[];
}

const Players: FC<PlayersProps> = ({ players }) => {
  const [localPlayers, setLocalPlayers] = useState<PlayerInterface[]>([]);
  const [nrOfPlayers, setNrOfPlayers] = useState<number>(5);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement> | undefined) => {
    const value = e?.target.value ? parseInt(e?.target.value) : 5;
    setNrOfPlayers(value);
  };

  useEffect(() => {
    let newPlayers = [];
    for (let i = 0; i < nrOfPlayers - players.length; i++) {
      newPlayers.push({
        name: `Player ${players.length + i + 1}`,
        active: false,
      });
    }
    setLocalPlayers([...players, ...newPlayers]);
  }, [nrOfPlayers, players]);

  return (
    <div className="flex flex-col w-max">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row">
          <p className="m-0 text-white text-2xl font-medium">Players</p>
          <select
            onChange={onChange}
            value={nrOfPlayers}
            className="ml-2 bg-white-20 rounded border-0 px-2 text-white outline-none"
          >
            <option value="5" selected={nrOfPlayers === 5} disabled={players.length > 5}>
              5
            </option>
            <option value="4" selected={nrOfPlayers === 4} disabled={players.length > 4}>
              4
            </option>
            <option value="3" selected={nrOfPlayers === 3} disabled={players.length > 3}>
              3
            </option>
          </select>
        </div>
        <div className="flex flex-row">
          <p className="m-0 text-white font-normal text-xl">{players.length}</p>
          <p className="m-0 text-white-50 font-normal text-xl">/{nrOfPlayers}</p>
        </div>
      </div>
      <div className="flex flex-row gap-2 mt-4 w-max">
        {localPlayers?.length &&
          localPlayers?.map((p, i) => {
            return (
              <div key={i} className={`flex flex-col`}>
                <div className="px-2">
                  <div
                    className={classNames(
                      'flex flex-row justify-center items-center w-16 skew-x-12 h-16 border-2 border-solid border-t-8',
                      {
                        'border-t-steel-blue': !p.active,
                        'border-transparent': !p.active,
                        'border-jungle-green': p.active,
                        'bg-astronaut-blue': p.active,
                        'bg-white-40': !p.active,
                      },
                    )}
                  />
                </div>
                <div className="flex flex-row ml-4 mt-2">
                  <p className="m-0 text-xs font-normal text-white">{p.name}</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Players;
