import EventEmitter from 'eventemitter3';

export enum EventType {
  CHANGE_SELECT_CITY = 'change_select_city',
  CHANGE_SELECT_CITY_CARD = 'change_select_city_card',
  REVERT_SELECT_CITY_CARD = 'revert_select_city_card',
  ADD_NEW_BUILD = 'add_new_build',
}

const eventEmitter = new EventEmitter();

const Emitter = {
  on: (event: any, fn: (...args: any[]) => void) => eventEmitter.on(event, fn),
  once: (event: any, fn: (...args: any[]) => void) => eventEmitter.once(event, fn),
  off: (event: any, fn?: ((...args: any[]) => void) | undefined) => eventEmitter.off(event, fn),
  emit: (event: any, payload?: any) => eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);

export default Emitter;
