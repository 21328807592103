import React from 'react';
import Modal from 'react-modal';
import Checkmark from '../../assets/Checkmark';
import Close from '../../assets/Close';

interface AddPinProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSuccess: () => void;
}

const AddPin = ({ isOpen, onClose, onSuccess, title }: AddPinProps) => {
  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onClose}
      className="modal_inside max-w-md"
      overlayClassName="modal_overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col rounded-lg bg-white-85 p-4">
        <div>
          <p className="m-0 text-black text-xl font-medium">Attention!</p>
        </div>
        <div className="mt-1">
          <p className="m-0 text-cod-gray text-base font-normal">{title}</p>
        </div>
        <div className="flex flex-row gap-x-4 justify-center mt-6">
          <button
            onClick={onClose}
            className="bg-transparent outline-none flex flex-row justify-center items-center px-4 py-3 w-36 rounded border-solid border border-forest-green cursor-pointer"
          >
            <p className="m-0 text-black text-sm leading-4 font-medium mr-2">No</p>
            <Close />
          </button>
          <button
            onClick={onSuccess}
            className="outline-none flex flex-row justify-center items-center px-4 py-3 w-36 rounded border-solid border border-forest-green bg-forest-green cursor-pointer"
          >
            <p className="m-0 text-white text-sm leading-4 font-medium mr-2">Yes</p>
            <Checkmark />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddPin;
