import Api from '../axios/api';
import { AttributeInterface, InstitutionInterface, StructureInterface } from '../components/Roles';

export interface Player {
  id: string;
  name: string;
  role: string;
}
export interface ConstructionI {
  type: string;
  typeBuild: string;
  count?: number;
  procentX: number;
  procentY: number;
  country: string;
  label: string;
  userId?: string;
  card_value?: string;
}

export interface CardI {
  resources: number;
  greenResources: number;
  institutions: number;
}
export interface CityI {
  name: string;
  id: number;
}
export interface CountryI {
  name: string;
  id: number;
  cities?: CityI[];
}

export interface CoordsI {
  cityId: number;
  x: number;
  y: number;
}
export interface DangerCardI {
  name?: string;
  description?: string;
  action?: string;
  icon?: string;
  use_power?: string;
  log?: string;
}

export interface CardsI {
  resources?: StructureInterface[];
  remainedResources?: number;
  greenResources?: StructureInterface[];
  remainedGreenResources?: number;
  institutions?: InstitutionInterface[];
  remainedInstitutions?: InstitutionInterface[];
  remainedInstitutionCount?: number;
}

export interface StateCardsI {
  [key: string]: CardsI | undefined;
}

export interface EmissionI {
  modifier: number;
  emission: number;
}

export interface StateGameI {
  constructions?: ConstructionI[];
  currentCards: StateCardsI;
  modifier: number;
  emission: number;
  lastModifier: number;
  lastEmission: number;
  currentActor: string;
  turnFull: string[];
  constructionsLastRound: string[];
  isEnded?: boolean;
  isOver?: boolean;
  isWinner?: boolean;
  round: string[];
  rounds: number;
  minorDanger?: string;
  danger?: string;
  dangerSubmited: boolean;
  usedPowers: string[];
  usedCards: string[];
}
export interface GameI {
  id: string;
  lobbyId: string;
  name?: string;
  chatId: string;
  isEnded: boolean;
  author: Player;
  players: Player[];
  state: StateGameI;
}

export interface StateStateI {
  procentX: number;
  procentY: number;
  type: string;
  typeBuild: string;
  country?: string;
  label: string;
  isEnded?: boolean;
  card_value?: string;
  card?: StructureInterface | InstitutionInterface | AttributeInterface | DangerCardI;
  old_type?: string;
  userId?: string;
}
export interface GameStateI {
  id?: string;
  gameId: string;
  type: string;
  author?: Player;
  state: StateStateI;
  worldState?: StateGameI;
  createdAt?: string;
}

interface GetGameResponse {
  data: GameI;
}

interface GameActionsResponse {
  count: number;
  items: GameStateI[];
}

interface GetGameActionsResponse {
  data: GameActionsResponse;
}

interface AddGameActionsResponse {
  state: StateStateI | undefined;
  type: string;
  worldState: StateGameI | undefined;
}

export class GameService {
  static getOne(id: string): Promise<GetGameResponse> {
    return Api.get(`/v1/game/${id}`);
  }

  static getActions(
    id: string,
    type?: string,
    limit = 10,
    skip = 0,
  ): Promise<GetGameActionsResponse> {
    return Api.get(`/v1/game/${id}/actions`, { params: { limit, type, skip } });
  }

  static addActions(id: string, data: AddGameActionsResponse): Promise<{ data: GameStateI }> {
    return Api.post(`/v1/game/${id}/actions`, {
      state: data.state || {},
      type: data.type,
      worldState: data.worldState || {},
    });
  }

  static end(id: string): Promise<{ data: GameStateI }> {
    return Api.post(`/v1/game/${id}/end`);
  }
}
