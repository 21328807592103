const Send = ({ fill = '#ffffff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M20.782 3.217a.75.75 0 00-.787-.172l-16.5 6a.75.75 0 000 1.402l7.2 2.88 2.88 7.2a.75.75 0 00.675.473.75.75 0 00.69-.495l6-16.5a.75.75 0 00-.158-.788zM14.25 18.15l-2.092-5.25 3.592-3.593-1.057-1.057-3.623 3.622L5.85 9.75l13.147-4.748L14.25 18.15z"
      ></path>
    </svg>
  );
};

export default Send;
