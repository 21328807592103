const NewLobby = ({ color = '#ffffff', size = 28 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color}
        d="M16.25 16.25H3.75V3.75H10V2.5H3.75A1.251 1.251 0 002.5 3.75v12.5a1.251 1.251 0 001.25 1.25h12.5a1.25 1.25 0 001.25-1.25V10h-1.25v6.25z"
      ></path>
      <path
        fill={color}
        d="M16.25 16.25H3.75V3.75H10V2.5H3.75A1.251 1.251 0 002.5 3.75v12.5a1.251 1.251 0 001.25 1.25h12.5a1.25 1.25 0 001.25-1.25V10h-1.25v6.25z"
      ></path>
      <path fill={color} d="M16.25 3.75v-2.5H15v2.5h-2.5V5H15v2.5h1.25V5h2.5V3.75h-2.5z"></path>
    </svg>
  );
};

export default NewLobby;
