export enum ActionType {
  ADD_BUILD = 'add_build',
  TAKE_BOTTOM_FIVE_CARDS = 'take_bottom_five_cards',
  BUILD_GREEN_STRUCTURE_OR_TWO_NON_GREEN_STRUCTURE = 'build_green_structure_or_two_non_green_structure',
  GOVERNMENT_NON_GREEN_STRUCTURE_TO_GREEN = 'government_non_green_structure_to_green',
  BUILDINGS_CONVERT_TO_GOVERNMENT_AND_CONSUMER = 'buildings_convert_to_government_and_consumer',
  FIVE_GREEN_RESOURCE_CARDS_TO_TOP = 'five_green_resource_cards_to_top',
  DOWN_ALL_INFRASTRUCTURE = 'down_all_infrastructure',
  ONE_GREEN_CARD_FOR_TWO = 'one_green_card_for_two',
  PLACE_UP_TO_FIVE_STRUCTURE_RESOURCE_CARDS = 'place_up_to_five_structure_resource_cards',
  RESTORE_INFRASTRUCTURE_IN_THREE_CITIES = 'restore_infrastructure_in_three_cities',
  MAKE_ONE_YOUR_BUILD_TO_GREEN = 'make_one_your_build_to_green',
  MAKE_ALL_NON_GREEN_WITHOUT_GOVERNMENT = 'make_all_non_green_without_government',
  REDUCING_EMISSIONS_PRODUCED = 'reducing_emissions_produced',
  SCIENCE_CARBON_CAPTURE = 'science_carbon_capture',
  SCIENCE_LAB_GROWN_MEAT = 'science_lab_grown_meat',
  SCIENCE_NUCLEAR_ENERGY = 'science_nuclear_energy',
  SCIENCE_PLANT_BASED_PLASTIC = 'science_plant_based_plastic',
  SCIENCE_COOL_PAVEMENTS = 'science_cool_pavements',
  SCIENCE_MACHINE_SORTED_RECYCLE = 'science_machine_sorted_recycle',
  SCIENCE_REQUEST_FUNDING_FROM_DONORS = 'science_request_funding_from_donors',
  GOVERNMENT_CORPORATE_POLLUTION_TAX = 'government_corporate_pollution_tax',
  GOVERNMENT_MEAT_ALTERNATIVE_SUBSIDIES = 'government_meat_alternative_subsidies',
  GOVERNMENT_NUCLEAR_ENERGY = 'government_nuclear_energy',
  GOVERNMENT_RECYCLING_PROGRAM = 'government_recycling_program',
  GOVERNMENT_ROAD_INFRASTRUCTURE_UPDATE = 'government_road_infrastructure_update',
  GOVERNMENT_LOBBYING = 'government_lobbying',
  GOVERNMENT_GOVERNMENT_RESEARCH = 'government_government_research',
  ENVIRONMENTAL_ACTIVIST_ANIMAL_AGRICULTURE = 'environmental_activist_animal_agriculture',
  ENVIRONMENTAL_ACTIVIST_DEFORESTATION = 'environmental_activist_deforestation',
  ENVIRONMENTAL_ACTIVIST_FOSSIL_FUELS_FRACKING = 'environmental_activist_fossil_fuels_fracking',
  ENVIRONMENTAL_ACTIVIST_INFRASTRUCTURE = 'environmental_activist_infrastructure',
  ENVIRONMENTAL_ACTIVIST_GREEN_ENERGY = 'environmental_activist_green_energy',
  ENVIRONMENTAL_ACTIVIST_DONATIONS_FOR_CONSERVATION = 'environmental_activist_donations_for_conservation',
  ENVIRONMENTAL_ACTIVIST_MASS_RALLY = 'environmental_activist_mass_rally',
  CONSUMER_CLOSED_CLOTHING_PRODUCTION = 'consumer_closed_clothing_production',
  CONSUMER_MEAT_ALTERNATIVES = 'consumer_meat_alternatives',
  CONSUMER_GREEN_ENERGY = 'consumer_green_energy',
  CONSUMER_SUSTAINABLE_PLASTIC = 'consumer_sustainable_plastic',
  CONSUMER_CARBON_CAPTURE = 'consumer_carbon_capture',
  CONSUMER_CHEAPER_GOODS = 'consumer_cheaper_goods',
  CONSUMER_SMALL_BUSINESSES = 'consumer_small_businesses',
  BUSINESS_ALTERNATIVE_MEAT_PRODUCTS = 'business_alternative_meat_products',
  BUSINESS_CLOSED_CLOTHING_PRODUCTION = 'business_closed_clothing_production',
  BUSINESS_GREEN_ENERGY = 'business_green_energy',
  BUSINESS_GREEN_PLASTIC_PRODUCTS = 'business_green_plastic_products',
  BUSINESS_RECYCLING_PROGRAM = 'business_recycling_program',
  BUSINESS_INCREASE_MARGINS = 'business_increase_margins',
  BUSINESS_R_D_X = 'business_r_d_x',
}
