export const AREAS = [
  [
    357, 373, 357, 373, 432, 381, 471, 404, 510, 416, 538, 429, 558, 452, 580, 478, 595, 513, 591,
    530, 579, 554, 546, 569, 530, 580, 514, 598, 492, 608, 474, 611, 453, 611, 427, 611, 408, 605,
    394, 602, 378, 595, 369, 592, 327, 590, 308, 590, 267, 537, 272, 513, 260, 439, 299, 420, 323,
    399, 335, 392, 349, 380,
  ],
  [
    359, 370, 435, 380, 472, 402, 510, 412, 530, 421, 547, 434, 576, 471, 586, 486, 597, 511, 607,
    453, 635, 419, 645, 371, 583, 347, 548, 335, 511, 319, 469, 303, 411, 281, 403, 293, 382, 318,
    356, 331,
  ],
  [
    412, 280, 436, 242, 454, 204, 469, 168, 515, 150, 557, 158, 580, 173, 595, 198, 617, 221, 642,
    246, 664, 269, 655, 277, 654, 321, 654, 340, 646, 370, 555, 334, 459, 297,
  ],
  [
    69, 179, 77, 161, 87, 157, 103, 155, 116, 157, 140, 158, 160, 159, 178, 152, 180, 143, 187, 128,
    188, 118, 196, 116, 208, 116, 223, 121, 231, 130, 247, 135, 258, 137, 276, 146, 316, 152, 384,
    151, 397, 164, 413, 172, 406, 187, 423, 204, 420, 217, 415, 236, 405, 240, 391, 246, 378, 256,
    376, 270, 386, 291, 379, 302, 368, 323, 326, 338, 286, 333, 248, 339, 212, 317, 196, 321, 181,
    317, 144, 324, 131, 317, 132, 296, 156, 285, 178, 281, 201, 264, 211, 252, 142, 219, 148, 209,
    146, 200, 130, 198, 100, 192,
  ],
  [
    1061, 391, 1051, 382, 1041, 375, 1034, 385, 1032, 392, 1027, 378, 1020, 364, 1016, 350, 1019,
    342, 1021, 331, 1023, 320, 1013, 316, 1006, 310, 996, 303, 1001, 291, 1005, 282, 1014, 274,
    1021, 266, 1029, 260, 1033, 264, 1039, 272, 1048, 282, 1055, 288, 1055, 297, 1062, 293, 1072,
    286, 1111, 293, 1142, 336, 1139, 389, 1116, 387, 1090, 413, 1079, 413,
  ],
  [
    800, 346, 814, 336, 826, 299, 842, 286, 862, 294, 885, 289, 912, 256, 921, 244, 955, 262, 1001,
    284, 993, 304, 1020, 324, 1014, 350, 1032, 401, 971, 459, 938, 445, 932, 427, 842, 392, 828,
    402, 799, 389,
  ],
  [
    815, 282, 803, 288, 799, 301, 780, 311, 754, 305, 753, 293, 767, 275, 771, 253, 787, 247, 762,
    212, 768, 189, 790, 153, 817, 110, 879, 105, 871, 137, 843, 146, 842, 163, 861, 177, 876, 186,
    897, 202, 926, 230, 924, 236, 907, 259, 884, 287, 862, 291, 842, 284, 826, 296,
  ],
  [
    880, 107, 873, 139, 846, 148, 845, 162, 894, 196, 915, 216, 929, 230, 922, 242, 963, 263, 1002,
    282, 1027, 258, 1052, 207, 1043, 177, 1016, 168, 976, 122, 936, 127,
  ],
  [
    304, 805, 324, 780, 313, 772, 309, 762, 309, 753, 312, 742, 322, 735, 332, 734, 338, 720, 340,
    706, 350, 698, 365, 692, 385, 691, 399, 693, 410, 698, 421, 705, 435, 717, 453, 725, 464, 719,
    471, 711, 482, 710, 502, 716, 516, 719, 527, 717, 534, 700, 589, 667, 625, 677, 651, 691, 668,
    705, 695, 724, 709, 737, 712, 753, 717, 777, 721, 804, 710, 814, 706, 833, 705, 843, 697, 850,
    687, 853, 670, 858, 647, 857, 630, 857, 603, 847, 579, 836, 569, 838, 555, 842, 537, 847, 525,
    852, 506, 857, 494, 858, 478, 859, 463, 861, 444, 853, 426, 843, 412, 829, 399, 808, 388, 796,
    374, 795, 347, 801, 327, 805,
  ],
  [
    847, 663, 857, 698, 854, 701, 852, 710, 854, 720, 855, 726, 859, 735, 862, 743, 870, 749, 879,
    757, 884, 762, 887, 768, 884, 775, 876, 780, 876, 789, 883, 795, 897, 801, 916, 808, 939, 810,
    957, 812, 966, 815, 974, 822, 977, 827, 978, 834, 976, 841, 966, 850, 955, 855, 944, 859, 932,
    861, 918, 864, 907, 865, 894, 865, 882, 865, 870, 865, 859, 865, 841, 865, 831, 864, 820, 862,
    812, 860, 801, 859, 793, 855, 781, 848, 771, 842, 763, 836, 754, 826, 748, 815, 740, 805, 731,
    801, 723, 803, 720, 780, 719, 772, 716, 762, 714, 753, 712, 742, 710, 734, 686, 715, 673, 706,
    661, 697, 670, 687, 678, 684, 695, 684, 705, 679, 701, 668, 700, 659, 709, 655, 719, 653, 729,
    650, 739, 646, 745, 637, 751, 632, 758, 627, 767, 619, 775, 612, 785, 605, 794, 603, 803, 602,
    809, 597, 817, 592, 831, 590, 845, 588, 883, 621, 873, 644,
  ],
  [
    833, 467, 817, 499, 817, 529, 827, 543, 842, 546, 847, 559, 876, 571, 885, 579, 917, 581, 915,
    560, 936, 532, 907, 498, 876, 490, 874, 471,
  ],
  [
    592, 664, 630, 677, 651, 687, 661, 695, 668, 687, 675, 682, 686, 682, 695, 682, 702, 677, 697,
    668, 697, 658, 709, 654, 719, 649, 733, 646, 743, 635, 757, 617, 767, 613, 777, 595, 793, 598,
    807, 595, 798, 585, 804, 552, 804, 542, 794, 536, 786, 525, 777, 514, 771, 482, 756, 458, 758,
    445, 710, 438, 669, 458, 649, 459, 626, 494, 620, 530, 622, 555, 565, 607, 589, 635,
  ],
  [
    1093, 709, 1108, 701, 1126, 691, 1141, 682, 1163, 668, 1188, 654, 1198, 650, 1215, 668, 1238,
    677, 1253, 688, 1266, 701, 1276, 709, 1290, 724, 1303, 740, 1307, 756, 1313, 779, 1316, 802,
    1316, 815, 1309, 824, 1295, 833, 1297, 847, 1306, 847, 1316, 853, 1319, 872, 1318, 897, 1318,
    946, 1302, 946, 1293, 946, 1282, 946, 1272, 948, 1263, 955, 1254, 960, 1245, 962, 1234, 964,
    1220, 941, 1197, 897, 1191, 870, 1183, 857, 1164, 855, 1147, 856, 1137, 850, 1141, 835, 1135,
    815, 1123, 809, 1105, 819, 1103, 810, 1095, 803, 1081, 804, 1077, 787, 1081, 768, 1099, 747,
    1104, 727, 1103, 716,
  ],
  [
    1201, 646, 1194, 636, 1192, 623, 1200, 614, 1206, 607, 1215, 601, 1229, 592, 1239, 583, 1250,
    578, 1263, 572, 1281, 565, 1298, 562, 1303, 568, 1315, 569, 1328, 572, 1336, 573, 1347, 577,
    1356, 579, 1367, 586, 1375, 595, 1383, 606, 1385, 619, 1383, 630, 1381, 644, 1378, 654, 1380,
    670, 1375, 684, 1370, 705, 1362, 730, 1355, 751, 1340, 740, 1328, 733, 1313, 730, 1300, 732,
    1285, 714, 1263, 694, 1250, 682, 1231, 672, 1216, 665,
  ],
  [
    1091, 707, 1103, 674, 1099, 665, 1084, 667, 1070, 672, 1053, 678, 1000, 681, 969, 678, 955, 675,
    948, 663, 936, 642, 946, 639, 967, 631, 986, 621, 1005, 612, 1013, 607, 1018, 595, 1016, 586,
    1035, 581, 1053, 579, 1066, 590, 1085, 598, 1099, 607, 1114, 621, 1131, 609, 1147, 613, 1168,
    617, 1189, 623, 1191, 636, 1198, 647, 1175, 658, 1156, 669, 1130, 687,
  ],
  [
    1016, 584, 1015, 572, 1016, 562, 1020, 548, 1020, 537, 1014, 519, 1009, 502, 1011, 490, 1019,
    476, 1027, 471, 1035, 468, 1044, 469, 1051, 472, 1056, 480, 1060, 486, 1066, 491, 1072, 495,
    1083, 491, 1090, 487, 1100, 485, 1109, 486, 1117, 485, 1122, 478, 1123, 468, 1122, 460, 1121,
    450, 1119, 441, 1128, 434, 1135, 435, 1144, 434, 1151, 435, 1159, 436, 1169, 439, 1177, 438,
    1184, 445, 1192, 450, 1200, 457, 1210, 463, 1222, 471, 1226, 477, 1229, 483, 1231, 492, 1234,
    514, 1242, 519, 1245, 518, 1252, 523, 1261, 525, 1270, 532, 1276, 536, 1284, 541, 1290, 548,
    1296, 561, 1282, 562, 1273, 566, 1264, 570, 1250, 575, 1238, 581, 1229, 590, 1214, 599, 1202,
    608, 1191, 622, 1160, 613, 1131, 607, 1114, 617, 1108, 611, 1089, 597, 1075, 592, 1066, 586,
    1055, 576, 1039, 579,
  ],
];
