const DoubleArrowUp = ({ fill = '#ffffff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill={fill}
        d="M2.5 17.5l7.5-5.877 7.5 5.877v-2.07L10 9.551 2.5 15.43V17.5zm0-7.052L10 4.57l7.5 5.877V8.377L10 2.5 2.5 8.377v2.07z"
      ></path>
    </svg>
  );
};

export default DoubleArrowUp;
