import Bank from '../assets/Bank';
import Chemistry from '../assets/Chemistry';
import Industry from '../assets/Industry';
import Store from '../assets/Store';
import User from '../assets/User';
import { ActionType } from '../enums/action-type';
import { ConstructionTypes } from '../enums/construction-type';
import { ConstructionTypesBuild } from '../enums/construction-type-build';

export const ROLES = [
  {
    name: 'CardMaster',
    role: 'Science',
    roleDb: 'science',
    structures: [
      {
        log: 'constructed a Laboratory, increasing the modifier by 5',
        name: 'Laboratories',
        type: ConstructionTypes.CHEMISTRY,
        typeBuild: ConstructionTypesBuild.RESOURCE,
        card_value: 'science_resource',
        action: ActionType.ADD_BUILD,
      },
      {
        log: 'transformed a Laboratory into a Green Laboratory, decreasing the modifier by 5',
        name: 'Green Laboratories',
        type: ConstructionTypes.CHEMISTRY,
        typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
        card_value: 'science_green_resource',
        action: ActionType.ADD_BUILD,
      },
    ],
    icon: '/images/science.png',
    bg: '/images/science.jpg',
    gameIcon: Chemistry,
    description:
      'You are pioneering new green technologies, but to do so, you need to build laboratories. You are tasked with making as many discoveries as possible. Through these discoveries, you are able to accumulate funds to expand and institute new and sustainable research structures.',
    attributes: [
      {
        name: 'Advanced searching algorithm',
        description: `Once per turn, the scientist can take the bottom five cards of any player's deck, and place them anywhere in the deck, without looking at other cards.`,
        card_value: 'science_advanced_searching_algorithm',
        action: ActionType.TAKE_BOTTOM_FIVE_CARDS,
        action_description: `You can take the bottom five cards of any player's deck, and place them on top, without looking at the cards.`,
      },
      {
        name: 'Research Grant',
        description:
          'The city has provided you with grant money to explore sustainable inventions and conduct research in a new Research Lab! Build up to two non-green structures or one green structure. Discard one structure card per non-green structure or one green card for the green structure.',
        card_value: 'science_research_grant',
        action: ActionType.BUILD_GREEN_STRUCTURE_OR_TWO_NON_GREEN_STRUCTURE,
        action_description:
          'Next turn, you can build up to two non-green structures or one green structure using one structure card per non-green structure or one green card for the green structure.',
      },
    ],
    institutions: [
      {
        name: 'Carbon Capture',
        value: 'carbon_capture',
        effect: ['This card reduces the modifier by 20 points.'],
        modifier: -20,
        card_value: 'science_carbon_capture',
        action: ActionType.SCIENCE_CARBON_CAPTURE,
        requirements: [],
        cost: [
          'To finally find a solution, scientists have to pull all-nighters, and are ultimately fed up. Get rid of 1 standard laboratory.',
        ],
        type: 'Invention',
      },
      {
        name: 'Lab Grown Meat',
        value: 'lab_grown_meat',
        effect: ['Reduces modifier by 5 points.'],
        modifier: -5,
        action: ActionType.SCIENCE_LAB_GROWN_MEAT,
        card_value: 'science_lab_grown_meat',
        requirements: ['Demand: Meat Alternatives'],
        cost: [],
        type: 'Invention',
      },
      {
        name: 'Nuclear Energy',
        value: 'nuclear_energy',
        effect: [
          'You have found a safe and effective way to use nuclear energy. This card is a prerequisite for other institution cards, collaborate with your teammates and benefit from it.',
        ],
        modifier: 0,
        card_value: 'science_nuclear_energy',
        requirements: [
          'The word nuclear still carries a negative connotation, so unless the government can be convinced to embrace this new technology, widespread adoption will be impossible. The Policy: Nuclear Energy is required.',
        ],
        action: ActionType.SCIENCE_NUCLEAR_ENERGY,
        cost: ['In the process, a research lab is removed from the map.'],
        type: 'Invention',
      },
      {
        name: 'Plant-based Plastic',
        value: 'plant_based_plastic',
        effect: ['Decrease modifier by 10.'],
        modifier: -10,
        card_value: 'science_plant_based_plastic',
        requirements: [],
        action: ActionType.SCIENCE_PLANT_BASED_PLASTIC,
        cost: [
          'If Product: Green Plastic Products was played then double the amount of non-green science structures in one city.',
          'If the card was not played, remove once science structure from the map.',
        ],
        type: 'Invention',
      },
      {
        name: 'Cool Pavements',
        value: 'cool_pavements',
        effect: [
          'You found an alternative to our impermeable heat retaining roads. This card is a prerequisite for other institution cards, collaborate with your teammates and benefit from it.',
        ],
        modifier: 0,
        card_value: 'science_cool_pavements',
        requirements: [],
        action: ActionType.SCIENCE_COOL_PAVEMENTS,
        cost: [
          'If Policy: Road Infrastructure Update is already played, the cost is negligible and you should double the amount of non-green structures in one city.',
          'If the card was not played, this turns out to be an expensive endeavor pulling funding from other laboratories, removing science infrastructure from 1 city.',
        ],
        type: 'Invention',
      },
      {
        name: 'Machine Sorted Recycle',
        value: 'machine_sorted_recycle',
        effect: [`Reduce modifier by 10.`],
        modifier: -10,
        card_value: 'science_machine_sorted_recycle',
        action: ActionType.SCIENCE_MACHINE_SORTED_RECYCLE,
        requirements: [],
        cost: [
          'Spend one structural resource card to build the machine necessary to implement a pilot program.',
        ],
        type: 'Invention',
      },
      {
        name: 'Machine Sorted Recycle',
        value: 'machine_sorted_recycle_1',
        effect: [`Reduce modifier by 10.`],
        modifier: -10,
        card_value: 'science_machine_sorted_recycle_1',
        action: ActionType.SCIENCE_MACHINE_SORTED_RECYCLE,
        requirements: [],
        cost: [
          'Spend one structural resource card to build the machine necessary to implement a pilot program. ',
        ],
        type: 'Invention',
      },
      {
        name: 'Request Funding From Donors',
        value: 'request_funding_from_donors',
        effect: [
          'Draw three additional resource cards and immediately build one green structure without the need for additional green resource cards.',
        ],
        modifier: 10,
        card_value: 'science_request_funding_from_donors',
        requirements: [],
        cost: [
          'Increase the emissions bar by 10, as some partnerships might not fully align with green initiatives.',
        ],
        action: ActionType.SCIENCE_REQUEST_FUNDING_FROM_DONORS,
        type: 'Invention',
      },
      {
        name: 'Request Funding From Donors',
        value: 'request_funding_from_donors_1',
        effect: [
          'Draw three additional resource cards and immediately build one green structure without the need for additional green resource cards.',
        ],
        modifier: 0,
        card_value: 'science_request_funding_from_donors_1',
        requirements: [],
        cost: [
          'Increase the emissions bar by 10, as some partnerships might not fully align with green initiatives.',
        ],
        action: ActionType.SCIENCE_REQUEST_FUNDING_FROM_DONORS,
        type: 'Invention',
      },
      {
        name: 'Request Funding From Donors',
        value: 'request_funding_from_donors_2',
        effect: [
          'Draw three additional resource cards and immediately build one green structure without the need for additional green resource cards.',
        ],
        modifier: 0,
        card_value: 'science_request_funding_from_donors_2',
        requirements: [],
        cost: [
          'Increase the emissions bar by 10, as some partnerships might not fully align with green initiatives.',
        ],
        action: ActionType.SCIENCE_REQUEST_FUNDING_FROM_DONORS,
        type: 'Invention',
      },
    ],
  },
  {
    name: 'AceInTheDeck',
    role: 'Government',
    roleDb: 'government',
    structures: [
      {
        log: 'constructed a Capitol Building, increasing the modifier by 5',
        name: 'Capitol Building',
        type: ConstructionTypes.BANK,
        typeBuild: ConstructionTypesBuild.RESOURCE,
        card_value: 'government_resource',
        action: ActionType.ADD_BUILD,
      },
      {
        log: 'transformed a Capitol Building into a Green Capitol Building, decreasing the modifier by 5',
        name: 'Green Capitol Building',
        type: ConstructionTypes.BANK,
        typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
        card_value: 'government_green_resource',
        action: ActionType.ADD_BUILD,
      },
    ],
    icon: '/images/government.png',
    bg: '/images/government.jpg',
    gameIcon: Bank,
    description:
      'Your job is to create policies and appease the masses. While the people are hesitant to accept green infrastructure, imminent environmental disasters will cause greater unrest. You are tasked with staying in office by gaining as many votes as possible, therefore being able to institute and expand new structures.',
    attributes: [
      {
        name: 'Green mandate',
        description:
          'Once per turn, it can flip over any government non-green structure and make it green. The government has to discard one green card in their hand. Use one green card instead of 2.',
        card_value: 'government_green_mandate',
        action: ActionType.GOVERNMENT_NON_GREEN_STRUCTURE_TO_GREEN,
        action_description:
          'Once per turn, you can flip over any government non-green structure and make it green. The government has to discard one green card from their hand instead of 2.',
      },
      {
        name: 'Residential zoning',
        description:
          'Make a city a residential-only zone, for 3 turns players can’t build any infrastructure there. Existing buildings are converted into government buildings and consumer buildings (you split the converted buildings between the two institutions). Government takes priority.',
        card_value: 'government_residential_zoning',
        action: ActionType.BUILDINGS_CONVERT_TO_GOVERNMENT_AND_CONSUMER,
        action_description:
          'Make a city a residential-only zone, for 3 turns players can’t build any infrastructure there. Existing buildings are converted into government structures and consumer structures.',
      },
    ],
    institutions: [
      {
        name: 'Corporate Pollution Tax',
        value: 'corporate_pollution_tax',
        effect: [
          'The business faction is required to provide you with one resource card per every three of their non-green structures',
        ],
        modifier: 0,
        card_value: 'government_corporate_pollution_tax',
        action: ActionType.GOVERNMENT_CORPORATE_POLLUTION_TAX,
        requirements: [],
        cost: ['Corporations pull lobbying support and you lose one government district.'],
        type: 'Policy',
      },
      {
        name: 'Meat Alternative Subsidies',
        value: 'meat_alternative_subsidies',
        effect: ['Decreases modifier by 10 points.'],
        modifier: -10,
        action: ActionType.GOVERNMENT_MEAT_ALTERNATIVE_SUBSIDIES,
        card_value: 'government_meat_alternative_subsidies',
        requirements: [],
        cost: [
          'If the Demand: Meat Alternatives and Invention: Lab Grown Meat were already played The cost is negligible.',
          'If the cards above were not played This becomes unpopular with voters, and while you were successful in implementing this policy, you take back three standard structures from any city.',
        ],
        type: 'Policy',
      },
      {
        name: 'Nuclear energy',
        value: 'nuclear_energy',
        effect: ['This reduces the modifier by 35.'],
        modifier: -35,
        action: ActionType.GOVERNMENT_NUCLEAR_ENERGY,
        card_value: 'government_nuclear_energy',
        requirements: [],
        cost: [
          'If Demand: Green Energy Costs are negligible',
          'Else People fear the implementation of a nuclear program, and vote you out of office in one city, remove all government infrastructure in one city. You should have at least one in that city.',
        ],
        type: 'Policy',
      },
      {
        name: 'Recycling Program',
        value: 'recycling_program',
        effect: ['Reduce the modifier by 25.'],
        modifier: -25,
        card_value: 'government_recycling_program',
        requirements: ['Invention: Machine Sorted Recycle'],
        cost: [],
        action: ActionType.GOVERNMENT_RECYCLING_PROGRAM,
        type: 'Policy',
      },
      {
        name: 'Road Infrastructure Update',
        value: 'road_infrastructure_update',
        effect: ['Decrease the modifier by 20 points.'],
        modifier: -20,
        card_value: 'government_road_infrastructure_update',
        requirements: ['Invention: Cool Pavements'],
        cost: [
          'The expense involved was unpopular with wealthy taxpayers, and they pulled support from one of your cities, remove all government infrastructure in a city.',
        ],
        action: ActionType.GOVERNMENT_ROAD_INFRASTRUCTURE_UPDATE,
        type: 'Policy',
      },
      {
        name: 'Lobbying',
        value: 'lobbying',
        effect: [
          'You accepted donations from major lobbies and to keep donations incoming, you implemented more lenient policies for these lobbies, add 5 government building in any cities.',
        ],
        modifier: 50,
        card_value: 'government_lobbying',
        requirements: [],
        cost: [
          'These large lobbies did not have environmental interests in mind, increase the modifier by 50',
        ],
        action: ActionType.GOVERNMENT_LOBBYING,
        type: 'Policy',
      },
      {
        name: 'Lobbying',
        value: 'lobbying_1',
        effect: [
          'You accepted donations from major lobbies and to keep donations incoming, you implemented more lenient policies for these lobbies, add 5 government building in any cities.',
        ],
        modifier: 50,
        card_value: 'government_lobbying_1',
        requirements: [],
        cost: [
          'These large lobbies did not have environmental interests in mind, increase the modifier by 50',
        ],
        action: ActionType.GOVERNMENT_LOBBYING,
        type: 'Policy',
      },
      {
        name: 'Climate Resilience Blueprint',
        value: 'government_research',
        effect: [
          'Make all structures in a selected city immune to disaster effects for next three turns.',
          'Reduces the modifier bar by 10',
        ],
        modifier: -10,
        card_value: 'government_government_research',
        requirements: [],
        cost: [],
        action: ActionType.GOVERNMENT_GOVERNMENT_RESEARCH,
        type: 'Policy',
      },
      {
        name: 'Climate Resilience Blueprint',
        value: 'government_research_1',
        effect: [
          'Make all structures in a selected city immune to disaster effects for next three turns.',
          'Reduces the modifier bar by 10',
        ],
        modifier: 0,
        card_value: 'government_government_research_1',
        requirements: [],
        cost: [],
        action: ActionType.GOVERNMENT_GOVERNMENT_RESEARCH,
        type: 'Policy',
      },
      {
        name: 'Climate Resilience Blueprint',
        value: 'government_research_2',
        effect: [
          'Make all structures in a selected city immune to disaster effects for next three turns.',
          'Reduces the modifier bar by 10',
        ],
        modifier: 0,
        card_value: 'government_government_research_2',
        requirements: [],
        cost: [],
        action: ActionType.GOVERNMENT_GOVERNMENT_RESEARCH,
        type: 'Policy',
      },
    ],
  },
  {
    name: 'QueenOfSpades',
    role: 'Environmental Activist',
    roleDb: 'environmentalActivist',
    structures: [
      {
        log: 'constructed a Conservation, increasing the modifier by 5',
        name: 'Conservation',
        type: ConstructionTypes.USER,
        typeBuild: ConstructionTypesBuild.RESOURCE,
        card_value: 'environmental_activist_resource',
        action: ActionType.ADD_BUILD,
      },
      {
        log: 'transformed a Conservation into a Green Conservation, decreasing the modifier by 5',
        name: 'Green Conservation',
        type: ConstructionTypes.USER,
        typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
        card_value: 'environmental_activist_green_resource',
        action: ActionType.ADD_BUILD,
      },
    ],
    icon: '/images/environmental_activist.png',
    bg: '/images/environmental_activist.jpg',
    gameIcon: User,
    description:
      'You are preserving diverse ecosystems, and creating natural carbon capture methods, and trees! But there are many protests about the land you take and poachers who threaten your carnivores. You are tasked with reforesting areas, repopulating wildlife, and saving the environment',
    attributes: [
      {
        name: 'Green resource search',
        description: `Searches a player's deck and places up to 5 green resource cards at the top. Additionally I can build a green infrastructure using only 1 green resource card or make a conservation into green from a standard one alo using 1 green resource card.`,
        card_value: 'environmental_activist_green_resource_search',
        action: ActionType.FIVE_GREEN_RESOURCE_CARDS_TO_TOP,
        action_description: `Searche a player's deck and places up to 5 green resource cards at the top. Additionally you can flip a conservation into a green conservation from a standard one using only 1 green resource card`,
      },
      {
        name: 'Protest',
        description: `They can shut down all infrastructure in one city. Infrastructure won’t produce any emissions.`,
        card_value: 'environmental_activist_protest',
        action: ActionType.DOWN_ALL_INFRASTRUCTURE,
        action_description:
          'You can shut down all infrastructure in one city for one round. Infrastructure won’t produce any emissions in that round.',
      },
      {
        name: 'Ecological Education',
        description:
          'Once per turn, you can educate other players about environmental preservation, allowing them to use one green card instead of two for their actions in the next round.',
        card_value: 'environmental_activist_ecological_education',
        action: ActionType.ONE_GREEN_CARD_FOR_TWO,
        action_description:
          'You can educate a players about environmental preservation, allowing them to use one green card instead of two to build a green structure in the next round.',
      },
    ],
    institutions: [
      {
        name: 'Animal Agriculture',
        value: 'animal_agriculture',
        effect: ['Protests are sparked, select one city to shut down all standard factories.'],
        modifier: 0,
        card_value: 'environmental_activist_animal_agriculture',
        requirements: [],
        action: ActionType.ENVIRONMENTAL_ACTIVIST_ANIMAL_AGRICULTURE,
        cost: ['The demolition process is a messy one, increase the modifier by 10'],
        type: 'Protest',
      },
      {
        name: 'Deforestation',
        value: 'deforestation',
        effect: [
          'People are chopping down forests left and right without regard to the ecological impact. Stage a protest to protect a forest.',
          'Replace two standard factories with a green conservation in any city on the board.',
          'Decrease the modifier by 10.',
        ],
        modifier: -10,
        action: ActionType.ENVIRONMENTAL_ACTIVIST_DEFORESTATION,
        card_value: 'environmental_activist_deforestation',
        requirements: [],
        cost: [],
        type: 'Protest',
      },
      {
        name: 'Clean Energy Now',
        value: 'fossil_fuels_fracking',
        effect: ['Convert up to three factories in one city into green conservations.'],
        modifier: 0,
        action: ActionType.ENVIRONMENTAL_ACTIVIST_FOSSIL_FUELS_FRACKING,
        card_value: 'environmental_activist_fossil_fuels_fracking',
        requirements: [],
        cost: [],
        type: 'Protest',
      },
      {
        name: 'Infrastructure',
        value: 'infrastructure',
        effect: [
          'You search and play the government or science card for Policy: Road Infrastructure or Invention: Cool Pavements',
          'Decrease the modifier by 10',
        ],
        modifier: -10,
        action: ActionType.ENVIRONMENTAL_ACTIVIST_INFRASTRUCTURE,
        card_value: 'environmental_activist_infrastructure',
        requirements: [],
        cost: ['You lose one conservation in three random cities'],
        type: 'Protest',
      },
      {
        name: 'Green Energy',
        value: 'green_energy',
        effect: [
          'Searches Consumer deck for Demand: Green Energy, Science deck for Invention: Nuclear Energy, Business deck for Product: Green Energy and Government deck for Policy: Nuclear energy and put them in the hands of the payers',
        ],
        modifier: 0,
        card_value: 'environmental_activist_green_energy',
        requirements: [],
        cost: [],
        action: ActionType.ENVIRONMENTAL_ACTIVIST_GREEN_ENERGY,
        type: 'Protest',
      },
      {
        name: 'Wildlife Restoration Fundraiser',
        value: 'donations_for_conservation',
        effect: [
          'Build two new green conservations in any city without using existing standard or green resource cards',
        ],
        modifier: 20,
        action: ActionType.ENVIRONMENTAL_ACTIVIST_DONATIONS_FOR_CONSERVATION,
        card_value: 'environmental_activist_donations_for_conservation',
        requirements: [],
        cost: [],
        type: 'Protest',
      },
      {
        name: 'Wildlife Restoration Fundraiser',
        value: 'donations_for_conservation_1',
        effect: [
          'Build two new green conservations in any city without using existing standard or green resource cards',
        ],
        modifier: 20,
        action: ActionType.ENVIRONMENTAL_ACTIVIST_DONATIONS_FOR_CONSERVATION,
        card_value: 'environmental_activist_donations_for_conservation_1',
        requirements: [],
        cost: [],
        type: 'Protest',
      },
      {
        name: 'Global Climate March',
        value: 'mass_rally',
        effect: [
          'For the next round, all players can use one less resource card when building green structures.',
        ],
        modifier: 0,
        action: ActionType.ENVIRONMENTAL_ACTIVIST_MASS_RALLY,
        card_value: 'environmental_activist_mass_rally',
        requirements: [],
        cost: ['Remove one conservation from two cities.'],
        type: 'Protest',
      },
      {
        name: 'Global Climate March',
        value: 'mass_rally_1',
        effect: [
          'For the next round, all players can use one less resource card when building green structures.',
        ],
        modifier: 0,
        action: ActionType.ENVIRONMENTAL_ACTIVIST_MASS_RALLY,
        card_value: 'environmental_activist_mass_rally_1',
        requirements: [],
        cost: ['Remove one conservation from two cities.'],
        type: 'Protest',
      },
      {
        name: 'Global Climate March',
        value: 'mass_rally_2',
        effect: [
          'For the next round, all players can use one less resource card when building green structures.',
        ],
        modifier: 0,
        action: ActionType.ENVIRONMENTAL_ACTIVIST_MASS_RALLY,
        card_value: 'environmental_activist_mass_rally_2',
        requirements: [],
        cost: ['Remove one conservation from two cities.'],
        type: 'Protest',
      },
    ],
  },
  {
    name: 'JokerJuggler',
    role: 'Consumer',
    roleDb: 'consumer',
    structures: [
      {
        log: 'constructed a Mall, increasing the modifier by 5',
        name: 'Malls',
        type: ConstructionTypes.STORE,
        typeBuild: ConstructionTypesBuild.RESOURCE,
        card_value: 'consumer_resource',
        action: ActionType.ADD_BUILD,
      },
      {
        log: 'transformed a Mall into a Green Mall, decreasing the modifier by 5',
        name: 'Green Malls',
        type: ConstructionTypes.STORE,
        typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
        card_value: 'consumer_green_resource',
        action: ActionType.ADD_BUILD,
      },
    ],
    icon: '/images/consumer.png',
    bg: '/images/consumer.jpg',
    gameIcon: Store,
    description:
      'You want goods easily accessible to consumers, building malls and distribution centers around the continent. But you know climate change will disrupt supply chains and the wallets of consumers. You are tasked with building fulfillment distribution centers and shopping malls as a method of accumulating funds to institute more sustainable structures.',
    attributes: [
      {
        name: 'More goods',
        description:
          'With an increased demand for goods, search any player’s deck and place up to 5 structure resource cards at the top.',
        card_value: 'consumer_more_goods',
        action: ActionType.PLACE_UP_TO_FIVE_STRUCTURE_RESOURCE_CARDS,
        action_description:
          'With an increased demand for goods, search any player’s deck and place up to 5 structure resource cards at the top.',
      },
      {
        name: 'Popular demand',
        description:
          'By popular demand, consumers donate large amounts to disaster relief, and restore infrastructure in 3 cities. New: By popular demand, consumers donate large amounts to disaster relief, protect the infrastructures in three cities from the next natural disaster.',
        card_value: 'consumer_popular_demand',
        action: ActionType.RESTORE_INFRASTRUCTURE_IN_THREE_CITIES,
        action_description:
          'By popular demand, consumers donate large amounts to disaster protection, protecting the infrastructures in one cities from the next natural disaster. The effect applies only on a city at a time.',
      },
      {
        name: 'Green Consumerism',
        description:
          'Once per turn, you can influence the market to favor eco-friendly products, converting one of your non-green infrastructure in any city into a green one.',
        card_value: 'consumer_green_consumerism',
        action: ActionType.MAKE_ONE_YOUR_BUILD_TO_GREEN,
        action_description:
          'You can influence the market to favor eco-friendly products, converting one of your non-green infrastructure in any city into a green one.',
      },
    ],
    institutions: [
      {
        name: 'Closed Clothing Production',
        value: 'closed_clothing_production',
        effect: [
          'Search the business deck for Product: Closed Clothing Production and put it in their hand',
        ],
        modifier: 0,
        action: ActionType.CONSUMER_CLOSED_CLOTHING_PRODUCTION,
        card_value: 'consumer_closed_clothing_production',
        requirements: ['Invention: Machine Sorted Recycle'],
        cost: [
          'The increased demand coupled with the extra cost of sustainable production, closes one mall',
        ],
        type: 'Demand',
      },
      {
        name: 'Meat Alternatives',
        value: 'meat_alternatives',
        effect: [
          'More and more consumers are turning vegan or vegetarian and want alternatives to their favorite meat products. This card is a prerequisite for other institution cards, collaborate with your teammates and benefit from it.',
        ],
        modifier: 0,
        action: ActionType.CONSUMER_MEAT_ALTERNATIVES,
        card_value: 'consumer_meat_alternatives',
        requirements: [],
        cost: [],
        type: 'Demand',
      },
      {
        name: 'Green Energy',
        value: 'green_energy',
        effect: [
          'Search the business deck for Product: Green Energy and place it at the top of the deck.',
        ],
        action: ActionType.CONSUMER_GREEN_ENERGY,
        modifier: 0,
        card_value: 'consumer_green_energy',
        requirements: [],
        cost: [],
        type: 'Demand',
      },
      {
        name: 'Sustainable Plastic',
        value: 'sustainable_plastic',
        effect: [
          `Search corporate's deck for Product: Green Plastic Products and put it in their hand.`,
          'If Product: Green Plastic Products was played then replace a standard mall with a green mall in one city',
          'Reduce the modifier by 10.',
        ],
        modifier: -10,
        action: ActionType.CONSUMER_SUSTAINABLE_PLASTIC,
        card_value: 'consumer_sustainable_plastic',
        requirements: [],
        cost: [
          'If Product: Green Plastic Products was played cost is negligible',
          'Otherwise this leads many to spend less time in malls, remove one mall from one city',
        ],
        type: 'Demand',
      },
      {
        name: 'Carbon Capture',
        value: 'carbon_capture',
        effect: ['Reduce the modifier by 10'],
        modifier: -10,
        action: ActionType.CONSUMER_CARBON_CAPTURE,
        card_value: 'consumer_carbon_capture',
        requirements: [],
        cost: [],
        type: 'Demand',
      },
      {
        name: 'Cheaper goods',
        value: 'cheaper_goods',
        effect: ['You can build 5 malls'],
        modifier: 50,
        action: ActionType.CONSUMER_CHEAPER_GOODS,
        card_value: 'consumer_cheaper_goods',
        requirements: [],
        cost: ['Increase the modifier by 50'],
        type: 'Demand',
      },
      {
        name: 'Cheaper goods',
        value: 'cheaper_goods_1',
        effect: ['You can build 5 malls'],
        modifier: 50,
        action: ActionType.CONSUMER_CHEAPER_GOODS,
        card_value: 'consumer_cheaper_goods_1',
        requirements: [],
        cost: ['Increase the modifier by 50'],
        type: 'Demand',
      },
      {
        name: 'Local Green Malls',
        value: 'small_businesses',
        effect: ['Replace one mall with two Green Malls in any city'],
        modifier: 0,
        action: ActionType.CONSUMER_SMALL_BUSINESSES,
        card_value: 'consumer_small_businesses',
        requirements: [],
        cost: [],
        type: 'Demand',
      },
      {
        name: 'Local Green Malls',
        value: 'small_businesses_1',
        effect: ['Replace one mall with two Green Malls in any city'],
        modifier: 0,
        action: ActionType.CONSUMER_SMALL_BUSINESSES,
        card_value: 'consumer_small_businesses_1',
        requirements: [],
        cost: [],
        type: 'Demand',
      },
      {
        name: 'Local Green Malls',
        value: 'small_businesses_2',
        effect: ['Replace one mall with two Green Malls in any city'],
        modifier: 0,
        action: ActionType.CONSUMER_SMALL_BUSINESSES,
        card_value: 'consumer_small_businesses_2',
        requirements: [],
        cost: [],
        type: 'Demand',
      },
    ],
  },
  {
    name: 'CardShark',
    role: 'Business',
    roleDb: 'business',
    structures: [
      {
        log: 'constructed a Factory, increasing the modifier by 5',
        name: 'Factories',
        type: ConstructionTypes.INDUSTRY,
        typeBuild: ConstructionTypesBuild.RESOURCE,
        card_value: 'business_resource',
        action: ActionType.ADD_BUILD,
      },
      {
        log: 'transformed a Factory into a Green Factory, decreasing the modifier by 5',
        name: 'Green Factories',
        type: ConstructionTypes.INDUSTRY,
        typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
        card_value: 'business_green_resource',
        action: ActionType.ADD_BUILD,
      },
    ],
    icon: '/images/business.png',
    bg: '/images/business.jpg',
    gameIcon: Industry,
    description:
      'You want to maximize your bottom line, disrupting markets and building more cost-effective supply chains, your growth allows you to build more factories. But climate change poses a threat to continued growth as a company, disrupting supply chains and causing recessions.',
    attributes: [
      {
        name: 'Hostile takeover',
        description:
          'Convert all existing infrastructure in one city into non green factories with the exception of government buildings. You need to have a factory in that city in order to use this action.',
        card_value: 'business_hostile_takeover',
        action: ActionType.MAKE_ALL_NON_GREEN_WITHOUT_GOVERNMENT,
        action_description:
          'Convert all existing structures in one city into non green factories with the exception of government buildings. You need to have a factory in that city in order to use this action.',
      },
      {
        name: 'Investment in Sustainability',
        description:
          'Once per turn, you can invest in green technology, reducing emissions produced by your factories by half for the next round.',
        card_value: 'business_investmen_in_iustainability',
        action: ActionType.REDUCING_EMISSIONS_PRODUCED,
        action_description:
          'You can invest in green technology, reducing emissions produced by your factories by half for the next round.',
      },
    ],
    institutions: [
      {
        name: 'Alternative meat products',
        value: 'alternative_meat_products',
        effect: ['Decrease the modifier based on cards that were played:'],
        modifier: -50,
        action: ActionType.BUSINESS_ALTERNATIVE_MEAT_PRODUCTS,
        card_value: 'business_alternative_meat_products',
        requirements: [
          'You need to have 2 green factories in a city or Protest: Animal Agriculture was played',
          'Invention: Lab Grown Meat or Demand: Meat Alternatives or Policy: Meat Alternative Subsidies were played',
        ],
        subDescription: [
          'Invention: Lab Grown Meat - 10',
          'Demand: Meat Alternatives - 10',
          'Policy: Meat Alternative Subsidies - 15',
          'Protest: Animal Agriculture - 10',
        ],
        cost: [
          'If Policy: Meat Alternative Subsidies implemented the cost is negligible.',
          'Otherwise all the factories in a selected city are removed, you should have at least one factory in that city.',
        ],
        type: 'Product',
      },
      {
        name: 'Closed Clothing Production',
        value: 'closed_clothing_production',
        effect: [
          'Reducing clothes waste and reducing the production of new clothing aids in reducing your modifier by 20.',
        ],
        modifier: -20,
        action: ActionType.BUSINESS_CLOSED_CLOTHING_PRODUCTION,
        card_value: 'business_closed_clothing_production',
        requirements: ['Demand: Closed Clothing Production'],
        cost: [],
        type: 'Product',
      },
      {
        name: 'Green Energy',
        value: 'green_energy',
        effect: [
          'If Demand: Green Energy was played, reduce the modifier by 15 points',
          'If Invention: Nuclear Energy was played, reduce the modifier by 20 points',
          'If neither of the cards were played, reduce the modifier by 5 points',
        ],
        modifier: -20,
        action: ActionType.BUSINESS_GREEN_ENERGY,
        card_value: 'business_green_energy',
        requirements: [],
        cost: [],
        type: 'Product',
      },
      {
        name: 'Green Plastic Products',
        value: 'green_plastic_products',
        effect: [
          'You start mass producing more sustainable plastic products. Decrease the modifier by 10',
        ],
        modifier: -25,
        action: ActionType.BUSINESS_GREEN_PLASTIC_PRODUCTS,
        card_value: 'business_green_plastic_products',
        requirements: [
          'Invention: Plant-based Plastic and Demand: Sustainable Plastic',
          'You have to have 2 green factories on the map',
        ],
        cost: [],
        type: 'Product',
      },
      {
        name: 'Recycling Program',
        value: 'recycling_program',
        effect: [
          'You take over the government run recycling program, and create a more efficient system reducing the modifier by 25.',
          'The government is able to devote its resources to other matters, add two government standard structures to the board.',
        ],
        modifier: -25,
        action: ActionType.BUSINESS_RECYCLING_PROGRAM,
        card_value: 'business_recycling_program',
        requirements: [
          'Policy: Recycling Program',
          'You have to have 2 green factories on the map',
        ],
        cost: [],
        type: 'Product',
      },
      {
        name: 'Increase margins',
        value: 'increase_margins',
        effect: [
          'You find ways to cut back on your Operating Expenses, allowing the money to be directed to CapEx, add 5 factories to the board.',
        ],
        modifier: 50,
        action: ActionType.BUSINESS_INCREASE_MARGINS,
        card_value: 'business_increase_margins',
        requirements: [],
        cost: [
          'This was at the cost of making more sustainable goods, increase the modifier by 50',
        ],
        type: 'Product',
      },
      {
        name: 'Increase margins',
        value: 'increase_margins_1',
        effect: [
          'You find ways to cut back on your Operating Expenses, allowing the money to be directed to CapEx, add 5 factories to the board.',
        ],
        modifier: 50,
        action: ActionType.BUSINESS_INCREASE_MARGINS,
        card_value: 'business_increase_margins_1',
        requirements: [],
        cost: [
          'This was at the cost of making more sustainable goods, increase the modifier by 50',
        ],
        type: 'Product',
      },
      {
        name: 'Sustainable Tech Accelerator',
        value: 'r_d_x',
        effect: ['Play immediately to double the effect of any green structure built this turn'],
        modifier: 0,
        action: ActionType.BUSINESS_R_D_X,
        card_value: 'business_r_d_x',
        requirements: [],
        cost: ['Remove one factory in a selected city.'],
        type: 'Product',
      },
      {
        name: 'Sustainable Tech Accelerator',
        value: 'r_d_x_1',
        effect: ['Play immediately to double the effect of any green structure built this turn'],
        modifier: 0,
        action: ActionType.BUSINESS_R_D_X,
        card_value: 'business_r_d_x_1',
        requirements: [],
        cost: ['Remove one factory in a selected city.'],
        type: 'Product',
      },
      {
        name: 'Sustainable Tech Accelerator',
        value: 'r_d_x_2',
        effect: ['Play immediately to double the effect of any green structure built this turn'],
        modifier: 0,
        action: ActionType.BUSINESS_R_D_X,
        card_value: 'business_r_d_x_2',
        requirements: [],
        cost: ['Remove one factory in a selected city.'],
        type: 'Product',
      },
    ],
  },
];

export const MODIFIER_GREEN_RESOURCES = -5;
export const MODIFIER_RESOURCES = 5;
