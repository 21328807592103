import { CountryI } from '../services/game.serivice';

export const COUNTRIES: CountryI[] = [
  {
    id: 1,
    name: 'Hydronia',
    cities: [
      {
        name: 'Aquaflow Dam',
        id: 9,
      },
      {
        name: 'Tidalbridge Plains',
        id: 10,
      },
      {
        name: 'Riverbend Run',
        id: 11,
      },
      {
        name: 'Wavecrest Bay',
        id: 12,
      },
    ],
  },
  {
    id: 2,
    name: 'Solargaea',
    cities: [
      {
        name: 'Radiant Valley',
        id: 13,
      },
      {
        name: 'Suncatcher Plains',
        id: 15,
      },
      {
        name: 'Helioport',
        id: 14,
      },
      {
        name: 'Sunspire City',
        id: 16,
      },
    ],
  },
  {
    id: 3,
    name: 'Aerolithica',
    cities: [
      {
        name: 'Windward Heights',
        id: 8,
      },
      {
        name: 'Galeforce City',
        id: 7,
      },
      {
        name: 'Breezeway Harbor',
        id: 5,
      },
      {
        name: 'Turbine Terrace',
        id: 6,
      },
    ],
  },
  {
    id: 4,
    name: 'Terraviva',
    cities: [
      {
        name: 'Geothermopolis',
        id: 1,
      },
      {
        name: 'Greenharvest Grove',
        id: 3,
      },
      {
        name: 'Soilheart Plains',
        id: 4,
      },
      {
        name: 'Earthbond Enclave',
        id: 2,
      },
    ],
  },
];
