export enum ActionDanger {
  POLITICAL_TREMORS = 'political_tremors',
  SCIENCE_SKEPTICS = 'science_skeptics',
  NATURES_DEFIANCE = 'natures_defiance',
  ECONOMIC_EBB = 'economic_ebb',
  INDUSTRY_STANDSTILL = 'industry_standstill',
  EARTHQUAKE = 'earthquake',
  ERUPTION = 'eruption',
  EMISSION_OF_RADIATION = 'emission_of_radiation',
  MELTING_GLACIERS = 'melting_glaciers',
  DROUGHT = 'drought',
  WILDFIRES = 'wildfires',
  FLOOD = 'flood',
  AIR_POLLUTION = 'air_pollution',
}
