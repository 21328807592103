const Info = ({ fill = '#999' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
      <g fill={fill} clipPath="url(#clip0_1327_20117)">
        <path d="M6.375 8.25v-3h-1.5V6h.75v2.25H4.5V9h3v-.75H6.375zM6 3a.563.563 0 100 1.125A.563.563 0 006 3z"></path>
        <path d="M6 11.25A5.25 5.25 0 116 .75a5.25 5.25 0 010 10.5zM6 1.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1327_20117">
          <path fill="#fff" d="M0 0H12V12H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Info;
