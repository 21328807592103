const Store = ({ fill = '#ffffff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M22.5 8.01L21 3.51a.75.75 0 00-.75-.51H3.75a.75.75 0 00-.75.51l-1.5 4.5c-.01.08-.01.16 0 .24v4.5a.75.75 0 00.75.75H3V21h1.5v-7.5H9V21h12v-7.5h.75a.75.75 0 00.75-.75v-4.5c.01-.08.01-.16 0-.24zm-3 11.49h-9v-6h9v6zM21 12h-3V9h-1.5v3h-3.75V9h-1.5v3H7.5V9H6v3H3V8.37L4.29 4.5h15.42L21 8.37V12z"
      ></path>
    </svg>
  );
};

export default Store;
