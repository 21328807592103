const Next = ({ fill = '#ffffff', size = 33 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        d="M22.5001 16L12.5001 26L11.1001 24.6L19.7001 16L11.1001 7.4L12.5001 6L22.5001 16Z"
        fill={fill}
      />
    </svg>
  );
};

export default Next;
