import React, { useState } from 'react';
import Modal from 'react-modal';
import Close from '../../assets/Close';
import { RoleInterface } from '../Roles';
import Next from '../../assets/Next';
import classNames from 'classnames';

interface SelectPlayerProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (player: RoleInterface) => void;
  players: RoleInterface[];
}

const SelectPlayer = ({ isOpen, onClose, onSubmit, players }: SelectPlayerProps) => {
  const [selectedPlayer, setSelectedPlayer] = useState<RoleInterface | undefined>();

  const onChange = (e: React.ChangeEvent<HTMLSelectElement> | undefined) => {
    const player = players.find((r) => r.id === e?.target.value);
    setSelectedPlayer(player);
  };

  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onClose}
      className="absolute rounded-2xl max-w-sm min-w-[384px]"
      overlayClassName="modal_overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col rounded-2xl bg-hint_of_green border-mantis border-solid border-4 shadow-product-modal">
        <div className="px-4 py-3 flex flex-col bg-forest-green rounded-t-xl">
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-white text-2xl font-medium">Select a player</p>
            <div className="flex flex-row items-center">
              <button
                onClick={onClose}
                className="bg-transparent outline-none border-0 flex flex-row justify-center items-center h-8 w-8 p-0 cursor-pointer"
              >
                <Close fill={'rgba(255, 255, 255, 0.80)'} />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2 justify-center p-4">
          <select
            onChange={onChange}
            defaultValue={'Select a player'}
            className="flex flex-col w-full gap-x-2 mt-4 bg-hint_of_green border border-solid border-forest-green rounded px-4 pb-3 pt-2 outline-none"
          >
            <option value="Select a player">Select a player</option>
            {players.map((p) => {
              return (
                <option key={p.id} value={p.id} selected={selectedPlayer?.id === p.id}>
                  {p.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex flex-row gap-2 justify-center p-4">
          <button
            onClick={onClose}
            className="outline-none flex flex-row justify-center items-center px-4 py-3 w-32 rounded-sm border-0 bg-venetian_red cursor-pointer"
          >
            <p className="m-0 text-base font-medium text-white mr-2 py-1">Cancel</p>
            <Close fill={'#ffffff'} />
          </button>
          <button
            className={classNames(
              'flex flex-row justify-center items-center rounded-sm outline-none border-0 px-4 py-3 w-32 cursor-pointer',
              {
                'bg-forest-green': !!selectedPlayer,
                'bg-forest-green-80': !selectedPlayer,
              },
            )}
            disabled={!selectedPlayer}
            onClick={() => onSubmit(selectedPlayer!)}
          >
            <p className="m-0 text-base font-medium text-white mr-2 py-1">Confirm</p>
            <Next />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectPlayer;
