const Tutorial = ({ color = '#ffffff', size = 28 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill={color}
        fillOpacity="0.8"
        d="M27.073 13.703A14.603 14.603 0 0014 4.375 14.604 14.604 0 00.928 13.703a.875.875 0 000 .595A14.603 14.603 0 0014 23.624a14.604 14.604 0 0013.073-9.328.875.875 0 000-.595zM14 21.875c-4.637 0-9.537-3.439-11.314-7.875C4.463 9.564 9.363 6.125 14 6.125c4.638 0 9.538 3.439 11.314 7.875-1.776 4.436-6.676 7.875-11.314 7.875z"
      ></path>
      <path
        fill={color}
        fillOpacity="0.8"
        d="M14 8.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm0 8.75a3.5 3.5 0 110-7 3.5 3.5 0 010 7z"
      ></path>
    </svg>
  );
};

export default Tutorial;
