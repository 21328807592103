import { Socket, io } from 'socket.io-client';

export class SocketService {
  static socket: { [key: string]: Socket } = {};
  static socketGame: { [key: string]: Socket } = {};
  static socketChat: { [key: string]: Socket } = {};

  static connectLobby(lobbyId: string) {
    let token = localStorage.getItem('access_token');
    this.socket[lobbyId] = io(process.env.REACT_APP_API_URL!, {
      reconnection: true,
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
      query: {
        lobbyId,
        token,
      },
    });
    this.socket[lobbyId].on('connect', () => {
      console.log(`connected lobby socket`);
    });
    this.socket[lobbyId].on('disconnect', () => {
      console.log('disconnect lobby socket');
    });
    return this.socket[lobbyId];
  }

  static disconnectLobby(lobbyId?: string) {
    if (lobbyId && this.socket && this.socket[lobbyId]) {
      this.socket[lobbyId].disconnect();
    }
  }

  static connectGame(gameId: string) {
    let token = localStorage.getItem('access_token');
    this.socketGame[gameId] = io(process.env.REACT_APP_API_URL!, {
      reconnection: true,
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
      query: {
        gameId,
        token,
      },
    });
    this.socketGame[gameId].on('connect', () => {
      console.log(`connected game socket`);
    });
    this.socketGame[gameId].on('disconnect', () => {
      console.log('disconnect game socket');
    });
    return this.socketGame[gameId];
  }

  static disconnectGame(gameId?: string) {
    if (gameId && this.socketGame && this.socketGame[gameId]) {
      this.socketGame[gameId].disconnect();
    }
  }

  static connectChat(chatId: string) {
    let token = localStorage.getItem('access_token');
    this.socketChat[chatId] = io(process.env.REACT_APP_API_URL!, {
      reconnection: true,
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
      query: {
        chatId,
        token,
      },
    });
    this.socketChat[chatId].on('connect', () => {
      console.log(`connected chat socket`);
    });
    this.socketChat[chatId].on('disconnect', () => {
      console.log('disconnect chat socket');
    });
    return this.socketChat[chatId];
  }

  static disconnectChat(chatId?: string) {
    if (chatId && this.socketChat && this.socketChat[chatId]) {
      this.socketChat[chatId].disconnect();
    }
  }

  static getInstanceLobby(lobbyId: string) {
    if (!(this.socket && this.socket[lobbyId])) {
      return this.connectLobby(lobbyId);
    }
    return this.socket[lobbyId];
  }

  static getInstanceGame(gameId: string) {
    if (!(this.socketGame && this.socketGame[gameId])) {
      return this.connectGame(gameId);
    }
    return this.socketGame[gameId];
  }

  static getInstanceChat(chatId: string) {
    if (!(this.socketChat && this.socketChat[chatId])) {
      return this.connectChat(chatId);
    }
    return this.socketChat[chatId];
  }
}
