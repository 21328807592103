import React from 'react';
import Modal from 'react-modal';
import Close from '../../assets/Close';
import { InstitutionInterface } from '../Roles';

interface ProductDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  institutionCard: InstitutionInterface;
}

const ProductDetails = ({ isOpen, onClose, institutionCard }: ProductDetailsProps) => {
  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onClose}
      className="absolute rounded-2xl max-w-sm"
      overlayClassName="modal_overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col rounded-2xl bg-hint_of_green border-mantis border-solid border-4 shadow-product-modal">
        <div className="px-4 py-3 flex flex-col bg-forest-green rounded-t-xl">
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-white text-2xl font-medium">{institutionCard.type}</p>
            <div className="flex flex-row items-center">
              <div className="border border-solid border-white-50 rounded-sm px-1 h-full mx-2">
                <p className="m-0 text-white-80 text-[10px] leading-4 font-medium">
                  Institution Card
                </p>
              </div>
              <button
                onClick={onClose}
                className="bg-transparent outline-none border-0 flex flex-row justify-center items-center h-8 w-8 p-0 cursor-pointer"
              >
                <Close fill={'rgba(255, 255, 255, 0.80)'} />
              </button>
            </div>
          </div>
          <div>
            <p className="m-0 text-lg text-white-60 font-normal">{institutionCard.name}</p>
          </div>
        </div>
        <div className="flex flex-col p-4">
          {institutionCard?.effect?.length ? (
            <div className="flex flex-col mb-4">
              <div>
                <p className="m-0 text-sm leading-4 text-black font-normal mb-1">Effect</p>
                <ul className="m-0">
                  {institutionCard.effect.map((description) => {
                    const ifSubDescription = description[description.length - 1] === ':';
                    return (
                      <li key={description} className="text-xs font-normal text-black-80">
                        {description}
                        {ifSubDescription && institutionCard?.subDescription?.length ? (
                          <ul className="m-0">
                            {institutionCard.subDescription.map((subDescription) => {
                              return (
                                <li
                                  key={subDescription}
                                  className="text-xs font-normal text-black-80"
                                >
                                  {subDescription}
                                </li>
                              );
                            })}
                          </ul>
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : null}
          {institutionCard?.requirements?.length ? (
            <div className="flex flex-col mb-4">
              <div>
                <p className="m-0 text-sm leading-4 text-black font-normal mb-1">Requirements</p>
                <ul className="m-0">
                  {institutionCard.requirements.map((requirement) => {
                    return (
                      <li key={requirement} className="text-xs font-normal text-black-80">
                        {requirement}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : null}
          {institutionCard?.cost?.length ? (
            <div className="flex flex-col mb-4">
              <div>
                <p className="m-0 text-sm leading-4 text-black font-normal mb-1">Cost</p>
                <ul className="m-0">
                  {institutionCard.cost.map((cost) => {
                    return (
                      <li key={cost} className="text-xs font-normal text-black-80">
                        {cost}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default ProductDetails;
