import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PAGES } from '../../constants/pages';
import { LobbyService } from '../../services/lobby.serivice';
import { AuthContext } from '../../context/context';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Main: FC = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const goTo = (path: string) => {
    navigate(path);
  };

  const createLobby = async () => {
    try {
      const lobby = await LobbyService.create(user?.username);
      navigate(`/lobby/${lobby.data.id}`);
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  };

  const renderItem = (page: { name: string; path?: string; icon: any }) => {
    return (
      <div
        className="my-3 flex flex-row justify-between py-5 px-6 rounded cursor-pointer w-96 bg-gape-palliser-50 bg-blend-soft-light items-center hover:bg-gape-palliser-30"
        onClick={() => (page.path ? goTo(page.path) : createLobby())}
        key={page.name}
      >
        <div className="flex flex-row">
          <page.icon />
          <p className="m-0 text-white text-xl font-medium ml-2">{page.name}</p>
        </div>
        <img src="/images/arrow-right.png" alt="arrow right" width={32} />
      </div>
    );
  };

  return (
    <div className="w-full h-screen">
      <div
        className="absolute bg-no-repeat z-0 bg-cover h-full w-full bg-left-bottom"
        style={{ backgroundImage: `url('/images/bg.jpg')` }}
      />
      <div className="flex flex-col justify-between h-full relative z-10 bg-saddle-brown-95">
        <Header />
        <div className="flex flex-row justify-center w-full">
          <div className="flex flex-col">
            <div className="flex flex-row justify-center">
              <p className="m-0 text-tangerine uppercase text-5xl leading-tight font-medium">
                Rising Tides
              </p>
            </div>
            <div className="flex flex-col mt-14 bg-black-10 p-12 rounded-2xl">
              {PAGES?.map((p) => renderItem(p))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Main;
