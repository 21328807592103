import React, { ReactElement, useContext, useEffect } from 'react';

import { AuthContext } from '../../context/context';

interface GuardProps {
  children: ReactElement<any, any>;
}

const GuardedRoute: React.FC<GuardProps> = ({ children }) => {
  const { user, logout } = useContext(AuthContext);

  useEffect(() => {
    const exist = localStorage.getItem('access_token');
    if (!exist) {
      logout();
    }
  }, [logout, user]);

  return <>{children}</>;
};

export default GuardedRoute;
