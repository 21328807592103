import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppRouter from './router/Routes';
import GlobalProvider from './context/provider';
import { useOpenReplay } from './hooks/use-openreplay';

function App() {
  useOpenReplay();

  return (
    <GlobalProvider>
      <AppRouter />
      <ToastContainer />
    </GlobalProvider>
  );
}

export default App;
