const Industry = ({ fill = '#ffffff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M22.148 4.612a.75.75 0 00-.75 0L15 7.785V5.25a.75.75 0 00-1.088-.668L7.5 7.785V2.25a.75.75 0 00-.75-.75h-4.5a.75.75 0 00-.75.75V21h21V5.25a.75.75 0 00-.352-.638zM16.5 19.5h-3v-5.25h3v5.25zm4.5 0h-3v-6a.75.75 0 00-.75-.75h-4.5a.75.75 0 00-.75.75v6H3V3h3v7.215l7.5-3.75v3.75l7.5-3.75V19.5z"
      ></path>
    </svg>
  );
};

export default Industry;
