import { FC } from 'react';
import Menu from '../Menu';

export interface ResourcesProps {
  count: number;
}

const Resources: FC<ResourcesProps> = ({ count }) => {
  return (
    <div className="flex absolute flex-col top-0 right-4 mt-4">
      <div className="absolute right-16 w-24 h-32">
        <div className="bg-yellow-sea w-24 h-32 rounded-md absolute top-2 right-0"></div>
        <div className="bg-sandy-beach w-24 h-32 rounded-md absolute top-0 right-2 flex flex-row justify-center items-center">
          <img
            src="/game/resources.png"
            alt="resources"
            className="absolute mix-blend-overlay max-w-full max-h-full"
          />
          <img
            src="/game/resources_1.png"
            alt="resources"
            className="absolute mix-blend-overlay max-w-full max-h-full"
          />
          <p className="m-0 text-black font-medium text-base">Resources</p>
        </div>
        <div className="flex flex-row justify-center items-center absolute -bottom-5 -left-6 border border-solid border-dove-gray rounded-2xl w-12 h-12 bg-gradient-to-bl from-golden-tainoi to-gape-palliser">
          <p className="m-0 text-black font-medium text-3xl">{count}</p>
        </div>
      </div>
      <Menu />
    </div>
  );
};

export default Resources;
