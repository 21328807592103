import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { memorizedRefreshToken } from '../services/refreshToken';

axios.interceptors.request.use(
  async (config) => {
    let accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    if (accessToken && refreshToken) {
      const decodedJwt: any = jwtDecode(accessToken);
      if (decodedJwt.exp * 1000 < Date.now()) {
        const result = await memorizedRefreshToken();
        accessToken = result?.accessToken;
      }
    }

    if (accessToken) {
      axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
});
