import React, { useState } from 'react';
import Chat from '../Chat';
import Logs from '../Logs';
import Industry from '../../assets/Industry';
import Info from '../../assets/Info';
import { InstitutionInterface, StructureInterface } from '../Roles';
import classNames from 'classnames';
import { ConstructionTypesBuild } from '../../enums/construction-type-build';
import ProductDetails from '../ProductDetails';
import ChevronUp from '../../assets/ChevronUp';

interface BottomGameBlockProps {
  constructionCount: number;
  greenConstructionCount: number;
  greenResources?: number;
  resources?: number;
  institutions?: number;
  currentCards?: StructureInterface[];
  institutuinCards?: InstitutionInterface[];
  role: string;
  chatId: string;
  gameId: string;
}

interface StructureColor {
  color: string;
  bg: string;
  icon: string;
}

export const COLOR_ROLE: {
  [key: string]: {
    resource: StructureColor;
    green_resource: StructureColor;
  };
} = {
  business: {
    resource: { color: '#023A56', bg: 'business.jpg', icon: 'business.png' },
    green_resource: { color: '#E09B00', bg: 'business_green.jpg', icon: 'business_green.png' },
  },
  consumer: {
    resource: { color: '#006698', bg: 'consumer.jpg', icon: 'consumer.png' },
    green_resource: { color: '#9F3C27', bg: 'consumer_green.jpg', icon: 'consumer_green.png' },
  },
  environmentalActivist: {
    resource: {
      color: '#2E2A3E',
      bg: 'environmental_activist.jpg',
      icon: 'environmental_activist.png',
    },
    green_resource: {
      color: '#5E0F1D',
      bg: 'environmental_activist_green.jpg',
      icon: 'environmental_activist_green.png',
    },
  },
  government: {
    resource: { color: '#006698', bg: 'government.jpg', icon: 'government.png' },
    green_resource: { color: '#C3804F', bg: 'government_green.jpg', icon: 'government_green.png' },
  },
  science: {
    resource: { color: '#000000', bg: 'science.jpg', icon: 'science.png' },
    green_resource: { color: '#587E01', bg: 'science_green.jpg', icon: 'science_green.png' },
  },
};

const BottomGameBlock = ({
  constructionCount,
  greenConstructionCount,
  greenResources,
  resources,
  institutions,
  currentCards,
  institutuinCards,
  role,
  chatId,
  gameId,
}: BottomGameBlockProps) => {
  const [opened, setOpen] = useState(true);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState<InstitutionInterface | undefined>();

  const showDetails = (card: InstitutionInterface) => {
    setDetailsModal(true);
    setSelectedCard(card);
  };

  const closeProductDetails = () => {
    setDetailsModal(false);
    setSelectedCard(undefined);
  };

  return (
    <div className="flex flex-row gap-x-4 absolute bottom-0 left-0 right-0 w-full justify-center">
      <div className="absolute bottom-0 w-72 left-0">
        <Chat chatId={chatId} isGame={true} />
      </div>
      <div className="absolute max-w-[calc(100vw-600px)] bottom-0">
        <div className="flex flex-row justify-center items-center absolute -top-5 h-5 w-full">
          <div className="bg-onahau-97 bg-blend-soft-light rounded-t-lg px-6 h-full">
            <button
              className="flex flex-row bg-transparent outline-none border-0 cursor-pointer"
              onClick={() => setOpen(!opened)}
            >
              <div
                className={classNames('transition-all ease-linear', {
                  'rotate-180': opened,
                })}
              >
                <ChevronUp />
              </div>
            </button>
          </div>
        </div>
        <div
          className={classNames(
            'flex flex-row max-w-full bg-onahau-97 bg-blend-soft-light p-4 rounded-t-lg transition-all ease-linear',
            {
              'h-auto': opened,
              'h-0 hidden': !opened,
            },
          )}
        >
          <div className="flex flex-col pr-3 border-0 border-r border-solid border-white-50">
            <p className="m-0 text-sm font-medium text-black leading-4 mb-2">Constructions</p>
            <div className="flex flex-row justify-start items-center pt-2 border-0 border-t border-solid border-white-50">
              <div className="flex flex-row bg-game_constructions border border-solid border-white rounded p-1">
                <Industry />
              </div>
              <p className="m-0 text-sm leading-4 text-black-80 font-medium mx-2">
                {constructionCount}
              </p>
              <Info />
            </div>
            <div className="flex flex-row justify-start items-center pt-2">
              <div className="flex flex-row bg-game_green_constructions border border-solid border-white rounded p-1">
                <Industry />
              </div>
              <p className="m-0 text-sm leading-4 text-black-80 font-medium mx-2">
                {greenConstructionCount}
              </p>
              <Info />
            </div>
          </div>
          <div
            className={classNames(
              'flex flex-row px-4 flex-wrap w-full gap-2 overflow-hidden justify-center max-h-[80vh] overflow-y-auto',
            )}
          >
            {currentCards?.map((r, i) => {
              const colorRole = COLOR_ROLE[role];
              const colorStructure =
                r?.typeBuild === ConstructionTypesBuild.RESOURCE
                  ? colorRole.resource
                  : colorRole.green_resource;
              return (
                <div
                  key={`${r?.card_value}_${i}`}
                  className={classNames(
                    'flex flex-col justify-center items-center w-20 h-28 p-2 border-4 border-solid rounded-md bg-no-repeat bg-cover',
                  )}
                  style={{
                    backgroundColor: colorStructure.color,
                    backgroundImage: `url('/images/${colorStructure.bg}')`,
                    borderColor: colorStructure.color,
                  }}
                >
                  <img
                    src={`/images/${colorStructure.icon}`}
                    alt={r?.name}
                    className="object-cover max-w-full max-h-full"
                  />
                </div>
              );
            })}
            {institutuinCards?.map((r, index) => {
              return (
                <div
                  key={`${index}_${r?.card_value}`}
                  className="bg-mantis flex flex-col w-20 h-28 border-4 border-solid border-mantis rounded-md"
                >
                  <div className="flex flex-col justify-center items-center bg-aero_blue w-full h-20 rounded-md">
                    <p className="m-0 text-xs font-medium text-black">{r?.type}</p>
                    <p className="m-0 leading-3 text-[10px] text-center font-normal text-tundora mt-1">
                      {r?.name}
                    </p>
                  </div>
                  <button
                    className="mt-1 h-7 flex flex-row justify-center items-center bg-transparent text-xs font-normal text-black border-0 outline-none cursor-pointer"
                    onClick={() => showDetails(r)}
                  >
                    Details
                  </button>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col pl-3 border-0 border-l border-solid border-white-50">
            <p className="m-0 text-sm font-medium text-black leading-4 mb-2 whitespace-nowrap">
              Cards owned
            </p>
            <div className="flex flex-row justify-start items-center pt-2 border-0 border-t border-solid border-white-50">
              <div className="flex flex-row justify-start items-center">
                <p className="m-0 text-xs font-normal text-black whitespace-nowrap">Resources</p>
                <div className="ml-1 flex flex-row justify-center items-center w-5 h-5 rounded-xl border border-solid border-gape-palliser bg-derby">
                  <p className="m-0 text-xs font-medium text-black">{resources}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-start items-center pt-1">
              <div className="flex flex-row justify-start items-center">
                <p className="m-0 text-xs font-normal text-black whitespace-nowrap">
                  Green Resources
                </p>
                <div className="ml-1 flex flex-row justify-center items-center w-5 h-5 rounded-xl border border-solid border-gape-palliser bg-derby">
                  <p className="m-0 text-xs font-medium text-black">{greenResources}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-start items-center pt-1">
              <div className="flex flex-row justify-start items-center">
                <p className="m-0 text-xs font-normal text-black whitespace-nowrap">
                  Institution Cards
                </p>
                <div className="ml-1 flex flex-row justify-center items-center w-5 h-5 rounded-xl border border-solid border-gape-palliser bg-derby">
                  <p className="m-0 text-xs font-medium text-black">{institutions}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 w-72 right-0">
        <Logs gameId={gameId} />
      </div>
      {detailsModal && selectedCard ? (
        <ProductDetails
          isOpen={detailsModal}
          onClose={closeProductDetails}
          institutionCard={selectedCard}
        />
      ) : null}
    </div>
  );
};

export default BottomGameBlock;
