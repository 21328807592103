import { FC, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Api from '../../axios/api';
import Login from '../../components/Login';
import { AuthService } from '../../services/auth.serivice';
import { AuthContext } from '../../context/context';
import { LobbyService } from '../../services/lobby.serivice';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const JoinLobby: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { setUser } = useContext(AuthContext);
  const [existLobby] = useState(params?.lobbyId);

  const joinLobby = async (username: string, lobbyId?: string) => {
    try {
      const { data } = await AuthService.login(username);
      Api.defaults.headers['Authorization'] = `Bearer ${data.accessToken}`;
      localStorage.setItem('access_token', data.accessToken);
      localStorage.setItem('refresh_token', data.refreshToken);
      const user = await AuthService.getMe();
      setUser(user.data);
      const gameId = existLobby || lobbyId;
      await LobbyService.join(gameId!);
      navigate(`/lobby/${gameId}`);
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  };

  return (
    <div className="w-full h-screen">
      <div
        className="absolute bg-no-repeat z-0 bg-cover h-full w-full bg-left-bottom"
        style={{ backgroundImage: `url('/images/bg.jpg')` }}
      />
      <div className="flex flex-col justify-between h-full relative z-10 bg-saddle-brown-95">
        <Header />
        <div className="flex flex-row justify-center w-full h-full my-48">
          <Login title="Join Lobby" submit={joinLobby} isJoinPage={!existLobby} />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default JoinLobby;
