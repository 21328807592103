import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Player } from '../../services/lobby.serivice';
import { InstitutionInterface, RoleInterface, StructureInterface } from '../Roles';
import { ROLES } from '../../constants/roles';
import classNames from 'classnames';
import Bar from '../../assets/Bar';
import Carbon from '../../assets/Carbon';
import Smoke from '../../assets/Smoke';
import Next from '../../assets/Next';
import { AuthContext } from '../../context/context';
import { ConstructionTypesBuild } from '../../enums/construction-type-build';
import ChevronUp from '../../assets/ChevronUp';
import InstitutionCard from '../InstitutionCard';
import ActionCard from '../ActionCard';
import { ActionType } from '../../enums/action-type';
import SelectPlayer from '../SelectPlayer';
import ChangePin from '../ChangePin';
import { POINTS } from '../../constants/points';
import Emitter, { EventType } from '../../services/events';
import { ConstructionI, GameService } from '../../services/game.serivice';
import { ConstructionTypes } from '../../enums/construction-type';
import ChangeCity from '../ChangeCity';

export interface PlayersGameProps {
  selectedBuild?: StructureInterface;
  players?: Player[];
  meRole: RoleInterface;
  currentActor: string;
  nextActor: () => void;
  changeInstitution: (
    action: InstitutionInterface & { currentModifier: number },
    constructions?: ConstructionI[],
    power?: string,
    cardsToHand?: { card: string; role: string }[],
    nrResources?: number,
  ) => void;
  changeSuperPower: (
    action: StructureInterface,
    constructions?: ConstructionI[],
    role?: string,
    power?: string,
  ) => void;
  selectAction: (action: StructureInterface | undefined) => void;
  selectBuild: (action: StructureInterface | undefined) => void;
  institutuinCards: InstitutionInterface[];
  currentCards: StructureInterface[];
  modifier: number;
  emission: number;
  constructionCount?: number;
  resourcesCount?: number;
  greenResourcesCount?: number;
  usedPowers: string[];
  rounds: number;
  usedCards: string[];
  constructions?: ConstructionI[];
  gameId: string;
  disabledPower: boolean;
}

const PlayersGame: FC<PlayersGameProps> = ({
  players,
  currentActor,
  nextActor,
  selectAction,
  selectBuild,
  changeInstitution,
  changeSuperPower,
  institutuinCards,
  currentCards,
  emission,
  modifier,
  constructionCount,
  resourcesCount = 0,
  greenResourcesCount = 0,
  meRole,
  usedPowers,
  rounds,
  usedCards,
  constructions,
  gameId,
  disabledPower,
}) => {
  const { user } = useContext(AuthContext);
  const [localPlayers, setLocalPlayers] = useState<RoleInterface[]>([]);
  const [localBuilds, setLocalBuilds] = useState<StructureInterface[] | undefined>([]);
  const [actions, setActions] = useState<StructureInterface[] | undefined>([]);
  const [institutions, setInstitutions] = useState<InstitutionInterface[]>([]);
  const [opened, setOpen] = useState(false);
  const [openedNext, setOpenNext] = useState(true);
  const [emissionPrecent, setEmissionPrecent] = useState(0);
  const [detailsModal, setDetailsModal] = useState(false);
  const [showCityModal, setCityModal] = useState(false);
  const [disabledSuperPowerButton, setDisabledSuperPowerButton] = useState(false);
  const [disabledPlayButton, setDisabledPlayButton] = useState(false);
  const [requirementIsNotMet, setRequirementIsNotMet] = useState<number[]>([]);
  const [costIsNotMet, setCostIsNotMet] = useState<number[]>([]);
  const [effectIsNotMet, setEffectIsNotMet] = useState<number[]>([]);
  const [detailsActionModal, setDetailsActionModal] = useState(false);
  const [localCard, setLocalCard] = useState<InstitutionInterface | undefined>();
  const [localActionCard, setLocalActionCard] = useState<StructureInterface | undefined>();
  const [playerModal, setPlayerModal] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<RoleInterface | undefined>();
  const [showPinTitle, setShowPinTitle] = useState<string>('');
  const [showSelectCity, setSelectCityModal] = useState(false);
  const [changeCityDescription, setChangeCityDescription] = useState('');
  const [selectedCountry, setSelectCountry] = useState('');
  const [confirmSelectCity, setConfirmSelectCity] = useState(false);

  const removeBuild = useCallback((id: string) => {
    const exist = document.querySelector(`#${id}`);
    if (exist) {
      const query = `#${id}>span`;
      const existCount = document.querySelector(query);
      if (existCount) {
        const newValue = parseFloat(existCount.innerHTML) - 1;
        if (newValue > 1) {
          existCount.innerHTML = `${newValue}`;
        } else {
          exist.removeChild(existCount);
        }
      } else {
        exist.parentElement?.removeChild(exist);
      }
    }
  }, []);

  useEffect(() => {
    if (emission) {
      const result = (emission * 100) / 600;
      setEmissionPrecent(result);
    }
  }, [emission]);

  useEffect(() => {
    const newPlayers = players?.map((r) => {
      const existPlayer = ROLES?.find((p) => p.roleDb === r.role) as RoleInterface;

      return {
        ...existPlayer,
        id: r?.id,
        name: r?.name || r.name,
        active: !!r?.id,
      };
    });
    setLocalPlayers(newPlayers as unknown as RoleInterface[]);
    const role = newPlayers?.find((r) => r.id === user?.id);
    let structures = (role?.structures as StructureInterface[]) || [];
    structures = structures?.filter((r) => currentCards.some((c) => c?.typeBuild === r?.typeBuild));
    const usedCards =
      meRole?.role === 'science' && usedPowers.includes(`science_research_grant_${rounds}`) ? 1 : 3;
    if (resourcesCount < usedCards) {
      structures = structures?.filter((r) => r?.typeBuild !== ConstructionTypesBuild.RESOURCE);
    }

    const usedGreenCards =
      (meRole?.role === 'science' && usedPowers.includes(`science_research_grant_${rounds}`)) ||
      usedPowers.includes(`environmental_activist_mass_rally_${rounds}`) ||
      usedPowers.includes(`${role?.roleDb}_environmental_activist_ecological_education_${rounds}`)
        ? 1
        : 2;
    if (constructionCount === 0 || greenResourcesCount < usedGreenCards) {
      structures = structures?.filter(
        (r) => r?.typeBuild !== ConstructionTypesBuild.GREEN_RESOURCE,
      );
    }
    setLocalBuilds(structures);
    const attributes =
      (role?.attributes?.map((r) => ({
        ...r,
        name: r.name,
        typeBuild: ConstructionTypesBuild.ACTION,
      })) as StructureInterface[]) || [];
    setActions(attributes);
    const newInstitutions = role?.institutions?.filter((i) =>
      institutuinCards.some((r) => r?.card_value === i?.card_value),
    );
    setInstitutions(newInstitutions || []);
  }, [
    currentCards,
    institutuinCards,
    players,
    constructionCount,
    user?.id,
    resourcesCount,
    greenResourcesCount,
    meRole?.role,
    usedPowers,
    rounds,
  ]);

  const showDetails = (card: InstitutionInterface) => {
    let isRequirement: number[] = [];
    let effectIsNotMet: number[] = [];
    let costIsNotMet: number[] = [];
    let disabledPlayButton = false;
    let currentCard = card;
    switch (card?.action) {
      case ActionType.CONSUMER_CLOSED_CLOTHING_PRODUCTION:
        const usedCard = usedCards.some((r) => r === ActionType.SCIENCE_MACHINE_SORTED_RECYCLE);
        if (!usedCard) {
          isRequirement = [0];
        }
        const simpleMallsDemand = constructions?.filter(
          (c) =>
            c.type === ConstructionTypes.STORE && c.typeBuild === ConstructionTypesBuild.RESOURCE,
        );
        if (!simpleMallsDemand?.length) {
          costIsNotMet = [0];
        }
        disabledPlayButton = !usedCard || !simpleMallsDemand?.length;
        effectIsNotMet = [];
        break;
      case ActionType.CONSUMER_SUSTAINABLE_PLASTIC:
        const usedCardGreenPlastic = usedCards.some(
          (r) => r === ActionType.BUSINESS_GREEN_PLASTIC_PRODUCTS,
        );
        isRequirement = [];
        const simpleMalls = constructions?.filter(
          (c) =>
            c.type === ConstructionTypes.STORE && c.typeBuild === ConstructionTypesBuild.RESOURCE,
        );
        disabledPlayButton = !simpleMalls?.length;
        if (!usedCardGreenPlastic) {
          effectIsNotMet = [1];
        }
        break;
      case ActionType.BUSINESS_ALTERNATIVE_MEAT_PRODUCTS:
        const usedCardAnimalAgriculture = usedCards.some(
          (r) => r === ActionType.ENVIRONMENTAL_ACTIVIST_ANIMAL_AGRICULTURE,
        );
        const existTwoGreenFactories =
          constructions?.filter(
            (r) =>
              r.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
              r.type === ConstructionTypes.INDUSTRY,
          ) || [];
        const requirementIsMetFirst =
          usedCardAnimalAgriculture || existTwoGreenFactories?.length >= 2;
        if (!requirementIsMetFirst) {
          isRequirement = [0];
        }
        const requirementIsMetSecond = usedCards.some(
          (r) =>
            r === ActionType.SCIENCE_LAB_GROWN_MEAT ||
            r === ActionType.CONSUMER_MEAT_ALTERNATIVES ||
            r === ActionType.GOVERNMENT_MEAT_ALTERNATIVE_SUBSIDIES,
        );
        if (!requirementIsMetSecond) {
          isRequirement = [...isRequirement, 1];
        }
        disabledPlayButton = !requirementIsMetFirst || !requirementIsMetSecond;
        let newModifier = 0;
        const usedCardLabGrownMeat = usedCards.some((r) => r === ActionType.SCIENCE_LAB_GROWN_MEAT);
        if (usedCardLabGrownMeat) {
          newModifier = -10;
        } else {
          effectIsNotMet = [0];
        }
        const usedCardMeatAlternatives = usedCards.some(
          (r) => r === ActionType.CONSUMER_MEAT_ALTERNATIVES,
        );
        if (usedCardMeatAlternatives) {
          newModifier = newModifier + -10;
        } else {
          effectIsNotMet = [...effectIsNotMet, 1];
        }
        const usedCardMeatAlternativeSubsidies = usedCards.some(
          (r) => r === ActionType.GOVERNMENT_MEAT_ALTERNATIVE_SUBSIDIES,
        );
        if (usedCardMeatAlternativeSubsidies) {
          newModifier = newModifier + -15;
        } else {
          effectIsNotMet = [...effectIsNotMet, 2];
        }
        const usedAnimalAgriculture = usedCards.some(
          (r) => r === ActionType.ENVIRONMENTAL_ACTIVIST_ANIMAL_AGRICULTURE,
        );
        if (usedAnimalAgriculture) {
          newModifier = newModifier + -10;
        } else {
          effectIsNotMet = [...effectIsNotMet, 3];
        }
        currentCard = { ...currentCard, modifier: newModifier };
        break;
      case ActionType.BUSINESS_CLOSED_CLOTHING_PRODUCTION:
        const busunessUsedCard = usedCards.some(
          (r) => r === ActionType.CONSUMER_CLOSED_CLOTHING_PRODUCTION,
        );
        if (!busunessUsedCard) {
          isRequirement = [0];
        }
        disabledPlayButton = !busunessUsedCard;
        effectIsNotMet = [];
        break;
      case ActionType.BUSINESS_GREEN_ENERGY:
        const busunessUsedGreenEnergy = usedCards.some(
          (r) => r === ActionType.CONSUMER_GREEN_ENERGY,
        );
        const busunessUsedNuclearEnergy = usedCards.some(
          (r) => r === ActionType.SCIENCE_NUCLEAR_ENERGY,
        );
        isRequirement = [];
        disabledPlayButton = false;
        if (!busunessUsedGreenEnergy) {
          effectIsNotMet = [0];
        }
        if (!busunessUsedNuclearEnergy) {
          effectIsNotMet = [...effectIsNotMet, 1];
        }
        break;
      case ActionType.BUSINESS_GREEN_PLASTIC_PRODUCTS:
        const busunessPlasticUsedCard = usedCards.some(
          (r) => r === ActionType.SCIENCE_PLANT_BASED_PLASTIC,
        );
        const consumerPlasticUsedCard = usedCards.some(
          (r) => r === ActionType.CONSUMER_SUSTAINABLE_PLASTIC,
        );
        let isRequirementBusynnes = !(busunessPlasticUsedCard && consumerPlasticUsedCard);
        const existTwoGreen =
          constructions?.filter(
            (r) =>
              r.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
              r.type === ConstructionTypes.INDUSTRY,
          ) || [];
        if (isRequirementBusynnes) {
          isRequirement = [0];
        }
        if (existTwoGreen?.length < 2) {
          isRequirement = [...isRequirement, 1];
        }
        disabledPlayButton = isRequirementBusynnes || existTwoGreen?.length < 2;
        effectIsNotMet = [];
        break;
      case ActionType.BUSINESS_RECYCLING_PROGRAM:
        const busunessRecyclingProgram = usedCards.some(
          (r) => r === ActionType.GOVERNMENT_RECYCLING_PROGRAM,
        );
        const existTwoGreenForRecycling =
          constructions?.filter(
            (r) =>
              r.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
              r.type === ConstructionTypes.INDUSTRY,
          ) || [];
        const isDisabledPlayButton = !busunessRecyclingProgram;
        if (isDisabledPlayButton) {
          isRequirement = [0];
        }
        if (existTwoGreenForRecycling?.length < 2) {
          isRequirement = [...isRequirement, 1];
        }
        disabledPlayButton = isDisabledPlayButton || existTwoGreenForRecycling?.length < 2;
        effectIsNotMet = [];
        break;
      case ActionType.ENVIRONMENTAL_ACTIVIST_INFRASTRUCTURE:
        const environmentalInfrastructure = usedCards.some(
          (r) =>
            r === ActionType.GOVERNMENT_ROAD_INFRASTRUCTURE_UPDATE ||
            r === ActionType.SCIENCE_COOL_PAVEMENTS,
        );
        if (!environmentalInfrastructure) {
          effectIsNotMet = [0];
        }
        const conservationConstructions = constructions?.filter(
          (c) => c.type === ConstructionTypes.USER,
        );
        const isDisabledPlayButtonCost = !(
          conservationConstructions && conservationConstructions?.length > 2
        );
        disabledPlayButton = isDisabledPlayButtonCost;
        isRequirement = [];
        if (isDisabledPlayButtonCost) {
          costIsNotMet = [0];
        }
        break;
      case ActionType.GOVERNMENT_RECYCLING_PROGRAM:
        const governmentRecyclingProgram = usedCards.some(
          (r) => r === ActionType.SCIENCE_MACHINE_SORTED_RECYCLE,
        );
        if (!governmentRecyclingProgram) {
          isRequirement = [0];
        }
        disabledPlayButton = !governmentRecyclingProgram;
        effectIsNotMet = [];
        break;
      case ActionType.GOVERNMENT_ROAD_INFRASTRUCTURE_UPDATE:
        const governmentInfrastructure = usedCards.some(
          (r) => r === ActionType.SCIENCE_COOL_PAVEMENTS,
        );
        if (!governmentInfrastructure) {
          isRequirement = [0];
        }
        disabledPlayButton = !governmentInfrastructure;
        effectIsNotMet = [];
        break;
      case ActionType.SCIENCE_LAB_GROWN_MEAT:
        const scienceMeatAlternatives = usedCards.some(
          (r) => r === ActionType.CONSUMER_MEAT_ALTERNATIVES,
        );
        if (!scienceMeatAlternatives) {
          isRequirement = [0];
        }
        disabledPlayButton = !scienceMeatAlternatives;
        effectIsNotMet = [];
        break;
      case ActionType.SCIENCE_NUCLEAR_ENERGY:
        const scienceNuclearEnergy = usedCards.some(
          (r) => r === ActionType.GOVERNMENT_NUCLEAR_ENERGY,
        );
        if (!scienceNuclearEnergy) {
          isRequirement = [0];
        }
        disabledPlayButton = !scienceNuclearEnergy;
        effectIsNotMet = [];
        break;
      case ActionType.ENVIRONMENTAL_ACTIVIST_FOSSIL_FUELS_FRACKING:
        const industires =
          constructions?.filter(
            (c) =>
              c.type === ConstructionTypes.INDUSTRY &&
              c.typeBuild === ConstructionTypesBuild.RESOURCE,
          ) || [];
        const existIndustires = industires?.length > 0;
        if (!existIndustires) {
          effectIsNotMet = [0];
        }
        isRequirement = [];
        disabledPlayButton = !existIndustires;
        break;
      case ActionType.ENVIRONMENTAL_ACTIVIST_ANIMAL_AGRICULTURE:
        const industiresForAnimal =
          constructions?.filter(
            (c) =>
              c.type === ConstructionTypes.INDUSTRY &&
              c.typeBuild === ConstructionTypesBuild.RESOURCE,
          ) || [];
        const existIndustiresForAnimal = industiresForAnimal?.length > 0;
        if (!existIndustiresForAnimal) {
          effectIsNotMet = [0];
        }
        isRequirement = [];
        disabledPlayButton = !existIndustiresForAnimal;
        break;
      case ActionType.ENVIRONMENTAL_ACTIVIST_DEFORESTATION:
        const industiresDeforestation =
          constructions?.filter(
            (c) =>
              c.type === ConstructionTypes.INDUSTRY &&
              c.typeBuild === ConstructionTypesBuild.RESOURCE,
          ) || [];
        const existIndustiresDeforestation = industiresDeforestation?.length > 1;
        if (!existIndustiresDeforestation) {
          effectIsNotMet = [0];
        }
        isRequirement = [];
        disabledPlayButton = !existIndustiresDeforestation;
        break;
      case ActionType.BUSINESS_R_D_X:
        const factoryAccelerator =
          constructions?.filter(
            (c) =>
              c.type === ConstructionTypes.INDUSTRY &&
              c.typeBuild === ConstructionTypesBuild.RESOURCE,
          ) || [];
        const existFactoryAccelerator = factoryAccelerator?.length > 0;
        if (!existFactoryAccelerator) {
          costIsNotMet = [0];
        }
        isRequirement = [];
        disabledPlayButton = !existFactoryAccelerator;
        break;
      default:
        break;
    }
    setRequirementIsNotMet(isRequirement);
    setCostIsNotMet(costIsNotMet);
    setEffectIsNotMet(effectIsNotMet);
    setDisabledPlayButton(disabledPlayButton);
    setDetailsModal(true);
    selectAction(undefined);
    setLocalCard(currentCard);
  };

  const closeProductDetails = () => {
    setDetailsModal(false);
    setLocalCard(undefined);
  };

  const revertRemovedBuild = async () => {
    try {
      let curentIndex = localStorage.getItem('play_institution');
      if (curentIndex) {
        const { data } = await GameService.getActions(gameId, curentIndex, 5, 0);
        for (let construction of data.items) {
          await GameService.addActions(gameId, {
            state: { ...construction.state, label: 'add_build' },
            type: 'play_institution',
            worldState: undefined,
          });
          Emitter.emit(EventType.ADD_NEW_BUILD, { ...construction.state, label: 'add_build' });
        }
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    } finally {
      setCityModal(false);
      setDetailsModal(false);
      setLocalCard(undefined);
      setOpenNext(false);
      localStorage.removeItem('play_institution');
    }
  };

  const closeCoonfirmSelectCity = () => {
    console.log('revertRemovedBuild');
    switch (localCard?.action) {
      case ActionType.GOVERNMENT_MEAT_ALTERNATIVE_SUBSIDIES:
        revertRemovedBuild();
        break;
      default:
        localStorage.removeItem('play_institution');
    }
    Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
    setSelectCountry('');
    setConfirmSelectCity(false);
    setCityModal(false);
    setSelectCityModal(false);
    setShowPinTitle('');
  };

  const finishUseCard = () => {
    Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, false);
    setSelectCityModal(false);
    setDetailsModal(false);
    setLocalCard(undefined);
    setOpenNext(false);
    setCityModal(false);
    setChangeCityDescription('');
    setSelectCountry('');
    setConfirmSelectCity(false);
    setDetailsActionModal(false);
    setDisabledSuperPowerButton(false);
    setLocalActionCard(undefined);
    setSelectedPlayer(undefined);
    localStorage.removeItem('play_institution');
  };

  const callClothingProduction = async (country: string) => {
    try {
      const simpleMalls = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.STORE &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      if (simpleMalls?.length) {
        const simpleMall = simpleMalls[0];
        let newConstructions: ConstructionI[] | undefined = constructions || [];
        await GameService.addActions(gameId, {
          state: { ...simpleMall, label: 'remove_build' },
          type: 'play_institution',
          worldState: undefined,
        });
        let index = newConstructions.findIndex(
          (r) =>
            r.country === simpleMall.country &&
            r.type === simpleMall.type &&
            r.typeBuild === simpleMall.typeBuild,
        );
        if (index !== -1) {
          delete newConstructions[index];
        }
        changeInstitution({
          ...localCard!,
          log: `played the ${localCard?.name}`,
          currentModifier: localCard?.modifier || 0,
        });
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const removeAllBuilds = async () => {
    try {
      let curentIndex = localStorage.getItem('play_institution');
      if (curentIndex) {
        const { data } = await GameService.getActions(gameId, curentIndex, 5, 0);
        for (let construction of data.items) {
          await GameService.addActions(gameId, {
            state: { ...construction.state, label: 'remove_build' },
            type: 'play_institution',
            worldState: undefined,
          });

          const id = `point_${construction.state.type}_${construction.state.typeBuild}_${
            construction.state.country || '1'
          }`;
          removeBuild(id);
        }
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    } finally {
      setCityModal(false);
      setDetailsModal(false);
      setLocalCard(undefined);
      setOpenNext(false);
      localStorage.removeItem('play_institution');
    }
  };

  const callCheaperGoods = async (country: string) => {
    try {
      let curentIndex = localStorage.getItem('play_institution');
      let newAddBuilds: ConstructionI[] = [];
      if (curentIndex) {
        const { data } = await GameService.getActions(gameId, curentIndex, 5, 0);
        newAddBuilds = (data.items.map((e) => e.state) as ConstructionI[]) || [];
      } else {
        curentIndex = new Date().getTime().toString();
        localStorage.setItem('play_institution', curentIndex);
      }
      let construction: ConstructionI | undefined;
      const simpleMalls = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.STORE &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      if (simpleMalls?.length) {
        construction = {
          procentX: simpleMalls[0].procentX,
          procentY: simpleMalls[0].procentY,
          type: simpleMalls[0].type,
          typeBuild: simpleMalls[0].typeBuild,
          country: simpleMalls[0].country,
          card_value: simpleMalls[0]?.card_value,
          label: 'add_build',
          userId: meRole.id,
        };
      } else {
        const cityPoints = POINTS[country];
        const countryConstructions = constructions?.filter((c) => c.country === country);
        const diffPoints = cityPoints.filter(
          (item) =>
            !countryConstructions?.some((c) => c.procentX === item.x && c.procentY === item.y),
        );
        construction = {
          procentX: diffPoints[0].x,
          procentY: diffPoints[0].y,
          type: ConstructionTypes.STORE,
          typeBuild: ConstructionTypesBuild.RESOURCE,
          country,
          card_value: 'consumer_resource',
          label: 'add_build',
          userId: meRole.id,
        };
      }
      if (construction) {
        await GameService.addActions(gameId, {
          state: construction,
          type: curentIndex,
          worldState: undefined,
        });
        newAddBuilds = [...newAddBuilds, construction];
        Emitter.emit(EventType.ADD_NEW_BUILD, construction);
      } else {
        setChangeCityDescription('No building was found');
      }
      if (newAddBuilds.length === 5) {
        let newConstructions: ConstructionI[] | undefined = [
          ...(constructions || []),
          ...newAddBuilds,
        ];
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callGreenEnergy = async () => {
    try {
      changeInstitution(
        {
          ...localCard!,
          log: `played the ${localCard?.name}`,
          currentModifier: localCard?.modifier || 0,
        },
        [],
        undefined,
        [{ card: ActionType.BUSINESS_GREEN_ENERGY, role: 'business' }],
      );
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    } finally {
      setDetailsModal(false);
      setLocalCard(undefined);
      setOpenNext(false);
      setCityModal(false);
    }
  };

  const callLocalGreenMalls = async (country: string) => {
    try {
      const simpleMalls = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.STORE &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      let newConstructions: ConstructionI[] | undefined = constructions || [];
      if (simpleMalls?.length) {
        const greenMall = constructions?.find(
          (c) =>
            c.type === ConstructionTypes.STORE &&
            c.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
            c.country === country,
        );
        let newBuilds: ConstructionI[] = [];
        let addBuilds: ConstructionI[] = [];
        const simpleMall = simpleMalls[0];
        if (greenMall) {
          addBuilds = [
            {
              procentX: greenMall.procentX,
              procentY: greenMall.procentY,
              type: greenMall.type,
              typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
              country: greenMall.country,
              card_value: greenMall?.card_value,
              label: 'add_build',
              userId: meRole.id,
            },
            {
              procentX: greenMall.procentX,
              procentY: greenMall.procentY,
              type: greenMall.type,
              typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
              country: greenMall.country,
              card_value: greenMall?.card_value,
              label: 'add_build',
              userId: meRole.id,
            },
          ];
          newBuilds = [
            ...newBuilds,
            {
              procentX: simpleMall.procentX,
              procentY: simpleMall.procentY,
              type: simpleMall.type,
              typeBuild: simpleMall.typeBuild,
              country: simpleMall.country,
              card_value: simpleMall?.card_value,
              label: 'remove_build',
              userId: meRole.id,
            },
          ];
          let index = newConstructions.findIndex(
            (r) =>
              r.country === simpleMall.country &&
              r.type === simpleMall.type &&
              r.typeBuild === simpleMall.typeBuild,
          );

          if (index !== -1) {
            delete newConstructions[index];
          }
        } else {
          if (simpleMalls?.length === 1) {
            newBuilds = [
              ...newBuilds,
              {
                procentX: simpleMall.procentX,
                procentY: simpleMall.procentY,
                type: simpleMall.type,
                country: simpleMall.country,
                card_value: simpleMall?.card_value,
                typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
                label: 'change_build',
                userId: meRole.id,
              },
            ];
            addBuilds = [
              ...addBuilds,
              {
                procentX: simpleMall.procentX,
                procentY: simpleMall.procentY,
                type: simpleMall.type,
                typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
                country: simpleMall.country,
                card_value: simpleMall?.card_value,
                label: 'add_build',
                userId: meRole.id,
              },
            ];
            let index = newConstructions.findIndex(
              (r) =>
                r.country === simpleMall.country &&
                r.type === simpleMall.type &&
                r.typeBuild === simpleMall.typeBuild,
            );
            if (index !== -1) {
              newConstructions[index] = {
                ...newConstructions[index],
                typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
              };
            }
          } else {
            const cityPoints = POINTS[country];
            const diffPoints = cityPoints.filter(
              (item) =>
                !constructions?.some(
                  (c) =>
                    c.country === item.cityId.toString() &&
                    c.procentX === item.x &&
                    c.procentY === item.y,
                ),
            );
            addBuilds = [
              {
                procentX: diffPoints[0].x,
                procentY: diffPoints[0].y,
                type: simpleMall.type,
                typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
                country: simpleMall.country,
                card_value: simpleMall?.card_value,
                label: 'add_build',
                userId: meRole.id,
              },
              {
                procentX: diffPoints[0].x,
                procentY: diffPoints[0].y,
                type: simpleMall.type,
                typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
                country: simpleMall.country,
                card_value: simpleMall?.card_value,
                label: 'add_build',
                userId: meRole.id,
              },
            ];
            newBuilds = [
              ...newBuilds,
              {
                procentX: simpleMall.procentX,
                procentY: simpleMall.procentY,
                type: simpleMall.type,
                typeBuild: simpleMall.typeBuild,
                country: simpleMall.country,
                card_value: simpleMall?.card_value,
                label: 'remove_build',
                userId: meRole.id,
              },
            ];
            let index = newConstructions.findIndex(
              (r) =>
                r.country === simpleMall.country &&
                r.type === simpleMall.type &&
                r.typeBuild === simpleMall.typeBuild,
            );

            if (index !== -1) {
              delete newConstructions[index];
            }
          }
        }
        newBuilds = [...addBuilds, ...newBuilds];
        newConstructions = [...newConstructions, ...addBuilds];
        let promises = [];
        for (let construction of newBuilds) {
          promises.push(
            GameService.addActions(gameId, {
              state: construction,
              type: 'play_institution',
              worldState: undefined,
            }),
          );
          Emitter.emit(EventType.ADD_NEW_BUILD, construction);
        }
        newConstructions = newConstructions.filter((c) => !!c);
        await Promise.all([
          ...promises,
          changeInstitution(
            {
              ...localCard!,
              log: `played the ${localCard?.name}`,
              currentModifier: localCard?.modifier || 0,
            },
            newConstructions,
          ),
        ]);
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callSustainablePlastic = (country: string) => {
    try {
      const usedCardGreenPlastic = usedCards.some(
        (r) => r === ActionType.BUSINESS_GREEN_PLASTIC_PRODUCTS,
      );
      let newConstructions: ConstructionI[] | undefined = constructions || [];
      const simpleMalls = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.STORE &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      if (simpleMalls?.length) {
        let addBuilds: ConstructionI[] = [];
        const simpleMall = simpleMalls[0];
        if (usedCardGreenPlastic) {
          const greenMall = constructions?.find(
            (c) =>
              c.type === ConstructionTypes.STORE &&
              c.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
              c.country === country,
          );
          if (greenMall) {
            addBuilds = [
              {
                procentX: greenMall.procentX,
                procentY: greenMall.procentY,
                type: greenMall.type,
                typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
                country: greenMall.country,
                card_value: greenMall?.card_value,
                label: 'add_build',
                userId: meRole.id,
              },
            ];
            newConstructions = [
              ...newConstructions,
              {
                procentX: greenMall.procentX,
                procentY: greenMall.procentY,
                type: greenMall.type,
                typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
                country: greenMall.country,
                card_value: greenMall?.card_value,
                label: 'add_build',
                userId: meRole.id,
              },
            ];
          } else {
            const cityPoints = POINTS[country];
            const diffPoints = cityPoints.filter(
              (item) =>
                !constructions?.some(
                  (c) =>
                    c.country === item.cityId.toString() &&
                    c.procentX === item.x &&
                    c.procentY === item.y,
                ),
            );
            addBuilds = [
              {
                procentX: diffPoints[0].x,
                procentY: diffPoints[0].y,
                type: simpleMall.type,
                typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
                country,
                card_value: simpleMall?.card_value,
                label: 'add_build',
                userId: meRole.id,
              },
            ];
            newConstructions = [
              ...newConstructions,
              {
                procentX: diffPoints[0].x,
                procentY: diffPoints[0].y,
                type: simpleMall.type,
                typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
                country,
                card_value: simpleMall?.card_value,
                label: 'add_build',
                userId: meRole.id,
              },
            ];
          }
        }
        addBuilds = [
          ...addBuilds,
          {
            procentX: simpleMall.procentX,
            procentY: simpleMall.procentY,
            type: simpleMall.type,
            typeBuild: simpleMall.typeBuild,
            country: simpleMall.country,
            card_value: simpleMall?.card_value,
            label: 'remove_build',
            userId: meRole.id,
          },
        ];
        let index = newConstructions.findIndex(
          (r) =>
            r.country === simpleMall.country &&
            r.type === simpleMall.type &&
            r.typeBuild === simpleMall.typeBuild,
        );
        if (index !== -1) {
          delete newConstructions[index];
        }
        let promises = [];
        for (let construction of addBuilds) {
          promises.push(
            GameService.addActions(gameId, {
              state: construction,
              type: 'play_institution',
              worldState: undefined,
            }),
          );
        }
        newConstructions = newConstructions?.filter((c) => !!c);
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
          undefined,
          [{ card: ActionType.BUSINESS_GREEN_PLASTIC_PRODUCTS, role: 'business' }],
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const onSubmitConsumer = () => {
    switch (localCard?.action) {
      case ActionType.CONSUMER_CLOSED_CLOTHING_PRODUCTION:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Closes one mall in one city');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.CONSUMER_CHEAPER_GOODS:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Select a city in which to build a standard structures');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.CONSUMER_GREEN_ENERGY:
        callGreenEnergy();
        break;
      case ActionType.CONSUMER_SMALL_BUSINESSES:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Replace one mall with two Green Malls in one city');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.CONSUMER_CARBON_CAPTURE:
      case ActionType.CONSUMER_MEAT_ALTERNATIVES:
        changeInstitution({
          ...localCard!,
          log: `played the ${localCard?.name}`,
          currentModifier: localCard?.modifier || 0,
        });
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        break;
      case ActionType.CONSUMER_SUSTAINABLE_PLASTIC:
        const usedCardGreenPlastic = usedCards.some(
          (r) => r === ActionType.BUSINESS_GREEN_PLASTIC_PRODUCTS,
        );
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle(
          usedCardGreenPlastic
            ? 'Replace a standard mall with a green mall in one city'
            : 'Remove one mall from one city',
        );
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      default:
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        break;
    }
  };

  const callAnimalAgriculture = async (country: string) => {
    try {
      let newConstructions: ConstructionI[] | undefined = constructions || [];
      const industires = newConstructions?.filter(
        (c) =>
          c.type === ConstructionTypes.INDUSTRY &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      let promises = [];
      if (industires?.length) {
        for (let industiry of industires) {
          promises.push(
            GameService.addActions(gameId, {
              state: { ...industiry, label: 'remove_build' },
              type: 'play_institution',
              worldState: undefined,
            }),
          );
          const id = `point_${industiry.type}_${industiry.typeBuild}_${industiry.country || '1'}`;
          removeBuild(id);
          let index = newConstructions.findIndex(
            (r) =>
              r?.country === country &&
              r?.type === industiry.type &&
              r?.typeBuild === industiry.typeBuild,
          );
          if (index !== -1) {
            delete newConstructions[index];
          }
        }
        await Promise.all(promises);
        newConstructions = newConstructions?.filter((c) => !!c);
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callDeforestation = async (country: string) => {
    try {
      let newConstructions = constructions || [];
      const simpleIndustries = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.INDUSTRY &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      if (simpleIndustries?.length && simpleIndustries?.length > 1) {
        const standartFactories = simpleIndustries.slice(0, 2);
        for (let simpleIndustry of standartFactories) {
          const construction = {
            procentX: simpleIndustry.procentX,
            procentY: simpleIndustry.procentY,
            type: simpleIndustry.type,
            typeBuild: simpleIndustry.typeBuild,
            country: simpleIndustry.country,
            card_value: simpleIndustry?.card_value,
            label: 'remove_build',
            userId: simpleIndustry.userId,
          };
          if (construction) {
            await GameService.addActions(gameId, {
              state: construction,
              type: '',
              worldState: undefined,
            });
          }
          if (newConstructions?.length) {
            let index = newConstructions?.findIndex(
              (r) =>
                r?.country === construction.country &&
                r?.type === construction.type &&
                r?.typeBuild === construction.typeBuild,
            );
            if (index !== -1) {
              delete newConstructions[index];
            }
          }
        }
        newConstructions = newConstructions?.filter((c) => !!c);

        let greenConservation = newConstructions?.find(
          (c) =>
            c?.type === ConstructionTypes.USER &&
            c?.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
            c?.country === country,
        );
        if (!greenConservation) {
          const cityPoints = POINTS[country];
          const countryConstructions = newConstructions?.filter((c) => c?.country === country);
          const diffPoints = cityPoints.filter(
            (item) =>
              !countryConstructions?.some((c) => c.procentX === item.x && c.procentY === item.y),
          );
          greenConservation = {
            procentX: diffPoints[0].x,
            procentY: diffPoints[0].y,
            country,
            type: ConstructionTypes.USER,
            typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
            label: 'add_build',
          };
        }
        const userConservation = {
          procentX: greenConservation?.procentX,
          procentY: greenConservation?.procentY,
          type: ConstructionTypes.USER,
          typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
          country,
          card_value: 'environmental_activist_green_resource',
          label: 'add_build',
          userId: meRole.id,
        };
        newConstructions?.push(userConservation);
        await GameService.addActions(gameId, {
          state: userConservation,
          type: 'play_institution',
          worldState: undefined,
        });
        Emitter.emit(EventType.ADD_NEW_BUILD, userConservation);
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription(
          `Can't find two standard factories in the selected city. Please try another one.`,
        );
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callCleanEnergyNow = async (country: string) => {
    try {
      let newConstructions: ConstructionI[] | undefined = constructions?.filter((c) => !!c) || [];
      const industires = newConstructions?.filter(
        (c) =>
          c.type === ConstructionTypes.INDUSTRY &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      let greenConservation = newConstructions?.find(
        (c) =>
          c.type === ConstructionTypes.USER &&
          c.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
          c.country === country,
      );
      if (!greenConservation) {
        const cityPoints = POINTS[country];
        const countryConstructions = newConstructions?.filter((c) => c?.country === country);
        const diffPoints = cityPoints.filter(
          (item) =>
            !countryConstructions?.some((c) => c.procentX === item.x && c.procentY === item.y),
        );
        greenConservation = {
          procentX: diffPoints[0].x,
          procentY: diffPoints[0].y,
          country,
          type: ConstructionTypes.USER,
          typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
          label: 'add_build',
          card_value: 'environmental_activist_green_resource',
          userId: meRole.id,
        };
      }
      let promises = [];
      if (industires?.length) {
        for (let industiry of industires) {
          promises.push(
            GameService.addActions(gameId, {
              state: { ...industiry, label: 'remove_build' },
              type: 'play_institution',
              worldState: undefined,
            }),
          );
          const id = `point_${industiry.type}_${industiry.typeBuild}_${industiry.country || '1'}`;
          removeBuild(id);
          let index = newConstructions.findIndex(
            (r) =>
              r?.country === country &&
              r?.type === industiry.type &&
              r?.typeBuild === industiry.typeBuild,
          );
          if (index !== -1) {
            delete newConstructions[index];
          }
          Emitter.emit(EventType.ADD_NEW_BUILD, greenConservation);
          newConstructions.push(greenConservation);
        }
        await Promise.all(promises);
        newConstructions = newConstructions?.filter((c) => !!c);
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callInfrastructure = async () => {
    try {
      const conservationConstructions = constructions?.filter(
        (c) => c.type === ConstructionTypes.USER,
      );
      const conservationCons = conservationConstructions?.sort(() => 0.5 - Math.random());
      const remoovedAddBuilds = conservationCons?.slice(0, 3);
      if (remoovedAddBuilds && remoovedAddBuilds?.length >= 3) {
        let newConstructions: ConstructionI[] | undefined = constructions || [];
        for (let industry of remoovedAddBuilds) {
          await GameService.addActions(gameId, {
            state: { ...industry, label: 'remove_build' },
            type: 'play_institution',
            worldState: undefined,
          });
          const id = `point_${industry.type}_${industry.typeBuild}_${industry.country || '1'}`;
          removeBuild(id);
          let index = newConstructions.findIndex(
            (r) =>
              r?.country === industry.country &&
              r?.type === industry.type &&
              r?.typeBuild === industry.typeBuild,
          );
          if (index !== -1) {
            delete newConstructions[index];
          }
        }
        newConstructions = newConstructions.filter((c) => !!c);

        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callWildlifeRestorationFundraiser = async (country: string) => {
    try {
      let curentIndex = localStorage.getItem('play_institution');
      let newAddBuilds: ConstructionI[] = [];
      if (curentIndex) {
        const { data } = await GameService.getActions(gameId, curentIndex, 2, 0);
        newAddBuilds = (data.items.map((e) => e.state) as ConstructionI[]) || [];
      } else {
        curentIndex = new Date().getTime().toString();
        localStorage.setItem('play_institution', curentIndex);
      }
      let construction: ConstructionI | undefined;
      const simpleUsers = constructions?.filter(
        (c) =>
          c?.type === ConstructionTypes.USER &&
          c?.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
          c?.country === country,
      );
      if (simpleUsers?.length) {
        construction = {
          procentX: simpleUsers[0].procentX,
          procentY: simpleUsers[0].procentY,
          type: simpleUsers[0].type,
          typeBuild: simpleUsers[0].typeBuild,
          country: simpleUsers[0].country,
          card_value: simpleUsers[0]?.card_value,
          label: 'add_build',
          userId: simpleUsers[0].userId,
        };
      } else {
        const cityPoints = POINTS[country];
        const countryConstructions = constructions?.filter((c) => c?.country === country);
        const diffPoints = cityPoints.filter(
          (item) =>
            !countryConstructions?.some((c) => c.procentX === item.x && c.procentY === item.y),
        );
        construction = {
          procentX: diffPoints[0].x,
          procentY: diffPoints[0].y,
          type: ConstructionTypes.USER,
          typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
          country,
          card_value: 'environmental_activist_green_resource',
          label: 'add_build',
          userId: meRole.id,
        };
      }
      if (construction) {
        await GameService.addActions(gameId, {
          state: construction,
          type: curentIndex,
          worldState: undefined,
        });
        newAddBuilds = [...newAddBuilds, construction];
        Emitter.emit(EventType.ADD_NEW_BUILD, construction);
      } else {
        setChangeCityDescription('No building was found');
      }

      if (newAddBuilds.length >= 2) {
        let newConstructions: ConstructionI[] | undefined = constructions || [];

        newConstructions = [...newAddBuilds, ...newConstructions];
        newConstructions = newConstructions.filter((c) => !!c);

        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callGlobalClimateMarch = async (country: string) => {
    try {
      let curentIndex = localStorage.getItem('play_institution');
      let newAddBuilds: ConstructionI[] = [];
      if (curentIndex) {
        const { data } = await GameService.getActions(gameId, curentIndex, 2, 0);
        newAddBuilds = (data.items.map((e) => e.state) as ConstructionI[]) || [];
      } else {
        curentIndex = new Date().getTime().toString();
        localStorage.setItem('play_institution', curentIndex);
      }
      let construction: ConstructionI | undefined;
      const simpleUsers = constructions?.filter(
        (c) => c.type === ConstructionTypes.USER && c.country === country,
      );
      if (simpleUsers?.length) {
        construction = {
          procentX: simpleUsers[0].procentX,
          procentY: simpleUsers[0].procentY,
          type: simpleUsers[0].type,
          typeBuild: simpleUsers[0].typeBuild,
          country: simpleUsers[0].country,
          card_value: simpleUsers[0]?.card_value,
          label: 'remove_build',
          userId: simpleUsers[0].userId,
        };
      }
      if (construction) {
        await GameService.addActions(gameId, {
          state: construction,
          type: curentIndex,
          worldState: undefined,
        });
        newAddBuilds = [...newAddBuilds, construction];
        const id = `point_${construction.type}_${construction.typeBuild}_${
          construction.country || '1'
        }`;
        removeBuild(id);
      } else {
        setChangeCityDescription('No building was found');
      }

      if (newAddBuilds.length >= 2) {
        let newConstructions: ConstructionI[] | undefined = constructions || [];
        for (let industry of newAddBuilds) {
          let index = newConstructions.findIndex(
            (r) =>
              r?.country === industry.country &&
              r?.type === industry.type &&
              r?.typeBuild === industry.typeBuild,
          );
          if (index !== -1) {
            delete newConstructions[index];
          }
        }
        newConstructions = newConstructions.filter((c) => !!c);

        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
          'environmental_activist_mass_rally_',
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callGreenMandate = async (country: string) => {
    try {
      const banks = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.BANK &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      const greenBanks = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.BANK &&
          c.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
          c.country === country,
      );

      if (banks?.length) {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, false);
        let greenBank = greenBanks?.length ? greenBanks[0] : undefined;
        if (!greenBank) {
          const cityPoints = POINTS[country];
          const countryConstructions = constructions?.filter((c) => c.country === country);
          const diffPoints = cityPoints.filter(
            (item) =>
              !countryConstructions?.some((c) => c.procentX === item.x && c.procentY === item.y),
          );
          greenBank = {
            procentX: diffPoints[0].x,
            procentY: diffPoints[0].y,
            type: ConstructionTypes.BANK,
            typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
            country,
            card_value: 'government_green_resource',
            label: 'add_build',
            userId: meRole.id,
          };
        }
        const bank = banks[0];
        let newConstructions: ConstructionI[] | undefined = constructions || [];
        let index = newConstructions.findIndex(
          (r) =>
            r?.country === bank.country && r?.type === bank.type && r?.typeBuild === bank.typeBuild,
        );

        if (index !== -1) {
          delete newConstructions[index];
        }
        await GameService.addActions(gameId, {
          state: { ...bank, label: 'remove_build' },
          type: 'play_institution',
          worldState: undefined,
        });
        const id = `point_${bank.type}_${bank.typeBuild}_${bank.country || '1'}`;
        removeBuild(id);
        await GameService.addActions(gameId, {
          state: greenBank,
          type: 'play_institution',
          worldState: undefined,
        });
        Emitter.emit(EventType.ADD_NEW_BUILD, greenBank);
        newConstructions = [...newConstructions, greenBank];
        newConstructions = newConstructions.filter((c) => !!c);

        changeSuperPower(
          {
            ...localActionCard!,
            log: `used Green Mandate power`,
          },
          newConstructions,
          'government',
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No non-green building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const onSubmitEnvironmentalActivist = () => {
    switch (localCard?.action) {
      case ActionType.ENVIRONMENTAL_ACTIVIST_ANIMAL_AGRICULTURE:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Select one city to shut down all standard factories');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.ENVIRONMENTAL_ACTIVIST_DEFORESTATION:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Replace two standard factories with a green conservation in any city');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.ENVIRONMENTAL_ACTIVIST_FOSSIL_FUELS_FRACKING:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Convert up to three factories in one city into green conservations');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.ENVIRONMENTAL_ACTIVIST_INFRASTRUCTURE:
        callInfrastructure();
        break;
      case ActionType.ENVIRONMENTAL_ACTIVIST_GREEN_ENERGY:
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          [],
          undefined,
          [
            { card: ActionType.CONSUMER_GREEN_ENERGY, role: 'consumer' },
            { card: ActionType.SCIENCE_NUCLEAR_ENERGY, role: 'science' },
            { card: ActionType.BUSINESS_GREEN_ENERGY, role: 'business' },
            { card: ActionType.GOVERNMENT_NUCLEAR_ENERGY, role: 'government' },
          ],
        );
        setCityModal(false);
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        break;
      case ActionType.ENVIRONMENTAL_ACTIVIST_DONATIONS_FOR_CONSERVATION:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Convert up to three factories in one city into green conservations');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.ENVIRONMENTAL_ACTIVIST_MASS_RALLY:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Remove one conservation from two cities');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      default:
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        break;
    }
  };

  const callAlternativeMeatProducts = async (country: string) => {
    try {
      const banks = constructions?.filter(
        (c) => c.type === ConstructionTypes.INDUSTRY && c.country === country,
      );
      let newConstructions: ConstructionI[] | undefined = constructions || [];

      if (banks?.length) {
        let promises = [];
        for (let bank of banks) {
          promises.push(
            GameService.addActions(gameId, {
              state: { ...bank, label: 'remove_build' },
              type: 'play_institution',
              worldState: undefined,
            }),
          );
          let index = newConstructions.findIndex(
            (r) =>
              r?.country === country && r?.type === bank.type && r?.typeBuild === bank.typeBuild,
          );
          if (index !== -1) {
            delete newConstructions[index];
          }
        }
        await Promise.all(promises);
        newConstructions = newConstructions?.filter((c) => !!c);
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const checkAlternativeMeatProducts = () => {
    const usedCardMeatAlternatives = usedCards.some(
      (r) => r === ActionType.GOVERNMENT_MEAT_ALTERNATIVE_SUBSIDIES,
    );
    if (usedCardMeatAlternatives) {
      changeInstitution({
        ...localCard!,
        log: `played the ${localCard?.name}`,
        currentModifier: localCard?.modifier || 0,
      });
      setDetailsModal(false);
      setLocalCard(undefined);
      setOpenNext(false);
      setCityModal(false);
    } else {
      setDetailsModal(false);
      setCityModal(true);
      setOpenNext(false);
      setShowPinTitle('Select a city to remove all the factories');
      Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
    }
  };

  const callGreenEnergyBusiness = () => {
    const usedCardGreenEnergy = usedCards.some(
      (r) =>
        r === ActionType.ENVIRONMENTAL_ACTIVIST_GREEN_ENERGY ||
        r === ActionType.CONSUMER_GREEN_ENERGY,
    );
    let modifier = 0;
    if (usedCardGreenEnergy) {
      modifier = -15;
    }
    const usedCardNuclearEnergy = usedCards.some(
      (r) => r === ActionType.SCIENCE_NUCLEAR_ENERGY || r === ActionType.GOVERNMENT_NUCLEAR_ENERGY,
    );
    if (usedCardNuclearEnergy) {
      modifier = modifier + -20;
    }
    if (!(usedCardGreenEnergy && usedCardNuclearEnergy)) {
      modifier = -5;
    }
    changeInstitution({
      ...localCard!,
      log: `played the ${localCard?.name}`,
      currentModifier: modifier || 0,
    });
    setDetailsModal(false);
    setLocalCard(undefined);
    setOpenNext(false);
  };

  const callRecyclingProgram = async (country: string) => {
    try {
      const simpleBanks = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.BANK &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      let newConstructions: ConstructionI[] | undefined = constructions || [];
      let addBuilds: ConstructionI[] | undefined = [];
      if (simpleBanks?.length) {
        const simpleBank = simpleBanks[0];
        addBuilds = [
          {
            procentX: simpleBank.procentX,
            procentY: simpleBank.procentY,
            type: simpleBank.type,
            typeBuild: ConstructionTypesBuild.RESOURCE,
            country: simpleBank.country,
            card_value: simpleBank?.card_value,
            label: 'add_build',
          },
          {
            procentX: simpleBank.procentX,
            procentY: simpleBank.procentY,
            type: simpleBank.type,
            typeBuild: ConstructionTypesBuild.RESOURCE,
            country: simpleBank.country,
            card_value: simpleBank?.card_value,
            label: 'add_build',
          },
        ];
      } else {
        const cityPoints = POINTS[country];
        const diffPoints = cityPoints.filter(
          (item) =>
            !constructions?.some(
              (c) =>
                c.country === item.cityId.toString() &&
                c.procentX === item.x &&
                c.procentY === item.y,
            ),
        );
        addBuilds = [
          {
            procentX: diffPoints[0].x,
            procentY: diffPoints[0].y,
            type: ConstructionTypes.BANK,
            typeBuild: ConstructionTypesBuild.RESOURCE,
            country,
            card_value: 'government_resource',
            label: 'add_build',
          },
          {
            procentX: diffPoints[0].x,
            procentY: diffPoints[0].y,
            type: ConstructionTypes.BANK,
            typeBuild: ConstructionTypesBuild.RESOURCE,
            country,
            card_value: 'government_resource',
            label: 'add_build',
          },
        ];
      }
      newConstructions = [...newConstructions, ...addBuilds];
      let promises = [];
      for (let construction of addBuilds) {
        promises.push(
          GameService.addActions(gameId, {
            state: construction,
            type: 'play_institution',
            worldState: undefined,
          }),
        );
        Emitter.emit(EventType.ADD_NEW_BUILD, construction);
      }
      newConstructions = newConstructions.filter((c) => !!c);
      await Promise.all([
        ...promises,
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        ),
      ]);
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    } finally {
      setDetailsModal(false);
      setLocalCard(undefined);
      setOpenNext(false);
      setCityModal(false);
    }
  };

  const callIncreaseMargins = async (country: string) => {
    try {
      let curentIndex = localStorage.getItem('play_institution');
      let newAddBuilds: ConstructionI[] = [];
      if (curentIndex) {
        const { data } = await GameService.getActions(gameId, curentIndex, 5, 0);
        newAddBuilds = (data.items.map((e) => e.state) as ConstructionI[]) || [];
      } else {
        curentIndex = new Date().getTime().toString();
        localStorage.setItem('play_institution', curentIndex);
      }
      let construction: ConstructionI | undefined;
      const simpleMalls = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.INDUSTRY &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      if (simpleMalls?.length) {
        construction = {
          procentX: simpleMalls[0].procentX,
          procentY: simpleMalls[0].procentY,
          type: simpleMalls[0].type,
          typeBuild: simpleMalls[0].typeBuild,
          country: simpleMalls[0].country,
          card_value: simpleMalls[0]?.card_value,
          label: 'add_build',
          userId: meRole.id,
        };
      } else {
        const cityPoints = POINTS[country];
        const countryConstructions = constructions?.filter((c) => c.country === country);
        const diffPoints = cityPoints.filter(
          (item) =>
            !countryConstructions?.some((c) => c.procentX === item.x && c.procentY === item.y),
        );
        construction = {
          procentX: diffPoints[0].x,
          procentY: diffPoints[0].y,
          type: ConstructionTypes.INDUSTRY,
          typeBuild: ConstructionTypesBuild.RESOURCE,
          country,
          card_value: 'consumer_resource',
          label: 'add_build',
          userId: meRole.id,
        };
      }
      if (construction) {
        await GameService.addActions(gameId, {
          state: construction,
          type: curentIndex,
          worldState: undefined,
        });
        newAddBuilds = [...newAddBuilds, construction];
        Emitter.emit(EventType.ADD_NEW_BUILD, construction);
      } else {
        setChangeCityDescription('No building was found');
      }
      if (newAddBuilds.length === 5) {
        let newConstructions: ConstructionI[] | undefined = [
          ...(constructions || []),
          ...newAddBuilds,
        ];
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callSustainableTechAccelerator = async (country: string) => {
    try {
      let industires = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.INDUSTRY &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      let newConstructions: ConstructionI[] | undefined = constructions || [];
      if (industires?.length) {
        industires = industires.slice().sort(() => 0.5 - Math.random());
        const industiry = industires[0];
        await GameService.addActions(gameId, {
          state: { ...industiry, label: 'remove_build' },
          type: 'play_institution',
          worldState: undefined,
        });
        let index = newConstructions.findIndex(
          (r) =>
            r.country === industiry.country &&
            r.type === industiry.type &&
            r.typeBuild === industiry.typeBuild,
        );
        if (index !== -1) {
          delete newConstructions[index];
        }
        newConstructions = newConstructions.filter((c) => !!c);
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const onSubmitBuisiness = () => {
    switch (localCard?.action) {
      case ActionType.BUSINESS_ALTERNATIVE_MEAT_PRODUCTS:
        checkAlternativeMeatProducts();
        break;
      case ActionType.BUSINESS_CLOSED_CLOTHING_PRODUCTION:
      case ActionType.BUSINESS_GREEN_PLASTIC_PRODUCTS:
        changeInstitution({
          ...localCard!,
          log: `played the ${localCard?.name}`,
          currentModifier: localCard?.modifier || 0,
        });
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        break;
      case ActionType.BUSINESS_GREEN_ENERGY:
        callGreenEnergyBusiness();
        break;
      case ActionType.BUSINESS_RECYCLING_PROGRAM:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Select a city to add two government standard structures.');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.BUSINESS_INCREASE_MARGINS:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Select a city in which to build a standard structures');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.BUSINESS_R_D_X:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Remove one factory in a selected city');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      default:
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        break;
    }
  };

  const callMeatAlternativeSubsidies = async (country: string) => {
    try {
      const governmentMeatAlternative = usedCards.some(
        (r) => r === ActionType.CONSUMER_MEAT_ALTERNATIVES,
      );
      const governmentLabGrownMeat = usedCards.some((r) => r === ActionType.SCIENCE_LAB_GROWN_MEAT);
      if (governmentLabGrownMeat && governmentMeatAlternative) {
        changeInstitution({
          ...localCard!,
          log: `played the ${localCard?.name}`,
          currentModifier: 0,
        });
        return;
      }
      let curentIndex = localStorage.getItem('play_institution');
      let newAddBuilds: ConstructionI[] = [];
      if (curentIndex) {
        const { data } = await GameService.getActions(gameId, curentIndex, 5, 0);
        newAddBuilds = (data.items.map((e) => e.state) as ConstructionI[]) || [];
      } else {
        curentIndex = new Date().getTime().toString();
        localStorage.setItem('play_institution', curentIndex);
      }
      const simpleMalls = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.BANK &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      if (simpleMalls?.length) {
        const simpleMall = simpleMalls[0];
        if (simpleMall) {
          await GameService.addActions(gameId, {
            state: { ...simpleMall, label: 'remove_build' },
            type: curentIndex,
            worldState: undefined,
          });
          newAddBuilds.push(simpleMall);
        }
      } else {
        setChangeCityDescription('No building was found');
      }
      if (newAddBuilds.length === 3) {
        let newConstructions: ConstructionI[] | undefined = constructions || [];
        for (let industry of newAddBuilds) {
          let index = newConstructions.findIndex(
            (r) =>
              r?.country === industry.country &&
              r?.type === industry.type &&
              r?.typeBuild === industry.typeBuild,
          );
          if (index !== -1) {
            delete newConstructions[index];
          }
        }
        newConstructions = newConstructions.filter((c) => !!c);
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callNuclearEnergy = async (country: string) => {
    try {
      const banks = constructions?.filter(
        (c) => c.type === ConstructionTypes.BANK && c.country === country,
      );
      let newConstructions: ConstructionI[] | undefined = constructions || [];

      if (banks?.length) {
        let promises = [];
        for (let bank of banks) {
          promises.push(
            GameService.addActions(gameId, {
              state: { ...bank, label: 'remove_build' },
              type: 'play_institution',
              worldState: undefined,
            }),
          );
          let index = newConstructions.findIndex(
            (r) =>
              r?.country === country && r?.type === bank.type && r?.typeBuild === bank.typeBuild,
          );
          if (index !== -1) {
            delete newConstructions[index];
          }
        }
        await Promise.all(promises);
        newConstructions = newConstructions?.filter((c) => !!c);
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callLobbying = async (country: string) => {
    try {
      let curentIndex = localStorage.getItem('play_institution');
      let newAddBuilds: ConstructionI[] = [];
      if (curentIndex) {
        const { data } = await GameService.getActions(gameId, curentIndex, 5, 0);
        newAddBuilds = (data.items.map((e) => e.state) as ConstructionI[]) || [];
      } else {
        curentIndex = new Date().getTime().toString();
        localStorage.setItem('play_institution', curentIndex);
      }
      let construction: ConstructionI | undefined;
      const simpleMalls = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.BANK &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      if (simpleMalls?.length) {
        construction = {
          procentX: simpleMalls[0].procentX,
          procentY: simpleMalls[0].procentY,
          type: simpleMalls[0].type,
          typeBuild: simpleMalls[0].typeBuild,
          country: simpleMalls[0].country,
          card_value: simpleMalls[0]?.card_value,
          label: 'add_build',
          userId: meRole.id,
        };
      } else {
        const cityPoints = POINTS[country];
        const countryConstructions = constructions?.filter((c) => c.country === country);
        const diffPoints = cityPoints.filter(
          (item) =>
            !countryConstructions?.some((c) => c.procentX === item.x && c.procentY === item.y),
        );
        construction = {
          procentX: diffPoints[0].x,
          procentY: diffPoints[0].y,
          type: ConstructionTypes.BANK,
          typeBuild: ConstructionTypesBuild.RESOURCE,
          country,
          card_value: 'consumer_resource',
          label: 'add_build',
          userId: meRole.id,
        };
      }
      if (construction) {
        await GameService.addActions(gameId, {
          state: construction,
          type: curentIndex,
          worldState: undefined,
        });
        newAddBuilds = [...newAddBuilds, construction];
        Emitter.emit(EventType.ADD_NEW_BUILD, construction);
      } else {
        setChangeCityDescription('No building was found');
      }
      if (newAddBuilds.length === 5) {
        let newConstructions: ConstructionI[] | undefined = [
          ...(constructions || []),
          ...newAddBuilds,
        ];
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callClimateResilienceBlueprint = (country: string) => {
    changeInstitution(
      {
        ...localCard!,
        log: `played the ${localCard?.name}`,
        currentModifier: localCard?.modifier || 0,
      },
      [],
      `${country}_government_government_research_`,
    );
    setDetailsModal(false);
    setLocalCard(undefined);
    setOpenNext(false);
    setCityModal(false);
  };

  const callCorporatePollutionTax = async () => {
    try {
      let constructionsGovernment =
        constructions?.filter(
          (c) =>
            c.typeBuild === ConstructionTypesBuild.RESOURCE && c.type === ConstructionTypes.BANK,
        ) || [];
      const constructionsBusiness =
        constructions?.filter(
          (c) =>
            c.typeBuild === ConstructionTypesBuild.RESOURCE &&
            c.type === ConstructionTypes.INDUSTRY,
        ) || [];

      const nrResources = Math.round(constructionsBusiness?.length / 3);
      constructionsGovernment = constructionsGovernment.slice().sort(() => 0.5 - Math.random());
      const constructionGovernment = constructionsGovernment[0];
      let newConstructions: ConstructionI[] | undefined = constructions || [];

      if (constructionGovernment && constructionGovernment.country && newConstructions?.length) {
        let index = newConstructions.findIndex(
          (r) =>
            r?.country === constructionGovernment?.country &&
            r?.type === constructionGovernment?.type &&
            r?.typeBuild === constructionGovernment?.typeBuild,
        );
        if (index !== -1) {
          delete newConstructions[index];
        }

        await GameService.addActions(gameId, {
          state: { ...constructionGovernment, label: 'remove_build' },
          type: 'play_institution',
          worldState: undefined,
        });
      }
      changeInstitution(
        {
          ...localCard!,
          log: `played the ${localCard?.name}`,
          currentModifier: localCard?.modifier || 0,
        },
        newConstructions.filter((c) => !!c),
        undefined,
        [],
        nrResources,
      );
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    } finally {
      setCityModal(false);
      setDetailsModal(false);
      setLocalCard(undefined);
      setOpenNext(false);
    }
  };

  const onSubmitGovernment = () => {
    switch (localCard?.action) {
      case ActionType.GOVERNMENT_CORPORATE_POLLUTION_TAX:
        callCorporatePollutionTax();
        break;
      case ActionType.GOVERNMENT_MEAT_ALTERNATIVE_SUBSIDIES:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Closes three standard structures from any city');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.GOVERNMENT_NUCLEAR_ENERGY:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Closes three standard structures from any city');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.GOVERNMENT_RECYCLING_PROGRAM:
        changeInstitution({
          ...localCard!,
          log: `played the ${localCard?.name}`,
          currentModifier: localCard?.modifier || 0,
        });
        setCityModal(false);
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        break;
      case ActionType.GOVERNMENT_ROAD_INFRASTRUCTURE_UPDATE:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Select a city to remove all government infrastructure.');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.GOVERNMENT_LOBBYING:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Select a citiiies to add 5 government building.');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.GOVERNMENT_GOVERNMENT_RESEARCH:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Select a city immune to disaster effects for next three turns');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      default:
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        break;
    }
  };

  const callCarbonCapture = async (country: string) => {
    try {
      let laboratories =
        constructions?.filter(
          (c) =>
            c.typeBuild === ConstructionTypesBuild.RESOURCE &&
            c.type === ConstructionTypes.CHEMISTRY &&
            c.country === country,
        ) || [];

      let newConstructions: ConstructionI[] | undefined = constructions || [];
      if (laboratories?.length) {
        const laboratory = laboratories[0];
        let index = newConstructions.findIndex(
          (r) =>
            r.country === laboratory.country &&
            r.type === laboratory.type &&
            r.typeBuild === laboratory.typeBuild,
        );
        if (index !== -1) {
          delete newConstructions[index];
        }
        await GameService.addActions(gameId, {
          state: { ...laboratory, label: 'remove_build' },
          type: 'play_institution',
          worldState: undefined,
        });
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions.filter((c) => !!c),
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callScienceNuclearEnergy = async () => {
    try {
      let laboratories = constructions?.filter((c) => c.type === ConstructionTypes.CHEMISTRY) || [];
      laboratories = laboratories.slice().sort(() => 0.5 - Math.random());
      const laboratory = laboratories[0];
      let newConstructions: ConstructionI[] | undefined = constructions || [];

      if (laboratory) {
        let index = newConstructions.findIndex(
          (r) =>
            r.country === laboratory.country &&
            r.type === laboratory.type &&
            r.typeBuild === laboratory.typeBuild,
        );
        if (index !== -1) {
          delete newConstructions[index];
        }
        const id = `point_${laboratory.type}_${laboratory.typeBuild}_${laboratory.country || '1'}`;
        removeBuild(id);
        await GameService.addActions(gameId, {
          state: { ...laboratory, label: 'remove_build' },
          type: 'play_institution',
          worldState: undefined,
        });
      }

      changeInstitution(
        {
          ...localCard!,
          log: `played the ${localCard?.name}`,
          currentModifier: localCard?.modifier || 0,
        },
        newConstructions.filter((c) => !!c),
      );
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    } finally {
      setCityModal(false);
      setDetailsModal(false);
      setLocalCard(undefined);
      setOpenNext(false);
    }
  };

  const callPlantBasedPlastic = async (country: string) => {
    try {
      const usedCardGreenPlastic = usedCards.some(
        (r) => r === ActionType.BUSINESS_GREEN_PLASTIC_PRODUCTS,
      );
      let laboratories =
        constructions?.filter(
          (c) =>
            c.typeBuild === ConstructionTypesBuild.RESOURCE &&
            c.type === ConstructionTypes.CHEMISTRY &&
            c.country === country,
        ) || [];
      let newConstructions: ConstructionI[] | undefined = constructions?.filter((c) => !!c) || [];
      let promises = [];
      if (usedCardGreenPlastic) {
        for (let laboratory of laboratories) {
          Emitter.emit(EventType.ADD_NEW_BUILD, { ...laboratory, label: 'add_build' });
          promises.push(
            GameService.addActions(gameId, {
              state: { ...laboratory, label: 'add_build' },
              type: 'play_institution',
              worldState: undefined,
            }),
          );
          newConstructions.push({ ...laboratory, label: 'add_build' });
        }
      } else {
        const laboratory = laboratories[0];
        if (laboratory) {
          const id = `point_${laboratory.type}_${laboratory.typeBuild}_${
            laboratory.country || '1'
          }`;
          removeBuild(id);
          promises.push(
            GameService.addActions(gameId, {
              state: { ...laboratory, label: 'remove_build' },
              type: 'play_institution',
              worldState: undefined,
            }),
          );
          let index = newConstructions.findIndex(
            (r) =>
              r?.country === laboratory?.country &&
              r?.type === laboratory?.type &&
              r?.typeBuild === laboratory?.typeBuild,
          );
          if (index !== -1) {
            delete newConstructions[index];
          }
        }
      }

      if (promises?.length) {
        await Promise.all(promises);
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions.filter((c) => !!c),
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callCoolPavements = async (country: string) => {
    try {
      const usedInfrastructureCard = usedCards.some(
        (r) => r === ActionType.GOVERNMENT_ROAD_INFRASTRUCTURE_UPDATE,
      );
      let laboratories =
        constructions?.filter(
          (c) =>
            c.typeBuild === ConstructionTypesBuild.RESOURCE &&
            c.type === ConstructionTypes.CHEMISTRY &&
            c.country === country,
        ) || [];
      let newConstructions: ConstructionI[] | undefined = constructions?.filter((c) => !!c) || [];
      let promises = [];
      if (usedInfrastructureCard) {
        for (let laboratory of laboratories) {
          Emitter.emit(EventType.ADD_NEW_BUILD, { ...laboratory, label: 'add_build' });
          promises.push(
            GameService.addActions(gameId, {
              state: { ...laboratory, label: 'add_build' },
              type: 'play_institution',
              worldState: undefined,
            }),
          );
          newConstructions.push({ ...laboratory, label: 'add_build' });
        }
      } else {
        const laboratory = laboratories[0];
        if (laboratory) {
          const id = `point_${laboratory.type}_${laboratory.typeBuild}_${
            laboratory.country || '1'
          }`;
          removeBuild(id);
          promises.push(
            GameService.addActions(gameId, {
              state: { ...laboratory, label: 'remove_build' },
              type: 'play_institution',
              worldState: undefined,
            }),
          );
          let index = newConstructions.findIndex(
            (r) =>
              r?.country === laboratory?.country &&
              r?.type === laboratory?.type &&
              r?.typeBuild === laboratory?.typeBuild,
          );
          if (index !== -1) {
            delete newConstructions[index];
          }
        }
      }

      if (promises?.length) {
        await Promise.all(promises);
        changeInstitution(
          {
            ...localCard!,
            log: `played the ${localCard?.name}`,
            currentModifier: localCard?.modifier || 0,
          },
          newConstructions.filter((c) => !!c),
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callRequestFunding = async (country: string) => {
    try {
      let laboratories =
        constructions?.filter(
          (c) =>
            c.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
            c.type === ConstructionTypes.CHEMISTRY &&
            c.country === country,
        ) || [];
      let newConstructions: ConstructionI[] | undefined = constructions?.filter((c) => !!c) || [];
      let laboratory = laboratories[0];
      if (laboratory) {
        laboratory = { ...laboratory, label: 'add_build' };
      } else {
        const cityPoints = POINTS[country];
        const countryConstructions = constructions?.filter((c) => c.country === country);
        const diffPoints = cityPoints.filter(
          (item) =>
            !countryConstructions?.some((c) => c.procentX === item.x && c.procentY === item.y),
        );
        laboratory = {
          procentX: diffPoints[0].x,
          procentY: diffPoints[0].y,
          type: ConstructionTypes.CHEMISTRY,
          typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
          country,
          card_value: 'science_green_resource',
          label: 'add_build',
          userId: meRole.id,
        };
      }
      Emitter.emit(EventType.ADD_NEW_BUILD, { ...laboratory, label: 'add_build' });
      await GameService.addActions(gameId, {
        state: { ...laboratory, label: 'add_build' },
        type: 'play_institution',
        worldState: undefined,
      });
      newConstructions.push({ ...laboratory, label: 'add_build' });
      changeInstitution(
        {
          ...localCard!,
          log: `played the ${localCard?.name}`,
          currentModifier: localCard?.modifier || 0,
        },
        newConstructions.filter((c) => !!c),
      );
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    } finally {
      setCityModal(false);
      setDetailsModal(false);
      setLocalCard(undefined);
      setOpenNext(false);
    }
  };

  const onSubmitScience = () => {
    switch (localCard?.action) {
      case ActionType.SCIENCE_CARBON_CAPTURE:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Get rid of 1 standard laboratory.');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.SCIENCE_LAB_GROWN_MEAT:
      case ActionType.SCIENCE_MACHINE_SORTED_RECYCLE:
        changeInstitution({
          ...localCard!,
          log: `played the ${localCard?.name}`,
          currentModifier: localCard?.modifier || 0,
        });
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        setCityModal(false);
        break;
      case ActionType.SCIENCE_NUCLEAR_ENERGY:
        callScienceNuclearEnergy();
        break;
      case ActionType.SCIENCE_PLANT_BASED_PLASTIC:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        const usedCardGreenPlastic = usedCards.some(
          (r) => r === ActionType.BUSINESS_GREEN_PLASTIC_PRODUCTS,
        );
        setShowPinTitle(
          usedCardGreenPlastic
            ? 'Double the amount of non-green science structures in one city.'
            : 'Remove once science structure from the map.',
        );
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.SCIENCE_COOL_PAVEMENTS:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        const usedInfrastructureCard = usedCards.some(
          (r) => r === ActionType.GOVERNMENT_ROAD_INFRASTRUCTURE_UPDATE,
        );
        setShowPinTitle(
          usedInfrastructureCard
            ? 'Double the amount of non-green science structures in one city.'
            : 'Remove once science structure from the map.',
        );
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.SCIENCE_REQUEST_FUNDING_FROM_DONORS:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setShowPinTitle('Select a city to build one green structure');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      default:
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        break;
    }
  };

  const onSubmit = () => {
    switch (meRole?.role) {
      case 'consumer':
        onSubmitConsumer();
        break;
      case 'business':
        onSubmitBuisiness();
        break;
      case 'environmentalActivist':
        onSubmitEnvironmentalActivist();
        break;
      case 'government':
        onSubmitGovernment();
        break;
      case 'science':
        onSubmitScience();
        break;
      default:
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        break;
    }
  };

  const closeChosePlayer = () => {
    setPlayerModal(false);
    setLocalActionCard(undefined);
  };

  const submitSuperPower = () => {
    setDetailsActionModal(false);
    selectAction(localActionCard);
    setLocalActionCard(undefined);
    setOpenNext(false);
  };

  const takeFiveCars = () => {
    setDetailsActionModal(false);
    setPlayerModal(true);
  };

  const selectPlayer = (player: RoleInterface) => {
    setPlayerModal(false);
    switch (localActionCard?.action) {
      case ActionType.TAKE_BOTTOM_FIVE_CARDS:
        selectAction({
          ...localActionCard!,
          log: `used Advanced Search Algorithm power on ${player.name}`,
        });
        setLocalActionCard(undefined);
        setSelectedPlayer(undefined);
        setOpenNext(false);
        break;
      case ActionType.ONE_GREEN_CARD_FOR_TWO:
        setShowPinTitle(
          'Next round you can build green structures using only one green resource card.',
        );
        setSelectedPlayer(player);
        setSelectCityModal(true);
        break;
      case ActionType.FIVE_GREEN_RESOURCE_CARDS_TO_TOP:
        setSelectedPlayer(player);
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setDetailsActionModal(false);
        setShowPinTitle(
          'Select a non-green conservation to flip into a green conservation using one green resource card instead of two.',
        );
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.PLACE_UP_TO_FIVE_STRUCTURE_RESOURCE_CARDS:
        selectAction({
          ...localActionCard!,
          use_power: `${player?.roleDb}_consumer_more_goods_5`,
          log: `played More Goods power on ${player?.name}`,
        });
        setLocalActionCard(undefined);
        setSelectedPlayer(undefined);
        setOpenNext(false);
        break;
      default:
        setDetailsModal(false);
        setLocalCard(undefined);
        setOpenNext(false);
        break;
    }
  };

  const successSelectCity = () => {
    setSelectCityModal(false);
    setShowPinTitle('');
    switch (localActionCard?.action) {
      case ActionType.ONE_GREEN_CARD_FOR_TWO:
        selectAction({
          ...localActionCard!,
          use_power: `${selectedPlayer?.roleDb}_environmental_activist_ecological_education_${
            rounds + 1
          }`,
          log: `used Ecological Education power on ${selectedPlayer?.name}`,
        });
        setLocalActionCard(undefined);
        setSelectedPlayer(undefined);
        setOpenNext(false);
        break;
      case ActionType.BUILDINGS_CONVERT_TO_GOVERNMENT_AND_CONSUMER:
        Emitter.emit(EventType.CHANGE_SELECT_CITY, localActionCard);
        setLocalActionCard(undefined);
        setOpenNext(false);
        break;
      case ActionType.DOWN_ALL_INFRASTRUCTURE:
        Emitter.emit(EventType.CHANGE_SELECT_CITY, localActionCard);
        setLocalActionCard(undefined);
        setOpenNext(false);
        break;
      case ActionType.RESTORE_INFRASTRUCTURE_IN_THREE_CITIES:
        Emitter.emit(EventType.CHANGE_SELECT_CITY, localActionCard);
        setLocalActionCard(undefined);
        setOpenNext(false);
        break;
      case ActionType.MAKE_ALL_NON_GREEN_WITHOUT_GOVERNMENT:
        Emitter.emit(EventType.CHANGE_SELECT_CITY, localActionCard);
        setLocalActionCard(undefined);
        setOpenNext(false);
        break;
      default:
    }
  };

  const callGreenResourceSearch = async (country: string) => {
    try {
      const conservations = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.USER &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      const greenConservations = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.USER &&
          c.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
          c.country === country,
      );

      if (conservations?.length) {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, false);
        let greenConservation = greenConservations?.length ? greenConservations[0] : undefined;
        if (!greenConservation) {
          const cityPoints = POINTS[country];
          const countryConstructions = constructions?.filter((c) => c.country === country);
          const diffPoints = cityPoints.filter(
            (item) =>
              !countryConstructions?.some((c) => c.procentX === item.x && c.procentY === item.y),
          );
          greenConservation = {
            procentX: diffPoints[0].x,
            procentY: diffPoints[0].y,
            type: ConstructionTypes.USER,
            typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
            country,
            card_value: 'government_green_resource',
            label: 'add_build',
            userId: meRole.id,
          };
        }
        const conservation = conservations[0];
        let newConstructions: ConstructionI[] | undefined = constructions || [];
        let index = newConstructions.findIndex(
          (r) =>
            r?.country === conservation.country &&
            r?.type === conservation.type &&
            r?.typeBuild === conservation.typeBuild,
        );

        if (index !== -1) {
          delete newConstructions[index];
        }
        await GameService.addActions(gameId, {
          state: { ...conservation, label: 'remove_build' },
          type: 'play_institution',
          worldState: undefined,
        });
        const id = `point_${conservation.type}_${conservation.typeBuild}_${
          conservation.country || '1'
        }`;
        removeBuild(id);
        await GameService.addActions(gameId, {
          state: greenConservation,
          type: 'play_institution',
          worldState: undefined,
        });
        Emitter.emit(EventType.ADD_NEW_BUILD, greenConservation);
        newConstructions = [...newConstructions, greenConservation];
        newConstructions = newConstructions.filter((c) => !!c);

        changeSuperPower(
          {
            ...localActionCard!,
            log: `played Green Resource Search power on ${selectedPlayer?.name}`,
          },
          newConstructions,
          'environmentalActivist',
          `${selectedPlayer?.roleDb}_five_green_resource_cards_to_top_5`,
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No non-green building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callGreenConsumerism = async (country: string) => {
    try {
      const malls = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.STORE &&
          c.typeBuild === ConstructionTypesBuild.RESOURCE &&
          c.country === country,
      );
      const greenMalls = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.STORE &&
          c.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
          c.country === country,
      );

      if (malls?.length) {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, false);
        let greenMall = greenMalls?.length ? greenMalls[0] : undefined;
        if (!greenMall) {
          const cityPoints = POINTS[country];
          const countryConstructions = constructions?.filter((c) => c.country === country);
          const diffPoints = cityPoints.filter(
            (item) =>
              !countryConstructions?.some((c) => c.procentX === item.x && c.procentY === item.y),
          );
          greenMall = {
            procentX: diffPoints[0].x,
            procentY: diffPoints[0].y,
            type: ConstructionTypes.STORE,
            typeBuild: ConstructionTypesBuild.GREEN_RESOURCE,
            country,
            card_value: 'government_green_resource',
            label: 'add_build',
            userId: meRole.id,
          };
        }
        const mall = malls[0];
        let newConstructions: ConstructionI[] | undefined = constructions || [];
        let index = newConstructions.findIndex(
          (r) =>
            r?.country === mall.country && r?.type === mall.type && r?.typeBuild === mall.typeBuild,
        );

        if (index !== -1) {
          delete newConstructions[index];
        }
        await GameService.addActions(gameId, {
          state: { ...mall, label: 'remove_build' },
          type: 'play_institution',
          worldState: undefined,
        });
        const id = `point_${mall.type}_${mall.typeBuild}_${mall.country || '1'}`;
        removeBuild(id);
        await GameService.addActions(gameId, {
          state: greenMall,
          type: 'play_institution',
          worldState: undefined,
        });
        Emitter.emit(EventType.ADD_NEW_BUILD, greenMall);
        newConstructions = [...newConstructions, greenMall];
        newConstructions = newConstructions.filter((c) => !!c);

        changeSuperPower(
          {
            ...localActionCard!,
            log: 'played Green Consumerism power',
          },
          newConstructions,
          'consumer',
        );
        finishUseCard();
      } else {
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        setChangeCityDescription('No non-green building was found');
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
      finishUseCard();
    }
  };

  const callInstitutionCard = useCallback(
    () => {
      const currentAction =
        localCard && localCard?.action ? localCard?.action : localActionCard?.action;
      setConfirmSelectCity(false);
      setChangeCityDescription('');
      switch (currentAction) {
        case ActionType.CONSUMER_SMALL_BUSINESSES:
          callLocalGreenMalls(selectedCountry);
          break;
        case ActionType.CONSUMER_CHEAPER_GOODS:
          callCheaperGoods(selectedCountry);
          break;
        case ActionType.CONSUMER_SUSTAINABLE_PLASTIC:
          callSustainablePlastic(selectedCountry);
          break;
        case ActionType.CONSUMER_CLOSED_CLOTHING_PRODUCTION:
          callClothingProduction(selectedCountry);
          break;
        case ActionType.GOVERNMENT_MEAT_ALTERNATIVE_SUBSIDIES:
          callMeatAlternativeSubsidies(selectedCountry);
          break;
        case ActionType.GOVERNMENT_NUCLEAR_ENERGY:
          callNuclearEnergy(selectedCountry);
          break;
        case ActionType.GOVERNMENT_GOVERNMENT_RESEARCH:
          callClimateResilienceBlueprint(selectedCountry);
          break;
        case ActionType.GOVERNMENT_ROAD_INFRASTRUCTURE_UPDATE:
          callNuclearEnergy(selectedCountry);
          break;
        case ActionType.GOVERNMENT_LOBBYING:
          callLobbying(selectedCountry);
          break;
        case ActionType.BUSINESS_ALTERNATIVE_MEAT_PRODUCTS:
          callAlternativeMeatProducts(selectedCountry);
          break;
        case ActionType.BUSINESS_RECYCLING_PROGRAM:
          callRecyclingProgram(selectedCountry);
          break;
        case ActionType.BUSINESS_INCREASE_MARGINS:
          callIncreaseMargins(selectedCountry);
          break;
        case ActionType.BUSINESS_R_D_X:
          callSustainableTechAccelerator(selectedCountry);
          break;
        case ActionType.ENVIRONMENTAL_ACTIVIST_ANIMAL_AGRICULTURE:
          callAnimalAgriculture(selectedCountry);
          break;
        case ActionType.ENVIRONMENTAL_ACTIVIST_DEFORESTATION:
          callDeforestation(selectedCountry);
          break;
        case ActionType.ENVIRONMENTAL_ACTIVIST_FOSSIL_FUELS_FRACKING:
          callCleanEnergyNow(selectedCountry);
          break;
        case ActionType.ENVIRONMENTAL_ACTIVIST_MASS_RALLY:
          callGlobalClimateMarch(selectedCountry);
          break;
        case ActionType.ENVIRONMENTAL_ACTIVIST_DONATIONS_FOR_CONSERVATION:
          callWildlifeRestorationFundraiser(selectedCountry);
          break;
        case ActionType.SCIENCE_CARBON_CAPTURE:
          callCarbonCapture(selectedCountry);
          break;
        case ActionType.SCIENCE_PLANT_BASED_PLASTIC:
          callPlantBasedPlastic(selectedCountry);
          break;
        case ActionType.SCIENCE_REQUEST_FUNDING_FROM_DONORS:
          callRequestFunding(selectedCountry);
          break;
        case ActionType.GOVERNMENT_NON_GREEN_STRUCTURE_TO_GREEN:
          callGreenMandate(selectedCountry);
          break;
        case ActionType.FIVE_GREEN_RESOURCE_CARDS_TO_TOP:
          callGreenResourceSearch(selectedCountry);
          break;
        case ActionType.MAKE_ONE_YOUR_BUILD_TO_GREEN:
          callGreenConsumerism(selectedCountry);
          break;
        case ActionType.SCIENCE_COOL_PAVEMENTS:
          callCoolPavements(selectedCountry);
          break;
        default:
          setDetailsModal(false);
          setLocalCard(undefined);
          setOpenNext(false);
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localCard, selectedCountry],
  );

  const checkCallInstitutionCard = useCallback((country: string) => {
    setSelectCountry(country);
    setConfirmSelectCity(true);
  }, []);

  useEffect(() => {
    Emitter.on(EventType.REVERT_SELECT_CITY_CARD, (newValue) => {
      checkCallInstitutionCard(newValue);
    });

    return () => {
      Emitter.off(EventType.REVERT_SELECT_CITY_CARD);
    };
  }, [checkCallInstitutionCard]);

  const downAllInfrastructure = () => {
    setShowPinTitle(
      'Select a city to shut down all the green and non-green structures for one round.',
    );
    setDetailsActionModal(false);
    setSelectCityModal(true);
  };

  const onSubmitAction = () => {
    switch (localActionCard?.action) {
      case ActionType.TAKE_BOTTOM_FIVE_CARDS:
        takeFiveCars();
        break;
      case ActionType.BUILD_GREEN_STRUCTURE_OR_TWO_NON_GREEN_STRUCTURE:
        setDetailsActionModal(false);
        selectAction({
          ...localActionCard!,
          log: `used Research Grant power`,
        });
        setLocalActionCard(undefined);
        setOpenNext(false);
        break;
      case ActionType.GOVERNMENT_NON_GREEN_STRUCTURE_TO_GREEN:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setDetailsActionModal(false);
        setShowPinTitle(
          'Select a non-green District to flip into a green District using one green resource card instead of two.',
        );
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.MAKE_ONE_YOUR_BUILD_TO_GREEN:
        setDetailsModal(false);
        setCityModal(true);
        setOpenNext(false);
        setDetailsActionModal(false);
        setShowPinTitle('Select a Mall to convert into a Green Mall');
        Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, true);
        break;
      case ActionType.BUILDINGS_CONVERT_TO_GOVERNMENT_AND_CONSUMER:
        setShowPinTitle(
          'Select a city you want to make residential-only for the next 3 turns and convert all the structures into government and consumer structures.',
        );
        setDetailsActionModal(false);
        setSelectCityModal(true);
        break;
      case ActionType.FIVE_GREEN_RESOURCE_CARDS_TO_TOP:
        setPlayerModal(true);
        break;
      case ActionType.DOWN_ALL_INFRASTRUCTURE:
        downAllInfrastructure();
        break;
      case ActionType.ONE_GREEN_CARD_FOR_TWO:
        takeFiveCars();
        break;
      case ActionType.PLACE_UP_TO_FIVE_STRUCTURE_RESOURCE_CARDS:
        takeFiveCars();
        break;
      case ActionType.RESTORE_INFRASTRUCTURE_IN_THREE_CITIES:
        setShowPinTitle('Select a city to protect against the next disaster.');
        setDetailsActionModal(false);
        setSelectCityModal(true);
        break;
      case ActionType.MAKE_ALL_NON_GREEN_WITHOUT_GOVERNMENT:
        setShowPinTitle('Select a city');
        setDetailsActionModal(false);
        setSelectCityModal(true);
        break;
      case ActionType.REDUCING_EMISSIONS_PRODUCED:
        setDetailsActionModal(false);
        selectAction({
          ...localActionCard!,
          log: `played Investment in Sustainability power`,
        });
        setLocalActionCard(undefined);
        setOpenNext(false);
        break;
      default:
        submitSuperPower();
    }
  };

  const checkSelectNewBuild = (action: StructureInterface) => {
    selectBuild(action);
    selectAction(undefined);
    setOpenNext(false);
  };

  const checkSelectAction = (action: StructureInterface) => {
    if (action.action === ActionType.GOVERNMENT_NON_GREEN_STRUCTURE_TO_GREEN) {
      const bankConstructions = constructions?.filter(
        (c) => c.type === ConstructionTypes.BANK && c.typeBuild === ConstructionTypesBuild.RESOURCE,
      );
      const greenCards = currentCards.filter(
        (c) =>
          c.type === ConstructionTypes.BANK &&
          c.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE,
      );
      const disableSuperPower = !(
        bankConstructions &&
        bankConstructions?.length > 0 &&
        greenCards &&
        greenCards?.length > 0
      );
      setDisabledSuperPowerButton(disableSuperPower);
    }
    if (action.action === ActionType.MAKE_ONE_YOUR_BUILD_TO_GREEN) {
      const mallConstructions = constructions?.filter(
        (c) =>
          c.type === ConstructionTypes.STORE && c.typeBuild === ConstructionTypesBuild.RESOURCE,
      );
      const disableSuperPower = !(mallConstructions && mallConstructions?.length > 0);
      setDisabledSuperPowerButton(disableSuperPower);
    }
    if (action.action === ActionType.FIVE_GREEN_RESOURCE_CARDS_TO_TOP) {
      const conservationConstructions = constructions?.filter(
        (c) => c.type === ConstructionTypes.USER && c.typeBuild === ConstructionTypesBuild.RESOURCE,
      );
      const greenConservationCards = currentCards.filter(
        (c) =>
          c.type === ConstructionTypes.USER &&
          c.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE,
      );
      const disableSuperPower = !(
        conservationConstructions &&
        conservationConstructions?.length > 0 &&
        greenConservationCards &&
        greenConservationCards?.length > 0
      );
      setDisabledSuperPowerButton(disableSuperPower);
    }
    setLocalActionCard(action);
    selectBuild(undefined);
    setDetailsActionModal(true);
  };

  const closeActionDetails = () => {
    setDetailsActionModal(false);
    setLocalActionCard(undefined);
    setDisabledSuperPowerButton(false);
  };

  const closeSelectCity = () => {
    switch (localCard?.action) {
      case ActionType.CONSUMER_CHEAPER_GOODS:
      case ActionType.GOVERNMENT_LOBBYING:
      case ActionType.BUSINESS_INCREASE_MARGINS:
        removeAllBuilds();
        break;
      case ActionType.GOVERNMENT_MEAT_ALTERNATIVE_SUBSIDIES:
        revertRemovedBuild();
        break;
      default:
        localStorage.removeItem('play_institution');
    }
    setCityModal(false);
    setSelectCityModal(false);
    setLocalActionCard(undefined);
    setLocalCard(undefined);
    setShowPinTitle('');
    setChangeCityDescription('');
    setOpenNext(false);
    setDetailsActionModal(false);
    Emitter.emit(EventType.CHANGE_SELECT_CITY_CARD, false);
  };

  useEffect(() => {
    return () => {
      finishUseCard();
    };
  }, []);

  return (
    <div className="flex flex-row">
      <div className="flex flex-col gap-y-2 w-56 absolute top-4 left-0">
        {localPlayers?.map((p, i) => {
          return (
            <div
              key={`${p.name}_${i}`}
              className={classNames(
                'bg-primrose rounded-e-lg py-2 pl-2 pr-3 flex flex-row gap-x-2 shadow-player-block',
                {
                  'bg-gradient-to-r from-primrose to-salomie': p.id !== currentActor,
                  'bg-gradient-to-r from-primrose to-cream-can': p.id === currentActor,
                },
              )}
            >
              <div
                className={classNames(
                  'border border-solid rounded-md p-2 h-11 w-11 flex flex-row justify-center items-center',
                  {
                    'bg-potters-clay-70': p.id === currentActor,
                    'bg-white-30': p.id !== currentActor,
                    'border-gape-palliser': p.id !== currentActor,
                    'border-potters-clay': p.id === currentActor,
                  },
                )}
              >
                <p.gameIcon fill={p.id === currentActor ? '#ffffff' : '#6F4518'} />
              </div>
              <div className="flex flex-col">
                <div>
                  <p className="m-0 text-xs font-bold text-black">
                    {p.name}
                    {user?.id === p.id ? '(You)' : ''}
                  </p>
                </div>
                <div>
                  <p className="m-0 text-xs font-normal text-black">{p.role}</p>
                </div>
                <div className="mt-1">
                  {p.id === currentActor ? (
                    <div className="px-1 py-[1px] bg-forest-green rounded-sm flex flex-row w-max">
                      <p className="m-0 text-white text-[8px] uppercase leading-4 font-medium">
                        Your turn
                      </p>
                    </div>
                  ) : (
                    <div className="px-1 py-[1px] bg-transparent rounded-sm flex flex-row w-max border border-solid border-tundora">
                      <p className="m-0 text-tundora text-[8px] uppercase leading-4 font-medium">
                        Awaiting
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col ml-4 absolute top-4 left-56">
        <div className="bg-onahau-90 flex flex-row rounded-lg bg-blend-soft-light backdrop-blur-sm px-4 py-3 w-[calc(100vw-500px)] max-w-[900px]">
          <div className="flex w-full flex-col justify-center border-0 border-r border-solid border-white-70 pr-8">
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row justify-start items-center">
                <Smoke />
                <p className="m-0 text-lg font-medium text-black ml-3">Emission</p>
              </div>
              <p className="m-0 text-xl font-normal text-eastern_blue">{emission}</p>
            </div>
            <div className="bg-bar h-[18px] w-full rounded-3xl mt-3 relative">
              <div className={`absolute flex -top-1`} style={{ marginLeft: `${emissionPrecent}%` }}>
                <Bar />
              </div>
            </div>
          </div>
          <div className="flex flex-col pl-8">
            <div className="flex flex-row justify-start items-center">
              <Carbon />
              <p className="m-0 text-lg font-medium text-black ml-3">Modifier</p>
            </div>
            <div className="flex flex-row justify-start items-center mt-2">
              <p className="m-0 text-3xl font-normal text-eastern_blue">{modifier}</p>
            </div>
          </div>
        </div>

        {currentActor === user?.id ? (
          <>
            <div
              className={classNames(
                'flex flex-row justify-center items-center absolute h-5 w-[calc(100vw-500px)] max-w-[900px]',
                {
                  '-bottom-5': openedNext,
                  'top-[96px]': !openedNext,
                },
              )}
            >
              <div className="bg-onahau-97 bg-blend-soft-light rounded-b-lg px-6 h-full">
                <button
                  className="flex flex-row bg-transparent outline-none border-0 cursor-pointer"
                  onClick={() => setOpenNext(!openedNext)}
                >
                  <div
                    className={classNames('transition-all ease-linear', {
                      'rotate-180': !openedNext,
                    })}
                  >
                    <ChevronUp />
                  </div>
                </button>
              </div>
            </div>
            <div
              className={classNames(
                'bg-white-85 backdrop-blur-sm flex flex-col rounded-lg p-3 mt-4 w-[calc(100vw-500px)] max-w-[900px] transition-all ease-linear',
                {
                  'h-auto': openedNext,
                  'h-0 hidden': !openedNext,
                },
              )}
            >
              <div className="flex flex-row justify-between w-full">
                <div className="outline-none border-0 bg-transparent flex flex-row justify-center items-center">
                  <p className="m-0 font-medium text-black text-base">Next step:</p>
                </div>
                <button
                  className={classNames(
                    'outline-none border-0 bg-transparent flex flex-row justify-center items-center',
                    {
                      'cursor-pointer': currentActor === user?.id,
                      'cursor-not-allowed': currentActor !== user?.id,
                    },
                  )}
                  onClick={nextActor}
                  disabled={currentActor !== user?.id}
                >
                  <p className="m-0 font-normal text-black-80 text-base mr-1">Skip</p>
                  <Next fill="#4D4D4D" size={20} />
                </button>
              </div>
              <div className="flex flex-row w-full gap-x-2 mt-6">
                {localBuilds?.map((structure, index) => {
                  return (
                    <button
                      key={`${structure.name}_${index}`}
                      disabled={currentActor !== user?.id}
                      className={classNames(
                        'outline-none border-0 hover:bg-forest-green-80 flex flex-row justify-center items-center cursor-pointer rounded-sm px-3 py-2',
                        {
                          'bg-forest-green-80': structure.name === localActionCard?.name,
                          'bg-forest-green': structure.name !== localActionCard?.name,
                          'cursor-pointer': currentActor === user?.id,
                          'cursor-not-allowed': currentActor !== user?.id,
                        },
                      )}
                      onClick={() => checkSelectNewBuild(structure)}
                    >
                      <p className="m-0 font-normal text-white text-sm">{structure.name}</p>
                    </button>
                  );
                })}
                {actions?.map((structure, index) => {
                  return (
                    <button
                      key={`${structure.name}_${index}`}
                      disabled={currentActor !== user?.id || disabledPower}
                      className={classNames(
                        'outline-none border-0 flex flex-row justify-center items-center cursor-pointer rounded-sm px-3 py-2',
                        {
                          'bg-forest-green-80': structure.name === localActionCard?.name,
                          'bg-forest-green': structure.name !== localActionCard?.name,
                          'cursor-pointer': currentActor === user?.id && !disabledPower,
                          'cursor-not-allowed': currentActor !== user?.id || disabledPower,
                          'opacity-50': disabledPower,
                          'hover:bg-forest-green-80': !disabledPower,
                        },
                      )}
                      onClick={() => checkSelectAction(structure)}
                    >
                      <p className="m-0 font-normal text-white text-sm">{structure.name}</p>
                    </button>
                  );
                })}
              </div>
              {institutions?.length ? (
                <div className="flex flex-col w-full gap-x-2 mt-4 bg-hint_of_green border border-solid border-forest-green rounded px-4 pb-3 pt-2">
                  <div
                    className="flex flex-row justify-between items-center cursor-pointer"
                    onClick={() => setOpen(!opened)}
                  >
                    <p className="m-0 text-sm leading-4 text-black font-medium">Institutions</p>
                    <button className="flex flex-row bg-transparent outline-none border-0">
                      <div
                        className={classNames('transition-all ease-linear', {
                          'rotate-180': !opened,
                        })}
                      >
                        <ChevronUp />
                      </div>
                    </button>
                  </div>
                  <div
                    className={classNames(
                      'flex flex-row flex-wrap justify-start mt-4 gap-2 max-w-full transition-all ease-linear',
                      classNames({
                        'h-auto': opened,
                        'h-0 hidden': !opened,
                      }),
                    )}
                  >
                    {institutions.map((institution, key) => {
                      return (
                        <button
                          key={`${institution.name}_${key}`}
                          className={classNames(
                            'rounded-sm border bg-aero_blue border-solid border-forest-green px-3 py-2 outline-none cursor-pointer',
                            {
                              'bg-forest-green': institution?.card_value === localCard?.card_value,
                              'bg-aero_blue': institution?.card_value !== localCard?.card_value,
                            },
                          )}
                          onClick={() => showDetails(institution)}
                        >
                          <p
                            className={classNames('m-0 text-sm leading-4 font-normal', {
                              'text-white': institution?.card_value === localCard?.card_value,
                              'text-black': institution?.card_value !== localCard?.card_value,
                            })}
                          >
                            {institution.name}
                          </p>
                        </button>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
      {detailsModal && localCard ? (
        <InstitutionCard
          isOpen={detailsModal}
          onClose={closeProductDetails}
          institutionCard={localCard}
          onSubmit={onSubmit}
          disabled={disabledPlayButton}
          effectIsNotMet={effectIsNotMet}
          requirementIsNotMet={requirementIsNotMet}
          costIsNotMet={costIsNotMet}
        />
      ) : null}
      {detailsActionModal && localActionCard ? (
        <ActionCard
          isOpen={detailsActionModal}
          onClose={closeActionDetails}
          actionCard={localActionCard}
          onSubmit={onSubmitAction}
          disablePlay={disabledSuperPowerButton}
        />
      ) : null}
      {playerModal ? (
        <SelectPlayer
          isOpen={playerModal}
          onClose={closeChosePlayer}
          onSubmit={selectPlayer}
          players={localPlayers.filter((r) => r.id !== user?.id)}
        />
      ) : null}
      {showSelectCity ? (
        <ChangePin
          isOpen={showSelectCity}
          title={showPinTitle}
          onClose={closeSelectCity}
          onSuccess={successSelectCity}
        />
      ) : null}
      {showCityModal ? (
        <ChangeCity
          isOpen={showCityModal}
          title={showPinTitle}
          description={changeCityDescription}
          onClose={closeSelectCity}
        />
      ) : null}
      {confirmSelectCity ? (
        <ChangePin
          isOpen={confirmSelectCity}
          title={'Are you sure in your choice?'}
          onClose={closeCoonfirmSelectCity}
          onSuccess={callInstitutionCard}
        />
      ) : null}
    </div>
  );
};

export default PlayersGame;
