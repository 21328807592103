const Bar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28">
      <rect width="26" height="26" x="1" y="1" fill="#fff" rx="13"></rect>
      <path
        fill="#333"
        d="M19.25 14l-8.75 8.75-1.225-1.225L16.8 14 9.275 6.475 10.5 5.25 19.25 14z"
      ></path>
      <rect width="26" height="26" x="1" y="1" stroke="#10451D" strokeWidth="2" rx="13"></rect>
    </svg>
  );
};

export default Bar;
