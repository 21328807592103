import classNames from 'classnames';
import { FC } from 'react';

export interface AttributeInterface {
  name?: string;
  description: string;
  card_value: string;
  action?: string;
  use_power?: string;
  log?: string;
}
export interface StructureInterface {
  log?: string;
  name?: string;
  type?: string;
  typeBuild: string;
  card_value?: string;
  action?: string;
  country?: string;
  action_description?: string;
  use_power?: string;
}
export interface InstitutionInterface {
  name?: string;
  effect: string[];
  modifier: number;
  value: string;
  card_value: string;
  action?: string;
  requirements?: string[];
  cost: string[];
  subDescription: string[];
  use_power?: string;
  log?: string;
  type?: string;
}
export interface RoleInterface {
  id?: string;
  name: string;
  icon?: string;
  role: string;
  roleDb?: string;
  bg?: string;
  structures: StructureInterface[];
  description: string;
  attributes: AttributeInterface[];
  gameIcon?: any;
  institutions: InstitutionInterface[];
  active?: boolean;
}

export interface RolesProps {
  roles: RoleInterface[];
  selectedRole: RoleInterface;
  userRole?: string;
  select: (role: RoleInterface) => void;
}

const Roles: FC<RolesProps> = ({ roles, select, selectedRole, userRole }) => {
  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row justify-start">
        <p className="m-0 text-white text-2xl font-medium">Roles</p>
      </div>
      <div className="flex flex-col h-full overflow-y-auto">
        {roles?.map((r, i) => {
          return (
            <div
              key={i}
              className={`flex flex-row py-3 border-0 cursor-pointer ${
                i !== 0 ? 'border-t border-solid border-white-20' : ''
              }`}
              onClick={() => select(r)}
            >
              <div
                className={classNames(
                  'flex flex-row justify-center items-center w-14 h-14 p-1 rounded-md border-2 border-solid shadow-roles-icon',
                  {
                    'border-jungle-green': selectedRole?.name === r.name,
                    'bg-deep-sea-green': selectedRole?.name === r.name,
                    'border-white-70': selectedRole?.name !== r.name,
                    'bg-white-30': selectedRole?.name !== r.name,
                  },
                )}
              >
                <img src={r.icon} alt={r.name} className="object-cover max-w-full max-h-full" />
              </div>
              <div className="flex flex-col ml-3">
                <div className="flex flex-row mb-1">
                  <p className="m-0 text-white text-sm font-medium leading-4">
                    {r.name}
                    {r?.roleDb === userRole ? '(YOU)' : ''}
                  </p>
                  <div className="border border-solid border-white-50 px-1 rounded-sm ml-2">
                    <p className="m-0 text-white-80 text-xs uppercase font-medium">{r.role}</p>
                  </div>
                </div>
                {r?.structures?.map((s) => {
                  return (
                    <div key={s.name}>
                      <p className="m-0 text-xs text-white-70 font-normal">{s.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Roles;
