import classNames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';

import DoubleArrowUp from '../../assets/DoubleArrowUp';
import Catalog from '../../assets/Catalog';
import { GameService, GameStateI } from '../../services/game.serivice';
import { SocketService } from '../../services/socket.services';
import LogItem from './LogItem';
interface LogsProps {
  gameId: string;
}

const Logs: FC<LogsProps> = ({ gameId }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [logs, setLogs] = useState<GameStateI[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const getChangeGame = useCallback(async () => {
    try {
      if (gameId) {
        const { data } = await GameService.getActions(gameId, 'visible_log');
        setLogs(data.items);
        const newHasMore = data?.count > data.items.length;
        setHasMore(newHasMore);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  }, [gameId]);

  const addToState = useCallback(
    async (item: GameStateI) => {
      if (gameId) {
        setLogs([item, ...logs]);
      }
    },
    [gameId, logs],
  );

  const connectSocket = useCallback(() => {
    try {
      if (gameId) {
        const socket = SocketService.getInstanceGame(gameId);
        socket.emit('identity', `room:${gameId}`);
        socket.on('game_stream', (data: GameStateI) => {
          if (!data?.state?.isEnded && data.type === 'visible_log') {
            addToState(data);
          }
        });
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  }, [addToState, gameId]);

  const fetchData = async (page: any) => {
    try {
      if (gameId) {
        const skip = page * 10;
        const { data } = await GameService.getActions(gameId, 'visible_log', 10, skip);
        const newLogs = [...logs, ...data.items];
        setLogs(newLogs);
        const newHasMore = !(data?.count <= newLogs.length);
        setHasMore(newHasMore);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  };

  useEffect(() => {
    if (gameId) {
      getChangeGame();
    }
  }, [gameId, getChangeGame]);

  useEffect(() => {
    if (gameId) {
      connectSocket();
    }
  }, [connectSocket, gameId]);

  return (
    <div className="flex flex-col h-full bg-derby-95 max-w-xs min-h-max">
      <div
        className={classNames(
          'flex flex-row justify-between items-center p-4 bg-muddy-waters-90 cursor-pointer',
        )}
        onClick={() => setOpened(!opened)}
      >
        <div className="flex flex-row justify-start items-center">
          <Catalog />
          <p className="text-white text-lg leading-4 font-medium m-0 ml-2">Log History</p>
        </div>
        <div
          className={classNames('transition-all ease-linear', {
            'rotate-180': opened,
          })}
        >
          <DoubleArrowUp />
        </div>
      </div>
      <div
        className={classNames('flex flex-col p-4 bg-papaya_whip justify-between', {
          'h-[350px]': opened,
          'h-0 hidden': !opened,
        })}
      >
        <div className="flex flex-col overflow-y-auto min-w-[250px] bg-chat">
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchData}
            hasMore={hasMore}
            isReverse={false}
            useWindow={false}
            threshold={100}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {logs.map((log, index) => {
              return (
                <div key={`${log.id}_${index}`}>
                  <LogItem {...log} />
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Logs;
