const Chemistry = ({ fill = '#99D98C' }) => {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" fill="none" viewBox="0 0 33 32">
    //   <path
    //     fill="#99D98C"
    //     d="M27.481 23.618l-7.231-9.943V4h2V2h-12v2h2v9.675l-7.231 9.943A4.019 4.019 0 008.269 30H24.23a4.017 4.017 0 003.25-6.382zM14.25 14.325V4h4v10.325L20.923 18h-9.346l2.673-3.675zM24.231 28H8.27a2.02 2.02 0 01-1.633-3.206L10.123 20h12.254l3.487 4.794A2.018 2.018 0 0124.231 28z"
    //   ></path>
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M20.424 17.714L15 10.256V3h1.5V1.5h-9V3H9v7.256l-5.424 7.458A3.014 3.014 0 006.014 22.5h11.972a3.014 3.014 0 002.438-4.786zm-9.924-6.97V3h3v7.744l2.005 2.756h-7.01l2.005-2.756zM17.986 21H6.014a1.514 1.514 0 01-1.225-2.405L7.404 15h9.192l2.615 3.595A1.514 1.514 0 0117.986 21z"
      ></path>
    </svg>
  );
};

export default Chemistry;
