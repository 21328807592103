const Smoke = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <rect width="24" height="24" fill="#191919" rx="12"></rect>
      <path
        fill="#fff"
        d="M17.5 18a.5.5 0 01-.4-.2l-.407-.543a4.498 4.498 0 01-.8-3.643l.736-3.438a3.497 3.497 0 00-.622-2.833L15.6 6.8a.5.5 0 11.8-.6l.407.543a4.5 4.5 0 01.8 3.643l-.736 3.438a3.497 3.497 0 00.622 2.833l.407.543a.5.5 0 01-.4.8zM15 18a.5.5 0 01-.4-.2l-.407-.543a4.498 4.498 0 01-.8-3.643l.736-3.438a3.497 3.497 0 00-.622-2.833L13.1 6.8a.5.5 0 11.8-.6l.407.543a4.5 4.5 0 01.8 3.643l-.736 3.438a3.497 3.497 0 00.622 2.833l.407.543a.5.5 0 01-.4.8zM12.5 18a.5.5 0 01-.4-.2l-.407-.543a4.498 4.498 0 01-.8-3.643l.736-3.438a3.497 3.497 0 00-.622-2.833L10.6 6.8a.5.5 0 11.8-.6l.407.543a4.5 4.5 0 01.8 3.643l-.736 3.438a3.497 3.497 0 00.622 2.833l.407.543a.5.5 0 01-.4.8zM10 18a.5.5 0 01-.4-.2l-.407-.543a4.499 4.499 0 01-.8-3.643l.736-3.438a3.498 3.498 0 00-.622-2.833L8.1 6.8a.5.5 0 11.8-.6l.407.543a4.5 4.5 0 01.8 3.643l-.736 3.438a3.497 3.497 0 00.622 2.833l.407.543a.5.5 0 01-.4.8zM7.5 18a.497.497 0 01-.4-.2l-.407-.543a4.499 4.499 0 01-.8-3.643l.737-3.438a3.498 3.498 0 00-.623-2.833L5.6 6.8a.5.5 0 11.8-.6l.407.543a4.499 4.499 0 01.8 3.643l-.736 3.438a3.498 3.498 0 00.622 2.833l.407.543a.5.5 0 01-.4.8z"
      ></path>
    </svg>
  );
};

export default Smoke;
