import classNames from 'classnames';
import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import { RoleInterface } from '../Roles';

export interface RolesProps {
  selectedRole: RoleInterface;
  isYou?: boolean;
}

const RoleDetails: FC<RolesProps> = ({ selectedRole, isYou = false }) => {
  return (
    <div className="flex flex-col bg-black-10 rounded-lg p-4">
      <div className="flex flex-row justify-start items-center">
        {selectedRole ? <selectedRole.gameIcon fill="#99D98C" /> : null}
        <p className="m-0 text-white text-2xl font-medium ml-3">
          {selectedRole?.role}
          {isYou ? '(You)' : ''}
        </p>
      </div>
      <div className="flex xl:flex-row lg:flex-col h-full mt-3 max-h-96 overflow-y-auto">
        <div
          className="flex flex-row justify-center items-center min-w-[176px] min-h-[256px] w-44 h-64 border-8 border-solid border-black rounded-[20px] p-5 bg-no-repeat bg-cover"
          style={{ backgroundImage: `url('${selectedRole?.bg}')` }}
        >
          <img
            src={selectedRole?.icon}
            alt={selectedRole?.role}
            className="object-cover max-w-full max-h-full"
          />
        </div>
        <div className="flex flex-col xl:ml-6 lg:ml-0 xl:mt-0 lg:mt-2">
          <div className="flex flex-col py-2 border-0 border-t border-solid border-white-20">
            <p className="m-0 text-sm leading-4 text-white font-normal mb-1">Structures</p>
            {selectedRole?.structures?.map((s) => {
              return (
                <div className="" key={s.name}>
                  <p className="m-0 text-xs text-white-70 font-normal">{s.name}</p>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col py-2 border-0 border-t border-solid border-white-20">
            <p className="m-0 text-sm leading-4 text-white font-normal mb-1">Description</p>
            <p className="m-0 text-xs text-white-70 font-normal">{selectedRole?.description}</p>
          </div>
          <div className="flex flex-col py-2">
            {selectedRole?.attributes?.map((a, i) => {
              return (
                <div
                  key={a.name}
                  className={classNames('flex flex-row justify-start items-start', {
                    'mb-1': i !== selectedRole?.attributes?.length - 1,
                  })}
                >
                  <p className="m-0 text-sm leading-4 text-white font-normal">{a.name}</p>
                  <button
                    className="bg-transparent border-0 outline-none"
                    data-tooltip-id="my-attribute"
                    data-tooltip-content={a.description}
                  >
                    <img src="/images/info.png" alt="info" />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Tooltip
        id="my-attribute"
        className="max-w-xs"
        opacity={1}
        style={{ backgroundColor: '#ffffff', color: '#4D4D4D' }}
      />
    </div>
  );
};

export default RoleDetails;
