import React, { useContext } from 'react';
import { GameStateI } from '../../../services/game.serivice';
import { InstitutionInterface, RoleInterface, StructureInterface } from '../../Roles';
import classNames from 'classnames';
import { AuthContext } from '../../../context/context';
import { ROLES } from '../../../constants/roles';

const LogItem = (props: GameStateI) => {
  const { user } = useContext(AuthContext);
  const playerData = ROLES?.find((p) => p.roleDb === props.author?.role) as RoleInterface;

  const LogTemplate = ({ message }: { message: string }) => {
    return (
      <>
        <div
          className={classNames(
            'border border-solid rounded-md p-2 h-7 w-7 flex flex-row justify-center items-center mr-2',
            {
              'bg-potters-clay-70 border-potters-clay': props.author?.id === user?.id,
              'bg-white-30 border-gape-palliser': props.author?.id !== user?.id,
            },
          )}
        >
          {!!playerData && (
            <playerData.gameIcon fill={props.author?.id === user?.id ? '#ffffff' : '#6F4518'} />
          )}
        </div>
        <p className="m-0 text-dove-gray text-xs font-normal flex-1">
          <span className="text-black text-xs font-medium">{props.author?.name}</span> {message}
        </p>
      </>
    );
  };

  const addInstitutionEvent = () => {
    let message = `played ${props.state.card?.name}`;
    const { modifier = 0 } = props.state.card as InstitutionInterface;
    if (modifier > 0) {
      message += `, increasing the modifier by ${modifier}`;
    }

    if (modifier < 0) {
      message += `, reducing the modifier by ${Math.abs(modifier)}`;
    }
    return <LogTemplate message={message} />;
  };
  const skipEvent = <LogTemplate message={'skipped their turn'} />;

  return (
    <div className="mb-6 flex">
      {props.state.card?.action === 'add_build' &&
        LogTemplate({ message: (props.state.card as StructureInterface)?.log! })}
      {props.state.label === 'action' &&
        (props.state.card as StructureInterface)?.log &&
        LogTemplate({ message: (props.state.card as StructureInterface)?.log! })}
      {props.state.typeBuild === 'danger' && <LogTemplate message={props?.state?.card?.log!} />}
      {props.state.typeBuild === 'institution' && addInstitutionEvent()}
      {props.state.type === 'skip' && skipEvent}
    </div>
  );
};

export default LogItem;
