const Checkmark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
      <g>
        <path
          fill="#fff"
          d="M6.75 12.5L2.25 8l.707-.707 3.793 3.792 6.793-6.792.707.707-7.5 7.5z"
        ></path>
      </g>
    </svg>
  );
};

export default Checkmark;
