import ApiLogin from '../axios/api-login';
import Api from '../axios/api';

interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}
export interface User {
  id?: string;
  username: string;
  role?: string;
}

interface PostAuthResponse {
  data: AuthTokens;
}
interface GetMeResponse {
  data: User;
}

export class AuthService {
  static login(username: string): Promise<PostAuthResponse> {
    return ApiLogin.post(`/v1/auth/login`, { username });
  }

  static getMe(): Promise<GetMeResponse> {
    return Api.get(`v1/auth/me`);
  }
}
