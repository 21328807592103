import React from 'react';
import Modal from 'react-modal';
import Close from '../../assets/Close';
import { InstitutionInterface } from '../Roles';
import Next from '../../assets/Next';
import classNames from 'classnames';

interface InstitutionCardProps {
  isOpen: boolean;
  disabled: boolean;
  effectIsNotMet: number[];
  requirementIsNotMet: number[];
  costIsNotMet: number[];
  onClose: () => void;
  onSubmit: () => void;
  institutionCard: InstitutionInterface;
}

const InstitutionCard = ({
  isOpen,
  onClose,
  onSubmit,
  institutionCard,
  requirementIsNotMet,
  costIsNotMet,
  effectIsNotMet,
  disabled,
}: InstitutionCardProps) => {
  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onClose}
      className="absolute rounded-2xl max-w-sm min-w-[384px]"
      overlayClassName="modal_overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col rounded-2xl bg-hint_of_green border-mantis border-solid border-4 shadow-product-modal">
        <div className="px-4 py-3 flex flex-col bg-forest-green rounded-t-xl">
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-white text-2xl font-medium">{institutionCard.type}</p>
            <div className="flex flex-row items-center">
              <div className="border border-solid border-white-50 rounded-sm px-1 h-full mx-2">
                <p className="m-0 text-white-80 text-[10px] leading-4 font-medium">
                  Institution Card
                </p>
              </div>
              <button
                onClick={onClose}
                className="bg-transparent outline-none border-0 flex flex-row justify-center items-center h-8 w-8 p-0 cursor-pointer"
              >
                <Close fill={'rgba(255, 255, 255, 0.80)'} />
              </button>
            </div>
          </div>
          <div>
            <p className="m-0 text-lg text-white-60 font-normal">{institutionCard.name}</p>
          </div>
        </div>
        <div className="flex flex-col p-4">
          {institutionCard?.effect?.length ? (
            <div className="flex flex-col mb-4">
              <div>
                <p className="m-0 text-sm leading-4 text-black font-normal mb-1">Effect</p>
                <ul className="m-0">
                  {institutionCard.effect.map((description, index) => {
                    const ifSubDescription = description[description.length - 1] === ':';
                    return (
                      <li
                        key={description}
                        className={classNames('text-xs font-normal', {
                          'text-black-80': !ifSubDescription && !effectIsNotMet.includes(index),
                          'text-venetian_red': !ifSubDescription && effectIsNotMet.includes(index),
                        })}
                      >
                        {description}
                        {ifSubDescription && institutionCard?.subDescription?.length ? (
                          <ul className="m-0">
                            {institutionCard.subDescription.map((subDescription, key) => {
                              return (
                                <li
                                  key={subDescription}
                                  className={classNames('text-xs font-normal', {
                                    'text-black-80': !effectIsNotMet.includes(key),
                                    'text-venetian_red': effectIsNotMet.includes(key),
                                  })}
                                >
                                  {subDescription}
                                </li>
                              );
                            })}
                          </ul>
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : null}
          {institutionCard?.requirements?.length ? (
            <div className="flex flex-col mb-4">
              <div>
                <p className="m-0 text-sm leading-4 text-black font-normal mb-1">Requirements</p>
                <ul className="m-0">
                  {institutionCard.requirements.map((requirement, index) => {
                    return (
                      <li
                        key={requirement}
                        className={classNames('text-xs font-normal', {
                          'text-black-80': !requirementIsNotMet.includes(index),
                          'text-venetian_red': requirementIsNotMet.includes(index),
                        })}
                      >
                        {requirement}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : null}
          {institutionCard?.cost?.length ? (
            <div className="flex flex-col mb-4">
              <div>
                <p className="m-0 text-sm leading-4 text-black font-normal mb-1">Cost</p>
                <ul className="m-0">
                  {institutionCard.cost.map((cost, index) => {
                    return (
                      <li
                        key={cost}
                        className={classNames('text-xs font-normal text-black-80', {
                          'text-black-80': !costIsNotMet.includes(index),
                          'text-venetian_red': costIsNotMet.includes(index),
                        })}
                      >
                        {cost}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col px-4 pb-4">
          <button
            className={classNames(
              `flex flex-row justify-center items-center rounded-sm bg-forest-green outline-none border-0 px-5 py-3 cursor-pointer`,
              {
                'opacity-50': disabled,
              },
            )}
            onClick={onSubmit}
            disabled={disabled}
          >
            <p className="m-0 text-base font-medium text-white mr-2 py-1">Play</p>
            <Next />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default InstitutionCard;
