const Carbon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <rect width="24" height="24" fill="#191919" rx="12"></rect>
      <path
        fill="#fff"
        d="M10.75 19.407a.5.5 0 01-.246-.065l-4.25-2.407A.5.5 0 016 16.5v-5a.5.5 0 01.254-.435l4.25-2.408a.5.5 0 01.492 0l4.25 2.408a.5.5 0 01.254.435v5a.5.5 0 01-.254.435l-4.25 2.408a.5.5 0 01-.246.064zM7 16.21l3.75 2.124 3.75-2.124V11.79l-3.75-2.124L7 11.791v4.418z"
      ></path>
      <path
        fill="#fff"
        d="M18 12.5h-1V7.791l-3.75-2.124-4.004 2.268-.492-.87 4.25-2.408a.501.501 0 01.492 0l4.25 2.408A.5.5 0 0118 7.5v5z"
      ></path>
    </svg>
  );
};

export default Carbon;
