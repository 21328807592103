import { useContext, useEffect, useState } from 'react';
import Tracker from '@openreplay/tracker';
import trackerProfiler from '@openreplay/tracker-profiler';
import trackerAssist from '@openreplay/tracker-assist';
import { AuthContext } from '../context/context';

let _tracker: Tracker;
if (process.env.REACT_APP_OR_PROJECT_KEY) {
  _tracker = new Tracker({
    projectKey: process.env.REACT_APP_OR_PROJECT_KEY,
    ingestPoint:
      process.env.REACT_APP_OR_INGEST_POINT || 'https://openreplay-rising.corlab.tech/ingest',
    __DISABLE_SECURE_MODE: true,
  });
}

export const useOpenReplay = () => {
  const [tracker] = useState<Tracker>(_tracker);
  const [profiler, setProfiler] = useState<any>();
  const { user } = useContext(AuthContext);

  const init = async () => {
    await tracker.start();

    const profiler = _tracker.use(trackerProfiler());
    setProfiler(profiler);
    tracker.use(
      trackerAssist({
        confirmText: 'Technical support request assistance',
      }),
    );
  };

  useEffect(() => {
    if (!tracker) {
      return;
    }

    if (user) {
      tracker.setUserID(user?.username!);
    }

    if (!tracker.isActive()) {
      init()
        .then(() => console.log(`openreplay is initialised with user "${user?.username}"`))
        .catch(console.error);
    }
  }, [tracker, user]);

  return [tracker, profiler];
};
