import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Chat from '../../components/Chat';
import { useNavigate, useParams } from 'react-router-dom';
import Players from '../../components/Players';
import Roles, { RoleInterface } from '../../components/Roles';
import RoleDetails from '../../components/RoleDetails';
import { ROLES } from '../../constants/roles';
import { LobbyI, LobbyService, Player } from '../../services/lobby.serivice';
import { SocketService } from '../../services/socket.services';
import { AuthContext } from '../../context/context';
import { useOpenReplay } from '../../hooks/use-openreplay';

const Lobby: FC = () => {
  const [tracker] = useOpenReplay();
  const navigate = useNavigate();
  const params = useParams();
  const { user, setPlayers } = useContext(AuthContext);
  const [curentLobby, setLobby] = useState<LobbyI | undefined>();
  const [selectedRole, setRole] = useState<RoleInterface | undefined>();
  const [roles, setRoles] = useState<RoleInterface[]>([]);
  const [players, setLocalPlayers] = useState<Player[]>([]);
  const [meRole, setMeRole] = useState<string>();
  const [connected, setConnected] = useState<boolean>(false);

  const connectSocket = useCallback(
    (lobbyId?: string) => {
      try {
        if (lobbyId) {
          const socket = SocketService.getInstanceLobby(lobbyId);
          setConnected(true);
          socket.emit('identity', `room:${lobbyId}`);
          socket.on('lobby_stream', (data: { new_val: LobbyI & { gameId?: string } }) => {
            if (data.new_val.gameId && data?.new_val.author.id !== user?.id) {
              navigate(`/game/${data.new_val.gameId}`);
            }
            if (data?.new_val?.players?.length) {
              setLocalPlayers(data?.new_val?.players);
              setPlayers(data?.new_val?.players);
              const newRoles = data?.new_val?.players?.map((r) => {
                const newRole = ROLES.find((rl) => rl.roleDb === r.role)!;
                return {
                  ...r,
                  ...newRole,
                  name: r.name,
                };
              });
              const me = newRoles.find((r) => r.id === user?.id);
              setMeRole(me?.roleDb);

              setRoles(newRoles as unknown as RoleInterface[]);
              setRole(me as unknown as RoleInterface);
            }
          });
        }
      } catch (e: any) {
        const message = e?.response?.statusText || 'Error';
        toast(message, { type: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, user?.id],
  );

  const getLobby = useCallback(async () => {
    try {
      const { data } = await LobbyService.getOne(params?.lobbyId!);
      setLobby(data);
    } catch (e) {
      navigate('/');
    }
  }, [navigate, params?.lobbyId]);

  useEffect(() => {
    if (user?.id && players?.length) {
      const me = players.find((r) => r.id === user?.id);
      setMeRole(me?.role);
    }
  }, [user?.id, players]);

  useEffect(() => {
    if (!params?.lobbyId) {
      navigate('/');
    } else if (tracker) {
      tracker.setMetadata('lobbyId', params.lobbyId);
      getLobby();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, params?.lobbyId, tracker]);

  useEffect(() => {
    if (curentLobby && curentLobby.id) {
      setLocalPlayers(curentLobby?.players);
      setPlayers(curentLobby.players);
      const newRoles = curentLobby?.players?.map((r) => {
        const newRole = ROLES.find((rl) => rl.roleDb === r.role)!;
        return {
          ...r,
          ...newRole,
          name: r.name,
        };
      });
      const me = newRoles.find((r) => r.id === user?.id);
      setMeRole(me?.roleDb);

      setRoles(newRoles as unknown as RoleInterface[]);
      setRole(me as unknown as RoleInterface);
    }
  }, [curentLobby, setPlayers, user?.id]);

  const copyLink = () => {
    try {
      navigator?.clipboard?.writeText(`${process.env.REACT_APP_URL}/join/${curentLobby?.id}`);
      toast('Copied successfully', { type: 'success' });
    } catch (e) {}
  };

  const selectRole = (role: RoleInterface) => {
    setRole(role);
  };

  const reRoll = async () => {
    try {
      const { data } = await LobbyService.shuffle(curentLobby?.id!);
      setLobby(data);
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  };

  const startGame = async () => {
    try {
      const { data } = await LobbyService.start(curentLobby?.id!);
      setPlayers(data.players);
      navigate(`/game/${data.id}`);
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  };

  const back = async () => {
    try {
      if (curentLobby?.id) {
        await LobbyService.leave(curentLobby?.id!);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    } finally {
      navigate('/home');
    }
  };

  useEffect(() => {
    connectSocket(params?.lobbyId);
  }, [params?.lobbyId, connectSocket]);

  useEffect(() => {
    return () => {
      if (connected) {
        SocketService.disconnectLobby(params?.lobbyId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  return (
    <div className="w-full h-screen">
      <div
        className="absolute bg-no-repeat z-0 bg-cover h-full w-full bg-left-bottom"
        style={{ backgroundImage: `url('/images/bg.jpg')` }}
      />
      <div className="flex flex-row justify-between h-full relative z-10 bg-saddle-brown-95">
        <Chat chatId={curentLobby?.chatId!} isGame={false} />
        <div className="flex flex-col p-6 w-full justify-between">
          <div className="justify-between flex xl:flex-row lg:flex-col">
            <div className="flex flex-row justify-start">
              <button
                onClick={back}
                className="bg-transparent border-0 outline-none cursor-pointer"
              >
                <img src="/images/back.png" alt="Back" />
              </button>
              <p className="m-0 ml-4 text-5xl font-medium leading-tight uppercase text-tangerine">
                Create new Lobby
              </p>
            </div>
            <div className="flex flex-row justify-end">
              <div className="flex flex-col pb-2 border-0 border-b border-solid border-mercury">
                <p className="m-0 text-sm text-white font-normal leading-4 mb-3">Invite link</p>
                <div className="flex flex-row">
                  <input
                    type="text"
                    value={`${process.env.REACT_APP_URL}/join/${curentLobby?.id}`}
                    className="m-0 text-sm text-white-50 font-normal leading-4 outline-none bg-transparent border-0 min-w-[420px]"
                    disabled={true}
                  />
                </div>
              </div>
              <div className="flex flex-row mb-4 ml-2 items-end">
                <button
                  className="flex outline-none bg-transparent border-0 cursor-pointer"
                  onClick={copyLink}
                >
                  <img src="/images/copy.png" alt="copy" className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col px-12">
            <div>
              <Players
                players={
                  players?.map((r) => ({
                    name: `${r.name}${r.id === user?.id ? '(YOU)' : ''}`,
                    active: true,
                  })) || []
                }
              />
            </div>
            <div className="grid grid-cols-2 xl:gap-28 lg:gap-14 xl:mt-20 lg:mt-10">
              <div>
                <Roles
                  roles={roles}
                  selectedRole={selectedRole!}
                  select={selectRole}
                  userRole={meRole}
                />
              </div>
              <div>
                <RoleDetails selectedRole={selectedRole!} isYou={selectedRole?.id === user?.id} />
              </div>
            </div>
          </div>
          <div>
            {user?.id === curentLobby?.author?.id ? (
              <div className="flex flex-row justify-center gap-6">
                <button
                  className="flex flex-col justify-center items-center outline-none border-0 rounded-sm px-5 py-3 w-52 cursor-pointer bg-mango-tango"
                  onClick={reRoll}
                >
                  <img src="/images/reroll.png" alt="reroll" className="w-14 h-14" />
                  <p className="m-0 text-white font-medium text-base mt-1">Re-roll</p>
                </button>
                <button
                  className="flex flex-col justify-center items-center outline-none border-0 rounded-sm px-5 py-3 w-52 cursor-pointer bg-forest-green"
                  onClick={startGame}
                >
                  <img src="/images/play.png" alt="play" className="w-14 h-14" />
                  <p className="m-0 text-white font-medium text-base mt-1">Start game</p>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lobby;
