import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { JoinLobby, Main } from '../../pages';
import GuardedRoute from '../Guards/GuardedRoute';
import Lobby from '../../pages/Lobby';
import Rules from '../../pages/Rules';
import Game from '../../pages/Game';
import LoginPage from '../../pages/Login';
import JoinNameLobby from '../../pages/JoinNameLobby';
import { useOpenReplay } from '../../hooks/use-openreplay';

const AppRouter: FC = () => {
  useOpenReplay();
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="/home"
        element={
          <GuardedRoute>
            <Main />
          </GuardedRoute>
        }
      />
      <Route
        path="/join"
        element={
          <GuardedRoute>
            <JoinNameLobby />
          </GuardedRoute>
        }
      />
      <Route path="/join/:lobbyId" element={<JoinLobby />} />
      <Route
        path="/lobby/:lobbyId"
        element={
          <GuardedRoute>
            <Lobby />
          </GuardedRoute>
        }
      />
      <Route
        path="/game/:gameId"
        element={
          <GuardedRoute>
            <Game />
          </GuardedRoute>
        }
      />
      <Route
        path="/rules"
        element={
          <GuardedRoute>
            <Rules />
          </GuardedRoute>
        }
      />
    </Routes>
  );
};

export default AppRouter;
