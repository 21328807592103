import React from 'react';
import Modal from 'react-modal';
import Next from '../../assets/Next';

interface WinnerModalProps {
  isOpen: boolean;
  onSubmit: () => void;
}

const WinnerModal = ({ isOpen, onSubmit }: WinnerModalProps) => {
  return (
    <Modal
      isOpen={!!isOpen}
      className="absolute rounded-2xl max-w-[500px] outline-none"
      overlayClassName="modal_overlay_danger"
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col border-0">
        <div className="flex flex-col items-center">
          <div>
            <p className="m-0 text-center text-5xl text-forest-green font-normal">
              Congratulations, you won the game!
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center mt-8">
          <button
            className="flex flex-row w-max justify-center items-center rounded-sm bg-mercury outline-none border-0 px-5 py-3 cursor-pointer"
            onClick={onSubmit}
          >
            <p className="m-0 text-sm leading-4 font-medium text-black mr-2 py-1">Main Menu</p>
            <Next fill={'#000000'} />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default WinnerModal;
