const JoinLobby = ({ color = '#ffffff', size = 28 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color}
        d="M16.25 8.75H15V10h1.25a1.877 1.877 0 011.875 1.875v2.5h1.25v-2.5A3.129 3.129 0 0016.25 8.75zM15 2.5a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75zm0-1.25a3.125 3.125 0 100 6.25 3.125 3.125 0 000-6.25zM14.375 18.75h-1.25V17.5a1.877 1.877 0 00-1.875-1.875h-2.5A1.877 1.877 0 006.875 17.5v1.25h-1.25V17.5a3.129 3.129 0 013.125-3.125h2.5a3.128 3.128 0 013.125 3.125v1.25zM10 8.125a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75zm0-1.25a3.125 3.125 0 100 6.25 3.125 3.125 0 000-6.25zM5 8.75H3.75a3.129 3.129 0 00-3.125 3.125v2.5h1.25v-2.5A1.877 1.877 0 013.75 10H5V8.75zM5 2.5a1.875 1.875 0 110 3.75A1.875 1.875 0 015 2.5zm0-1.25A3.125 3.125 0 105 7.5a3.125 3.125 0 000-6.25z"
      ></path>
    </svg>
  );
};

export default JoinLobby;
