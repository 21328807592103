const Bank = ({ fill = '#ffffff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M22.5 21h-21v1.5h21V21zM20.25 8.25A.75.75 0 0021 7.5V5.25a.75.75 0 00-.495-.705l-8.25-3a.75.75 0 00-.51 0l-8.25 3A.75.75 0 003 5.25V7.5a.75.75 0 00.75.75h.75V18H3v1.5h18V18h-1.5V8.25h.75zM4.5 5.775l7.5-2.73 7.5 2.73v.975h-15v-.975zM13.5 18h-3V8.25h3V18zM6 8.25h3V18H6V8.25zM18 18h-3V8.25h3V18z"
      ></path>
    </svg>
  );
};

export default Bank;
