import { CoordsI } from '../services/game.serivice';

export const POINTS: { [key: string]: CoordsI[] } = {
  '1': [
    {
      cityId: 1,
      x: 343,
      y: 396,
    },
    {
      cityId: 1,
      x: 272,
      y: 474,
    },
    {
      cityId: 1,
      x: 299,
      y: 555,
    },
    {
      cityId: 1,
      x: 314,
      y: 462,
    },
    {
      cityId: 1,
      x: 347,
      y: 540,
    },
    {
      cityId: 1,
      x: 394,
      y: 481,
    },
    {
      cityId: 1,
      x: 455,
      y: 548,
    },
    {
      cityId: 1,
      x: 494,
      y: 464,
    },
    {
      cityId: 1,
      x: 520,
      y: 537,
    },
    {
      cityId: 1,
      x: 536,
      y: 482,
    },
  ],
  '2': [
    {
      cityId: 2,
      x: 369,
      y: 342,
    },
    {
      cityId: 2,
      x: 401,
      y: 334,
    },
    {
      cityId: 2,
      x: 433,
      y: 321,
    },
    {
      cityId: 2,
      x: 430,
      y: 371,
    },
    {
      cityId: 2,
      x: 463,
      y: 352,
    },
    {
      cityId: 2,
      x: 495,
      y: 342,
    },
    {
      cityId: 2,
      x: 480,
      y: 394,
    },
    {
      cityId: 2,
      x: 513,
      y: 375,
    },
    {
      cityId: 2,
      x: 541,
      y: 402,
    },
    {
      cityId: 2,
      x: 567,
      y: 387,
    },
  ],
  '3': [
    {
      cityId: 3,
      x: 476,
      y: 216,
    },
    {
      cityId: 3,
      x: 519,
      y: 237,
    },
    {
      cityId: 3,
      x: 548,
      y: 243,
    },
    {
      cityId: 3,
      x: 611,
      y: 247,
    },
    {
      cityId: 3,
      x: 499,
      y: 266,
    },
    {
      cityId: 3,
      x: 546,
      y: 294,
    },
    {
      cityId: 3,
      x: 594,
      y: 291,
    },
    {
      cityId: 3,
      x: 645,
      y: 282,
    },
    {
      cityId: 3,
      x: 598,
      y: 324,
    },
    {
      cityId: 3,
      x: 647,
      y: 314,
    },
  ],
  '4': [
    {
      cityId: 4,
      x: 173,
      y: 201,
    },
    {
      cityId: 4,
      x: 237,
      y: 200,
    },
    {
      cityId: 4,
      x: 314,
      y: 193,
    },
    {
      cityId: 4,
      x: 377,
      y: 192,
    },
    {
      cityId: 4,
      x: 206,
      y: 237,
    },
    {
      cityId: 4,
      x: 169,
      y: 300,
    },
    {
      cityId: 4,
      x: 234,
      y: 299,
    },
    {
      cityId: 4,
      x: 284,
      y: 285,
    },
    {
      cityId: 4,
      x: 356,
      y: 265,
    },
    {
      cityId: 4,
      x: 347,
      y: 301,
    },
  ],
  '5': [
    {
      cityId: 5,
      x: 1084,
      y: 408,
    },
    {
      cityId: 5,
      x: 1135,
      y: 385,
    },
    {
      cityId: 5,
      x: 1046,
      y: 372,
    },
    {
      cityId: 5,
      x: 1100,
      y: 368,
    },
    {
      cityId: 5,
      x: 1135,
      y: 343,
    },
    {
      cityId: 5,
      x: 1021,
      y: 333,
    },
    {
      cityId: 5,
      x: 1044,
      y: 314,
    },
    {
      cityId: 5,
      x: 1076,
      y: 310,
    },
    {
      cityId: 5,
      x: 1024,
      y: 296,
    },
    {
      cityId: 5,
      x: 1116,
      y: 308,
    },
  ],
  '6': [
    {
      cityId: 6,
      x: 960,
      y: 441,
    },
    {
      cityId: 6,
      x: 826,
      y: 390,
    },
    {
      cityId: 6,
      x: 884,
      y: 396,
    },
    {
      cityId: 6,
      x: 939,
      y: 394,
    },
    {
      cityId: 6,
      x: 1001,
      y: 401,
    },
    {
      cityId: 6,
      x: 851,
      y: 332,
    },
    {
      cityId: 6,
      x: 890,
      y: 328,
    },
    {
      cityId: 6,
      x: 927,
      y: 329,
    },
    {
      cityId: 6,
      x: 972,
      y: 318,
    },
    {
      cityId: 6,
      x: 934,
      y: 285,
    },
  ],
  '7': [
    {
      cityId: 7,
      x: 809,
      y: 150,
    },
    {
      cityId: 7,
      x: 772,
      y: 206,
    },
    {
      cityId: 7,
      x: 817,
      y: 202,
    },
    {
      cityId: 7,
      x: 864,
      y: 209,
    },
    {
      cityId: 7,
      x: 779,
      y: 254,
    },
    {
      cityId: 7,
      x: 761,
      y: 293,
    },
    {
      cityId: 7,
      x: 787,
      y: 294,
    },
    {
      cityId: 7,
      x: 820,
      y: 273,
    },
    {
      cityId: 7,
      x: 851,
      y: 275,
    },
    {
      cityId: 7,
      x: 885,
      y: 265,
    },
  ],
  '8': [
    {
      cityId: 8,
      x: 898,
      y: 155,
    },
    {
      cityId: 8,
      x: 929,
      y: 148,
    },
    {
      cityId: 8,
      x: 976,
      y: 150,
    },
    {
      cityId: 8,
      x: 1004,
      y: 158,
    },
    {
      cityId: 8,
      x: 1033,
      y: 176,
    },
    {
      cityId: 8,
      x: 898,
      y: 188,
    },
    {
      cityId: 8,
      x: 929,
      y: 205,
    },
    {
      cityId: 8,
      x: 948,
      y: 188,
    },
    {
      cityId: 8,
      x: 1006,
      y: 200,
    },
    {
      cityId: 8,
      x: 990,
      y: 252,
    },
  ],
  '9': [
    {
      cityId: 9,
      x: 356,
      y: 737,
    },
    {
      cityId: 9,
      x: 406,
      y: 735,
    },
    {
      cityId: 9,
      x: 466,
      y: 742,
    },
    {
      cityId: 9,
      x: 509,
      y: 737,
    },
    {
      cityId: 9,
      x: 558,
      y: 731,
    },
    {
      cityId: 9,
      x: 389,
      y: 785,
    },
    {
      cityId: 9,
      x: 429,
      y: 813,
    },
    {
      cityId: 9,
      x: 474,
      y: 810,
    },
    {
      cityId: 9,
      x: 529,
      y: 807,
    },
    {
      cityId: 9,
      x: 606,
      y: 805,
    },
  ],
  '10': [
    {
      cityId: 10,
      x: 743,
      y: 667,
    },
    {
      cityId: 10,
      x: 790,
      y: 670,
    },
    {
      cityId: 10,
      x: 829,
      y: 669,
    },
    {
      cityId: 10,
      x: 743,
      y: 742,
    },
    {
      cityId: 10,
      x: 791,
      y: 747,
    },
    {
      cityId: 10,
      x: 859,
      y: 759,
    },
    {
      cityId: 10,
      x: 772,
      y: 820,
    },
    {
      cityId: 10,
      x: 810,
      y: 813,
    },
    {
      cityId: 10,
      x: 865,
      y: 808,
    },
    {
      cityId: 10,
      x: 930,
      y: 832,
    },
  ],
  '11': [
    {
      cityId: 11,
      x: 834,
      y: 469,
    },
    {
      cityId: 11,
      x: 871,
      y: 474,
    },
    {
      cityId: 11,
      x: 824,
      y: 504,
    },
    {
      cityId: 11,
      x: 857,
      y: 505,
    },
    {
      cityId: 11,
      x: 907,
      y: 511,
    },
    {
      cityId: 11,
      x: 848,
      y: 544,
    },
    {
      cityId: 11,
      x: 880,
      y: 541,
    },
    {
      cityId: 11,
      x: 931,
      y: 533,
    },
    {
      cityId: 11,
      x: 887,
      y: 579,
    },
    {
      cityId: 11,
      x: 915,
      y: 566,
    },
  ],
  '12': [
    {
      cityId: 12,
      x: 654,
      y: 483,
    },
    {
      cityId: 12,
      x: 689,
      y: 487,
    },
    {
      cityId: 12,
      x: 739,
      y: 486,
    },
    {
      cityId: 12,
      x: 627,
      y: 543,
    },
    {
      cityId: 12,
      x: 681,
      y: 544,
    },
    {
      cityId: 12,
      x: 744,
      y: 545,
    },
    {
      cityId: 12,
      x: 620,
      y: 589,
    },
    {
      cityId: 12,
      x: 651,
      y: 587,
    },
    {
      cityId: 12,
      x: 714,
      y: 587,
    },
    {
      cityId: 12,
      x: 767,
      y: 588,
    },
  ],
  '13': [
    {
      cityId: 13,
      x: 1123,
      y: 712,
    },
    {
      cityId: 13,
      x: 1173,
      y: 699,
    },
    {
      cityId: 13,
      x: 1216,
      y: 695,
    },
    {
      cityId: 13,
      x: 1098,
      y: 780,
    },
    {
      cityId: 13,
      x: 1144,
      y: 775,
    },
    {
      cityId: 13,
      x: 1184,
      y: 773,
    },
    {
      cityId: 13,
      x: 1210,
      y: 771,
    },
    {
      cityId: 13,
      x: 1261,
      y: 773,
    },
    {
      cityId: 13,
      x: 1196,
      y: 840,
    },
    {
      cityId: 13,
      x: 1261,
      y: 821,
    },
  ],
  '14': [
    {
      cityId: 14,
      x: 1273,
      y: 600,
    },
    {
      cityId: 14,
      x: 1306,
      y: 602,
    },
    {
      cityId: 14,
      x: 1336,
      y: 603,
    },
    {
      cityId: 14,
      x: 1262,
      y: 662,
    },
    {
      cityId: 14,
      x: 1313,
      y: 654,
    },
    {
      cityId: 14,
      x: 1357,
      y: 647,
    },
    {
      cityId: 14,
      x: 1292,
      y: 695,
    },
    {
      cityId: 14,
      x: 1329,
      y: 691,
    },
    {
      cityId: 14,
      x: 1364,
      y: 698,
    },
    {
      cityId: 14,
      x: 1345,
      y: 723,
    },
  ],
  '15': [
    {
      cityId: 15,
      x: 1020,
      y: 597,
    },
    {
      cityId: 15,
      x: 992,
      y: 623,
    },
    {
      cityId: 15,
      x: 968,
      y: 635,
    },
    {
      cityId: 15,
      x: 986,
      y: 665,
    },
    {
      cityId: 15,
      x: 1027,
      y: 659,
    },
    {
      cityId: 15,
      x: 1076,
      y: 659,
    },
    {
      cityId: 15,
      x: 1099,
      y: 660,
    },
    {
      cityId: 15,
      x: 1133,
      y: 668,
    },
    {
      cityId: 15,
      x: 1163,
      y: 649,
    },
    {
      cityId: 15,
      x: 1057,
      y: 597,
    },
  ],
  '16': [
    {
      cityId: 16,
      x: 1056,
      y: 522,
    },
    {
      cityId: 16,
      x: 1107,
      y: 509,
    },
    {
      cityId: 16,
      x: 1155,
      y: 487,
    },
    {
      cityId: 16,
      x: 1079,
      y: 578,
    },
    {
      cityId: 16,
      x: 1127,
      y: 547,
    },
    {
      cityId: 16,
      x: 1153,
      y: 525,
    },
    {
      cityId: 16,
      x: 1193,
      y: 513,
    },
    {
      cityId: 16,
      x: 1168,
      y: 585,
    },
    {
      cityId: 16,
      x: 1206,
      y: 564,
    },
    {
      cityId: 16,
      x: 1242,
      y: 557,
    },
  ],
};
