import { FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { PAGES } from '../../constants/pages';
import Logout from '../../assets/Logout';
import { AuthContext } from '../../context/context';
import { LobbyService } from '../../services/lobby.serivice';
import { GameService } from '../../services/game.serivice';

const Menu: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { logout, user } = useContext(AuthContext);
  const [isOpened, open] = useState<boolean>(false);

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [pages, setPages] = useState<{ name: string; path?: string; icon: any }[]>([]);

  const dropDownRef = useDetectClickOutside({
    onTriggered: () => open && open(false),
  });

  const onLogout = () => {
    open(false);
    logout();
  };

  const goTo = (path: string) => {
    open(false);
    navigate(path);
  };

  useEffect(() => {
    const curentRoutes = location.pathname.replace('/', '').split('/');
    const curentRoute = curentRoutes?.at(0)!;
    let newPages = PAGES;
    if (['join', 'game'].includes(curentRoute)) {
      setShowMenu(true);
      if (curentRoute.includes('join')) {
        newPages = PAGES.filter((r) => r.path !== '/join');
      }
      if (curentRoute.includes('game')) {
        newPages = PAGES.filter((r) => r.path === '/rules');
      }
    } else {
      setShowMenu(false);
    }
    setPages(newPages);
  }, [location.pathname]);

  const createLobby = async () => {
    try {
      const lobby = await LobbyService.create(user?.username);
      navigate(`/lobby/${lobby.data.id}`);
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  };

  const renderItem = (page: { name: string; path?: string; icon: any }) => {
    return (
      <div
        className="flex flex-row justify-between py-3 px-4 cursor-pointer w-48 hover:bg-wheat"
        onClick={() => (page?.path ? goTo(page?.path) : createLobby())}
        key={page.name}
      >
        <div className="flex flex-row justify-start items-center">
          <page.icon color="#4D4D4D" size={20} />
          <p className="m-0 text-tundora text-sm leading-4 font-normal ml-2">{page.name}</p>
        </div>
      </div>
    );
  };

  const exitGame = async () => {
    try {
      if (params?.gameId) {
        await GameService.end(params?.gameId!);
        navigate('/');
      }
    } catch (e) {
      navigate('/');
    }
  };

  return (
    <div className="relative" ref={dropDownRef}>
      {showMenu ? (
        <div>
          <button
            className="rounded-lg border border-solid border-potters-clay bg-derby cursor-pointer h-10 w-10 flex flex-row justify-center items-center"
            onClick={() => open(!isOpened)}
          >
            {isOpened ? (
              <img src="/images/close.png" alt="close" className="w-6 h-6" />
            ) : (
              <img src="/images/menu.png" alt="menu" className="w-6 h-6" />
            )}
          </button>
          {isOpened ? (
            <div className="absolute bg-derby rounded-lg border border-solid border-potters-clay right-0 top-11 py-2">
              {pages?.map((p) => renderItem(p))}
              {user ? (
                <div
                  className="flex flex-row justify-between py-3 px-4 cursor-pointer w-48 hover:bg-wheat border-0 border-t border-solid border-saddle-brown-10"
                  onClick={location.pathname.includes('game') ? exitGame : onLogout}
                  key={'logout'}
                >
                  <div className="flex flex-row justify-start items-center">
                    <Logout />
                    <p className="m-0 text-tundora text-sm leading-4 font-normal ml-2">
                      {location.pathname.includes('game') ? 'Exit Game' : 'Logout'}
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Menu;
