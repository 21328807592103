const Rules = ({ color = '#ffffff', size = 28 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color}
        d="M18.75 5H10v1.25h8.75V5zM3.75 6.619L2.131 5l-.881.881 2.5 2.5 5-5-.881-.881L3.75 6.619zM18.75 13.75H10V15h8.75v-1.25zM3.75 15.369L2.131 13.75l-.881.881 2.5 2.5 5-5-.881-.881-4.119 4.119z"
      ></path>
    </svg>
  );
};

export default Rules;
