const Catalog = ({ fill = '#ffffff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M19.5 1.5H6A1.5 1.5 0 004.5 3v3H3v1.5h1.5v3.75H3v1.5h1.5v3.75H3V18h1.5v3A1.5 1.5 0 006 22.5h13.5A1.5 1.5 0 0021 21V3a1.5 1.5 0 00-1.5-1.5zm0 19.5H6v-3h1.5v-1.5H6v-3.75h1.5v-1.5H6V7.5h1.5V6H6V3h13.5v18z"
      ></path>
      <path
        fill={fill}
        d="M16.5 6h-6v1.5h6V6zM16.5 11.25h-6v1.5h6v-1.5zM16.5 16.5h-6V18h6v-1.5z"
      ></path>
    </svg>
  );
};

export default Catalog;
