import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '../Menu';

const Header: FC = () => {
  const navigate = useNavigate();

  const goTo = (path: string) => {
    navigate(path);
  };

  return (
    <div className="flex flex-row justify-between p-6">
      <div>
        <p
          className="text-muddy-waters text-3xl font-medium uppercase m-0 cursor-pointer"
          onClick={() => goTo('/')}
        >
          Rising Tides
        </p>
      </div>
      <Menu />
    </div>
  );
};

export default Header;
