import Api from '../axios/api';
import { Player } from './game.serivice';

export interface ChatI {
  id: string;
  isClosed: boolean;
  actors: Player[];
}

export interface MessageI {
  id: string;
  chatId: string;
  author: Player;
  message: string;
  createdAt: number;
}

export class ChatService {
  static addMessage(id: string, data: { message: string }): Promise<{ data: MessageI }> {
    return Api.post(`/v1/chat/${id}/messages`, data);
  }

  static getOne(id: string): Promise<{ data: ChatI }> {
    return Api.get(`/v1/chat/${id}`);
  }

  static getMessages(
    id: string,
    skip = 0,
    limit = 20,
  ): Promise<{ data: { count: number; items: MessageI[] } }> {
    return Api.get(`/v1/chat/${id}/messages`, { params: { limit, skip } });
  }
}
