import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Login from '../../components/Login';
import { LobbyService } from '../../services/lobby.serivice';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const JoinNameLobby: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [existLobby] = useState(params?.lobbyId);

  const joinLobby = async (_: string, lobbyId?: string) => {
    try {
      await LobbyService.join(lobbyId!);
      navigate(`/lobby/${lobbyId}`);
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  };

  return (
    <div className="w-full h-screen">
      <div
        className="absolute bg-no-repeat z-0 bg-cover h-full w-full bg-left-bottom"
        style={{ backgroundImage: `url('/images/bg.jpg')` }}
      />
      <div className="flex flex-col justify-between h-full relative z-10 bg-saddle-brown-95">
        <Header />
        <div className="flex flex-row justify-center w-full h-full my-48">
          <Login title="Join Lobby" submit={joinLobby} isJoinPage={!existLobby} />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default JoinNameLobby;
