const User = ({ fill = '#ffffff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M22.098 10.5L19.5 9l-.75 1.3 2.598 1.5.75-1.3zM22.5 6h-3v1.5h3V6zM2.652 11.8l2.598-1.5L4.5 9l-2.598 1.5.75 1.3zM19.5 22.5H18v-3.75A3.755 3.755 0 0014.25 15h-4.5A3.755 3.755 0 006 18.75v3.75H4.5v-3.75a5.256 5.256 0 015.25-5.25h4.5a5.256 5.256 0 015.25 5.25v3.75zM12 3a3.75 3.75 0 110 7.5A3.75 3.75 0 0112 3zm0-1.5A5.25 5.25 0 1012 12a5.25 5.25 0 000-10.5zM21.348 1.701l-2.598 1.5.75 1.299L22.098 3l-.75-1.299zM4.5 6h-3v1.5h3V6zM1.902 3L4.5 4.5l.75-1.299-2.598-1.5L1.902 3z"
      ></path>
    </svg>
  );
};

export default User;
