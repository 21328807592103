const Close = ({ fill = '#000' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 17 17">
      <path
        fill={fill}
        d="M12.25 5.2l-.7-.7-3.3 3.3-3.3-3.3-.7.7 3.3 3.3-3.3 3.3.7.7 3.3-3.3 3.3 3.3.7-.7-3.3-3.3 3.3-3.3z"
      ></path>
    </svg>
  );
};

export default Close;
