import classNames from 'classnames';
import { FC, KeyboardEventHandler, useState } from 'react';
import Next from '../../assets/Next';

export interface LoginProps {
  title: string;
  isJoinPage?: boolean;
  submit: (username: string, lobbyId?: string) => void;
}

const Login: FC<LoginProps> = ({ title, submit, isJoinPage = false }) => {
  const [username, setUsername] = useState<string | undefined>();
  const [lobbyId, setLobby] = useState<string | undefined>();
  const [error, setError] = useState<boolean>(false);
  const [errorLobby, setErrorLobby] = useState<boolean>(false);

  const onSubmit = () => {
    if (!username?.length && !isJoinPage) {
      setError(true);
      return;
    }
    if (isJoinPage && !lobbyId?.length) {
      setErrorLobby(true);
      return;
    }
    submit(username!, lobbyId);
  };

  const changeUsername = (value: string | undefined) => {
    setError(false);
    setUsername(value);
  };

  const changeLobby = (value: string | undefined) => {
    setErrorLobby(false);
    setLobby(value);
  };

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <div className="flex flex-col justify-between h-full w-full max-h-[450px]">
      <div className="flex flex-row justify-center">
        <p className="m-0 text-tangerine uppercase text-5xl leading-tight font-medium">{title}</p>
      </div>
      <div className="flex flex-row justify-center">
        <div className="flex flex-col">
          {!isJoinPage ? (
            <div className="flex flex-col relative">
              <label htmlFor="username" className="m-0 text-base font-normal text-white mb-1">
                Username
              </label>
              <input
                type="text"
                name="username"
                id="username"
                placeholder="e.g., Jacksparrow"
                value={username}
                onChange={(e) => changeUsername(e.target.value)}
                className={classNames(
                  'border-0 bg-transparent border-b  border-solid py-1 text-sm font-normal leading-4 text-white-50 outline-none',
                  { 'border-red': error, 'border-white-50': !error },
                )}
                onKeyDown={handleKeyPress}
              />
              {error ? (
                <p className="m-0 text-sm font-normal leading-4 text-red absolute -bottom-2 translate-y-full">
                  Username is required
                </p>
              ) : null}
            </div>
          ) : null}
          {isJoinPage ? (
            <div
              className={classNames('flex flex-col relative', {
                'mt-2': !error,
                'mt-5': error,
              })}
            >
              <label htmlFor="lobbyId" className="m-0 text-base font-normal text-white mb-1">
                Enter Lobby ID
              </label>
              <input
                type="text"
                name="lobbyId"
                id="lobbyId"
                placeholder="Enter Lobby ID"
                value={lobbyId}
                onChange={(e) => changeLobby(e.target.value)}
                className={classNames(
                  'border-0 bg-transparent border-b  border-solid py-1 text-sm font-normal leading-4 text-white-50 outline-none',
                  { 'border-red': errorLobby, 'border-white-50': !errorLobby },
                )}
                onKeyDown={handleKeyPress}
              />
              {errorLobby ? (
                <p className="m-0 text-sm font-normal leading-4 text-red absolute -bottom-2 translate-y-full">
                  Lobby is required
                </p>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex flex-row justify-center">
        <button
          className="flex flex-row justify-center items-center rounded-sm bg-forest-green outline-none border-0 px-5 py-3 cursor-pointer"
          onClick={onSubmit}
        >
          <p className="m-0 text-base font-medium text-white mr-2 py-1">Continue</p>
          <Next />
        </button>
      </div>
    </div>
  );
};

export default Login;
